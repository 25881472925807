.actionsWrapper {
    position: relative;
}

.iconWrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.optionsWrapper {
    z-index: 2;
    position: absolute;
    top: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #b3b3b3;
    padding: 10px 0 10px 0;
}

.option {
    display: flex;
    align-items: center;

    padding: 5px 10px 5px 10px;
    cursor: pointer;
}

.option:hover {
    background-color: #c4daef;
}

.optValue {
    margin: 0 0 0 10px;
}
