.container {
	display: flex;
	padding: 10px 15px;
	flex-direction: column;
	cursor: pointer;
}

.container:hover {
	background-color: #454545;
}

.border {
	margin-left: 15px;
	height: 1px;
	flex-grow: 1;
	background: rgba(255, 255, 255, 0.2);
}

.titleContainer {
	display: flex;
	flex-wrap: wrap;
}

.mainTextStyle {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-weight: 600;
	font-family: Roboto;
	font-size: 14px;
	line-height: 16px;
	color: #fafcff;
}

.timeTextStyle {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 13px;
	color: #b3b3b3;
	font-family: Roboto;
}

.regularTextStyle {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 14px;
	line-height: 16px;
	color: #fafcff;
	font-family: Roboto;
}
