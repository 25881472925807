.collapsableLine {
    padding: 10px 0;
    border-bottom: 1px solid #e9e9e9;
}

.lineButtons {
    display: flex;
    justify-content: space-between;
}

.expressionCollapseButton {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: rgba(51, 51, 51, 0.7);
    border: none;
    background: none;
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.expressionCollapseButton:hover {
    background: none;
    border: none;
}

.expressionCollapseButton:focus {
    background: none;
    border: none;
}

.expressionCollapseButton:active {
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
}

.expressionCollapseButton:active:focus {
    background: none;
    border: none;
    outline: none;
}

.expressionCollapseButtonIcon {
    width: 12px;
    height: 6px;
    transform: rotate(-90deg);
    transition: all 0.2s ease-out;
    margin-right: 10px;
}

.expressionCollapseButtonIcon > path {
    fill: #606060;
}

.chevronOpen {
    transform: rotate(0deg);
}

.addRemoveButton {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #3b82c9;
    padding: 10px 0;
    background: none;
    border: none;
}

.addRemoveButton:hover {
    background: none;
    border: none;
}

.addRemoveButton:focus {
    background: none;
    border: none;
}

.addRemoveButton:active {
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
}

.addRemoveButton:active:focus {
    background: none;
    border: none;
    outline: none;
}

.field {
    margin-bottom: 10px;
}

.label {
    display: block;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: rgba(51, 51, 51, 0.7);
    margin-bottom: 5px;
}

.allowNansLabel {
    display: block;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(51, 51, 51, 0.7);
}

.selectTitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: rgba(51, 51, 51, 0.7);
    margin-bottom: 5px;
}

.selectExpression {
    display: flex;
    align-items: center;
}

.selectLabel {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: rgba(51, 51, 51, 0.7);
    margin-right: 5px;
}

.selectInput {
    color: #333;
    border-radius: 4px;
    border: 1px solid rgb(204, 204, 204);
    padding: 8px;
}
