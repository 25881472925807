.circle-letter {
    background: #3f4b62;
    color: white;
    padding: 0px 0px;
    border-radius: 50%;
    font-family: 'Roboto';
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    /*font-size: 35px;*/
}

.regular-text {
    color: var(--primary-text-color);
    font-family: 'Roboto';
}

.popup-regular-text {
    color: var(--popup-primary-text-color);
    font-family: 'Roboto';
}

.content-regular-text {
    color: var(--content-primary-text-color);
    font-family: 'Roboto';
}

.note-text {
    color: #969aa3;
    font-family: 'Roboto';
}

.no-selection {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
