.root {
	background: none;
	border: none;
}

.contained {
	background: linear-gradient(183.16deg, rgba(46, 62, 98, 0.8) 5.24%, #2E3E62 94.76%), #FFFFFF;
	border: 1px solid rgba(4, 30, 55, 0.1);
	box-sizing: border-box;
	box-shadow: 0px 1px 2px rgba(49, 54, 58, 0.04);
	border-radius: 4px;
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #FFFFFF;
	padding: .8rem 1.6rem;
}

.text {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #5978BC;
}
