.header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7f7f7;
    padding: 2vh;
    color: #72899F;
    font-size: 25px;
    font-weight: 800;
}

.contentWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    margin: 0 4vw 0 4vw;
    padding: 10vh 2vw 10vh 2vw;
}


.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3vw;
}