.container {
    background: #ffffff;
    border: 2px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 4px;
}

.searchInput {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    color: #e8e8e8;
}

.searchInput::placeholder {
    color: #cccccc !important;
}

.itemContainer {
    border-bottom: 1px solid rgba(74, 74, 74, 0.2);
    cursor: pointer;
    margin-left: 22px;
    margin-right: 15px;
    display: flex;
    height: 35px;
    align-items: flex-end;
}

.item {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #4a4a4a;
}

.pageItem {
    justify-content: space-between;
    width: 100%;
    display: flex;
}

.itemMedium {
    font-weight: 500;
}

.searchContainer {
    background: linear-gradient(90.2deg, #323232 50.28%, rgba(50, 50, 50, 0.9) 100%);
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 4px;
}

.sectionItem {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
}

.backButton {
    font-family: Roboto;
    display: flex;
    width: 100%;
    margin-top: 7px;
    margin-bottom: 7px;
    padding-right: 20px;
    justify-content: flex-end;
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    color: #4a4a4a;
}
