.modelContainer {
    padding: 10px;
    background: rgba(246, 246, 246, 0.8);
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: #75869a;
    margin-bottom: 10px;
}

.title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: rgba(51, 51, 51, 0.8);
}

.significanceLabel {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: rgba(51, 51, 51, 0.7);
    display: block;
    text-align: left;
}

.significanceGroup {
    background: #ffffff;
    border: 0.862069px solid #d1d1d1;
    box-sizing: border-box;
    border-radius: 3.44828px;
}

.summaryArea {
    font-family: Roboto;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    white-space: pre-wrap;
}

.modelArea {
    border: none;
    padding-top: 10px;
    height: 40px;
}

.btnSmallSignificance {
    background-color: var(--levers-background-color);
    color: #98a7b9;
    padding: 0.5rem 0.5rem;
    font-size: 1rem;
    border: none;
}
.btnSmallSignificance:hover,
.btnSmallSignificance:active,
.btnSmallSignificance:focus,
.btnSmallSignificance:active:focus {
    background-color: #008bf5;
    color: white;
    padding: 0.5rem 0.5rem;
    font-size: 1rem;
    border: none;
}

.btnSmallSignificance[disabled],
.btnSmallSignificance[disabled]:hover,
.btnSmallSignificance[disabled]:active,
.btnSmallSignificance[disabled]:focus,
.btnSmallSignificance[disabled]:active:focus {
    background-color: var(--selectors-background-hover-color);
    color: #98a7b9;
    padding: 0.5rem 0.5rem;
    font-size: 1rem;
    opacity: 1; /* not greyed out when disabled */
    cursor: default;
    box-shadow: none; /* no shadow when clicked */
    border: none;
}

.btnSmallSignificanceSelected,
.btnSmallSignificanceSelected:hover,
.btnSmallSignificanceSelected:active,
.btnSmallSignificanceSelected:focus,
.btnSmallSignificanceSelected:active:focus {
    background-color: #75869a;
    color: white;
    padding: 0.5rem 0.5rem;
    font-size: 1rem;
    border: none;
}

.btnSmallSignificanceSelected[disabled],
.btnSmallSignificanceSelected[disabled]:hover,
.btnSmallSignificanceSelected[disabled]:active,
.btnSmallSignificanceSelected[disabled]:focus,
.btnSmallSignificanceSelected[disabled]:active:focus {
    background-color: #75869a;
    color: white;
    padding: 0.5rem 0.5rem;
    font-size: 1rem;
    opacity: 1; /* not greyed out when disabled */
    cursor: default;
    box-shadow: none; /* no shadow when clicked */
    z-index: 0;
    border: none;
}
