.root {
	border: none;
	background: none;
	/* padding: 0; */
	display: flex;
	align-items: center;
	border-radius: 0;
}

.root:focus {
	border: none;
	background: none;
}

.root:active {
	border: none;
	background: none;
	outline: none;
	box-shadow: none;
}

.root:hover {
	border: none;
	background: none;
}

.root:active:focus {
	border: none;
	background: none;
	outline: none;
}
