.container {
    padding: 8px 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
}

.headerRow {
    padding: 16px 0px;
    display: flex;
    flex-direction: row;
}

.headerRow > * {
    flex: 1;
}

.row {
    padding: 8px 0px;
    display: flex;
    flex-direction: row;
}

.row > * {
    flex: 1;
}

.headerCell {
    font-family: Roboto;
    font-size: 16px;
    color: white;
    text-align: left;
    overflow-wrap: break-word;
}

.cell {
    font-family: Roboto;
    font-size: 14px;
    color: white;
    text-align: left;
    overflow-wrap: break-word;
}
