.floatingBtnWrapper {
  cursor: pointer;
  position: absolute;
  bottom: 2.5vh;
  right: 5vw;
  background-color: #f9f9f960;
  border-radius: 25%;
  width: 5rem;
  height: 5rem;
  padding: 0px 5px 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
}

.floatingBtnWrapperSend {
  cursor: pointer;
  position: absolute;
  bottom: 2.5vh;
  right: 5vw;
  background-color: rgb(59, 130, 201);
  border-radius: 25%;
  width: 5rem;
  height: 5rem;
  padding: 0px 5px 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
}

.floatingBtnWrapper:hover {
  animation: scaleAnimation 1s infinite alternate;
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}