.logosEditorContainer {
  height: 165px;
  display: block;
}

.showMoreBtnContainer {
  display: flex;
  justify-content: flex-end;
  padding: 15px 0 0 0;
}

.logoInput {
  width: 24px;
  height: 24px;
  border: 1px solid #acacac;
  text-align: center;
}

.logoInput:focus {
  outline: none;
}

.logoInfoItem {
  display: flex;
  justify-content: center;
}
