.root {
	margin-top: 10px;
	padding-bottom: 10px;
}

.dataContainer {
	width: 480px;
	display: grid;
	grid-template-columns: repeat(3, 75px) repeat(2, 90px) 75px;
	border-bottom: 1px solid #d1d1d1;
	border-left: 1px solid #d1d1d1;
	position: absolute;
	top: 0;
	left: 0;
}

.dataContainerOverlay {
	width: 480px;
	display: grid;
	grid-template-columns: repeat(3, 75px) repeat(2, 90px) 75px;
	position: absolute;
	top: 0;
	left: 0;
}

.optionName {
	color: #333333;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	min-width: 130px;
}

.optionContainer {
	margin-top: 20px;
	display: flex;
	align-items: center;
}
.input {
	color: #333333;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	border: 1px solid #d1d1d1;
	outline: none;
	width: 56px;
	height: 35px;
}

.configFields {
	padding-left: 10px;
}

.configField {
	display: flex;
	margin-top: 10px;
	align-items: center;
}

.configLabel {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: rgba(36, 33, 36, 0.8);
	margin-right: 5px;
	min-width: 125px;
}

.configSelect {
	flex-grow: 1;
}
