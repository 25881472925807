.icon > path {
    fill: white;
}

.content {
    /* Drop shadow */
    width: 100%;
    height: 100%;
}

.container {
    display: flex;
    width: 100%;
    /* flex-grow: 1; */
    background: var(--greyColor);
    position: relative;
    margin-top: 65px;
    /* padding-top: 65px; */
    padding-left: 112px;
    padding-bottom: 40px;
    flex-wrap: wrap;
}


.topBar {
    background: #f6f6f6;
    /* Drop shadow */
    min-height: 66px;
    border-bottom: 1px solid #dddddd;
	padding-left: 38px;
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 9;
}

.topBarLabel {
	margin-left: 17px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    /* identical to box height */

    color: #555;
}
.progressBarWrapper {
    margin-top: 5px;
    text-align: center;
    padding: 15px 0 0;
    /* z-index: 9; */
    width: 100%;
    background: var(--greyColor);
    padding-bottom: 15px;
    margin-right: 15px;
}





.landing_guide .row_first .steps .step_circle .inner:before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background: #4d5966;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
}

.landing_guide .row_first .steps.active .step_circle .inner:before {
    content: '';
    background: var(--acceptBtn-color);
    opacity: 1 !important;
}

.landing_guide .row_first .steps:hover .step_circle .inner:before {
    content: '';
    background: var(--acceptBtn-color);
    opacity: 0.5;
}

.landing_guide .row_first .step_hdng .steps:first-child {
    text-align: left;
    width: 27%;
}

.landing_guide .row_first .step_hdng .steps {
    width: 33%;
    position: relative;
    cursor: pointer;
}

.landing_guide .row_first .step_hdng .steps.step_one {
    z-index: 4 !important;
}

.landing_guide .row_first .step_hdng .steps.step_one:before {
    content: '';
    position: absolute;
    width: 80%;
    left: 20px !important;
    height: 5px;
    right: auto !important;
    top: 61px;
    background: var(--acceptBtn-color);
    z-index: 8;
}

.landing_guide .row_first .step_hdng .steps.active:first-child:before {
    content: '';
    position: absolute;
    width: 80%;
    left: 20px !important;
    height: 5px;
    right: auto !important;
    top: 61px;
    background: var(--acceptBtn-color);
}

.landing_guide .row_first .step_hdng .steps:nth-child(2) {
    z-index: 3;
}

.landing_guide .row_first .step_hdng .steps:nth-child(2) .step_circle {
    margin-left: 7px;
}

.landing_guide .row_first .step_hdng .steps:nth-child(2)>p {
    max-width: 87%;
}

.landing_guide .row_first .step_hdng .steps:nth-child(3) {
    z-index: 2;
}

.landing_guide .row_first .step_hdng .steps.step_two:before {
    content: '';
    position: absolute;
    width: 75%;
    right: 96%;
    height: 5px;
    top: 61px;
    background: var(--acceptBtn-color);
}

.landing_guide .row_first .step_hdng .steps.active:hover:before {
    content: '';
    position: absolute;
    width: 50%;
    right: 96%;
    height: 5px;
    top: 61px;
    background: var(--acceptBtn-color);
}

.landing_guide .row_first .step_hdng .steps.active:nth-child(3):hover:before {
    content: '';
    position: absolute;
    width: 170%;
    right: 96%;
    height: 5px;
    top: 61px;
    background: var(--acceptBtn-color);
}

.landing_guide .row_first .step_hdng .steps.step_three:before {
    content: '';
    position: absolute;
    width: 215%;
    right: 100%;
    height: 5px;
    top: 61px;
    background: var(--acceptBtn-color);
}

.landing_guide .row_first .step_hdng .steps.step_four:before {
    content: '';
    position: absolute;
    width: 350%;
    right: 20px;
    height: 5px;
    top: 61px;
    background: var(--acceptBtn-color);
    z-index: 1;
}

.landing_guide .row_first .step_hdng .steps.active:last-child:hover:before {
    content: '';
    position: absolute;
    width: 350%;
    right: 20px;
    height: 5px;
    top: 61px;
    background: var(--acceptBtn-color);
    z-index: 1;
}

.landing_guide .row_first .step_hdng .steps:hover>p {
    color: var(--acceptBtn-color);
    opacity: 0.5;
}

.landing_guide .row_first .step_hdng .steps.active>p {
    color: var(--acceptBtn-color);
    opacity: 1 !important;
}

.landing_guide .row_first .step_hdng .step_circle .inner {
    display: block;
    width: 28px;
    height: 28px;
    border: 5px solid #4D5966;
    border-radius: 50%;
    position: relative;
    margin: 0 auto;
}

.landing_guide .row_first .steps:hover .step_circle .inner {
    border: 5px solid var(--acceptBtn-color);
    /* opacity: 0.5; */
    opacity: 0.7;
}

.landing_guide .row_first .steps.active .step_circle .inner {
    border: 5px solid var(--acceptBtn-color);
    opacity: 1 !important;

}

.landing_guide .row_first .step_hdng .steps:first-child .step_circle {
    margin-left: 18px;
    position: relative;
    z-index: 8;
    padding: 0 15px 0 20px;
}


.landing_guide .row_first .step_hdng .main_steps__3yyLC:nth-child(2) .step_circle {
    margin-left: 6px;
}

.landing_guide .row_first .step_hdng .step_circle {
    display: table;
    background: #fff;
    position: relative;
    z-index: 8;
    padding: 0 15px;
}

.landing_guide .row_first .step_hdng p {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    display: flex;
    min-height: 40px;
    line-height: 15px;
    padding-top: 10px;
}

.landing_guide .row_first .step_hdng {
    display: flex;
    width: 100%;
    position: relative;
}

.landing_guide .row_first .step_hdng .steps:last-child {
    text-align: right;
    width: 20%;
}

.landing_guide .row_first .step_hdng .steps:nth-child(3) {
    width: 18%;
}

.landing_guide .row_first .step_hdng:after {
    content: '';
    position: absolute;
    left: 3%;
    top: 61px;
    width: 92%;
    height: 5px;
    background: #4d5966;
}

.landing_guide .row_first {
    padding: 20px 50px;
    background: #fff;
}

.landing_guide .row_first .step_hdng .steps:last-child .step_circle {
    margin-right: 5px;
    margin-left: auto;
}

.landing_guide .row_first .step_hdng .steps .pop_listing .list {
    padding: 11px 0 0 0;
    list-style: none;
    position: relative;
    margin: 0 0 0 33px;
}

.landing_guide .row_first .step_hdng .steps .pop_listing {
    position: absolute;
    width: 80%;
    top: 74px;
    z-index: 9;
    background: transparent;
}

.landing_guide .row_first .step_hdng .steps .pop_listing .list:before {
    content: '';
    width: 5px;
    height: 60%;
    background: #4d5966;
    left: 0;
    top: 0;
    position: absolute;
}

.landing_guide .row_first .step_hdng .steps .pop_listing .list li {
    margin: 2px 0;
    padding-left: 31px;
    position: relative;
    cursor: pointer;
    text-align: left;
}

.landing_guide .row_first .step_hdng .steps .pop_listing .list li span {
    padding-right: 10px;
    position: absolute;
    left: 0;
    top: -5px;
    z-index: 9;
}

.landing_guide .row_first .step_hdng .steps .pop_listing .list li:hover:after {
    content: '';
    height: 200px;
    width: 5px;
    position: absolute;
    bottom: 100%;
    background: var(--acceptBtn-color);
    left: 0;
    z-index: 9;
}
.landing_guide .row_first .step_hdng .steps .pop_listing .list li:after {
    content: '';
    height: 200px;
    width: 5px;
    position: absolute;
    bottom: 98%;
    background: #4d5966;
    left: 0;
}

.landing_guide .row_first .step_hdng .steps .pop_listing .list li:active:after {
    content: '';
    height: 200px;
    width: 5px;
    position: absolute;
    bottom: 100%;
    background: var(--acceptBtn-color);
    left: 0;
    z-index: 9;
    ;
}

.landing_guide .row_first .step_hdng .steps .pop_listing .list li.active {
    color: var(--acceptBtn-color);
}

.landing_guide .row_first .step_hdng .steps .pop_listing .list li:hover {
    color: var(--acceptBtn-color);
}

.landing_guide .row_first .step_hdng .steps .pop_listing li:before {
    content: '';
    width: 26px;
    height: 12px;
    background-size: 100%;
    position: absolute;
    left: 0;
    bottom: 100%;
}

.listing_content {
    overflow: hidden;
}

.landing_guide .row_first .step_hdng .steps .pop_listing .list li.active:before {
    content: '';
    width: 26px;
    height: 12px;
    background-size: 100%;
    position: absolute;
    left: 0;
    bottom: 100%;
}

.landing_guide .row_first .step_hdng .steps .pop_listing .list li.active:after {
    content: '';
    left: 0;
    bottom: 100%;
    position: absolute;
    width: 5px;
    height: 300px;
    background: var(--acceptBtn-color);
    z-index: 9;
}

.landing_guide .row_first .step_hdng p.sub_hdng {
    font-weight: 400;
    max-width: 193px;
    text-align: center;
    font-size: 14px;
    position: relative;
    top: 16px;
    transform: translate(-50%, 0);
    left: 29px;
}

.landing_guide .row_first .step_hdng .steps:first-child p.sub_hdng {
    left: 50px;
    max-width: 152px;
}

.landing_guide .row_first .step_hdng .steps:nth-child(2) p.sub_hdng {
    left: 37px;
    max-width: 250px;
}

.landing_guide .row_first .step_hdng .steps:last-child p.sub_hdng {
    left: auto;
    transform: translate(30%, 0);
    margin-left: auto;
    text-align: center;
}


.landing_guide .row_first .step_hdng .steps:last-child p {
    text-align: right;
    display: block;
}

.step_hdng p .add_new {
    font-size: 13px;
    font-weight: 300;
    padding-left: 5px;
}

.landing_guide {
    padding: 20px 25px;
    min-height: 260px;
    background-color: #fff;
    border-radius: 10px;
    margin-block: 20px;
}

.header {
    text-align: center;
    background: var(--greyColor);
    padding-block: 25px;
}




@media screen and (min-width: 1400px) {

    /*.landing_guide {
       margin: 0 1.8% 0 16px;
    }
    .landing_guide .row_first .step_hdng .steps:first-child p.sub_hdng {
        left: 50px;
    }

    .landing_guide .row_first .step_hdng .steps:nth-child(2) p.sub_hdng {
        left: 37px;
    }

    .landing_guide .row_first .step_hdng p.sub_hdng {
        left: 29px;
    }*/

}

@media screen and (min-width: 1600px) {

    /*.landing_guide {
       margin: 0 1.8% 0 16px;
    }
    .landing_guide .row_first .step_hdng .steps:first-child p.sub_hdng {
        left: 50px;
    }
<<<<<<< HEAD

=======
>>>>>>> 8105c5e2ef5ffb430478bb14923e040665564add
    .landing_guide .row_first .step_hdng .steps:nth-child(2) p.sub_hdng {
        left: 37px;
    }

    .landing_guide .row_first .step_hdng p.sub_hdng {
        left: 29px;
    }*/

    .landing_guide .row_first .step_hdng .step_circle .inner {
        display: block;
        width: 27px;
        height: 27px;
        border: 5px solid #4D5966;
        border-radius: 50%;
        position: relative;
        margin: 0 auto;
    }
}

@media screen and (min-width: 2500px) {
    .landing_guide .row_first .step_hdng .step_circle .inner {
        display: block;
        width: 28px;
        height: 28px;
        border: 5px solid #4D5966;
        border-radius: 50%;
        position: relative;
        margin: 0 auto;
    }
}