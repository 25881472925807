.dataContainer {
    display: grid;
    max-height: 300px;
    overflow: auto;
    width: 100%;
    grid-template-columns: 30% 10% 10% 10% 30% 10%;
    background-color: white;
    border-radius: 7px;
    /* border: 1px solid red; */
}


.dataContainerWrapper {
    background-color: var(--greyColor);
    padding: 17px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;

}


.checkboxColumn {
    display: flex;
    justify-content: left;
    align-items: center;
    vertical-align: middle;
    padding-left: 10px;
    height: 40px;
    /* border: 1px solid green; */
}

.optionName {
    color: #333333b2;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;

    /* border: 1px solid red;  */
}

.mediumOptionName {
    padding-left: 30px;
}

.mainVariableWrapper {
    display: flex;
    justify-content: center;
    background-color: var(--greyColor);
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

.dataContainer>div:nth-child(12n + 13),
.dataContainer>div:nth-child(12n + 14),
.dataContainer>div:nth-child(12n + 15),
.dataContainer>div:nth-child(12n + 16),
.dataContainer>div:nth-child(12n + 17),
.dataContainer>div:nth-child(12n + 18) {
    background: #f0f4f9;
}

.dataContainer>div:nth-child(12n + 1),
.dataContainer>div:nth-child(12n + 2),
.dataContainer>div:nth-child(12n + 3),
.dataContainer>div:nth-child(12n + 4),
.dataContainer>div:nth-child(12n + 5),
.dataContainer>div:nth-child(12n + 6),
.dataContainer>div:nth-last-child(6),
.dataContainer>div:nth-last-child(5),
.dataContainer>div:nth-last-child(4),
.dataContainer>div:nth-last-child(3),
.dataContainer>div:nth-last-child(2),
.dataContainer>div:last-child {
    box-shadow: 0px -1px 0px var(--grid-border-color), inset 0px -1px 0px var(--grid-border-color);
}

.buttonsContainer {
    display: flex;
    margin-block: 20px;
    justify-content: center;
}

.container {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: -5px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #cccccc;
    border: 1.29px solid #b3b3b3;
    border-radius: 5px;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
    background-color: #3c84cc;
    border-color: #00000033;
}

.container input:checked~.checkmark:hover {
    border-color: #8CBFFF;
}
.checkmark:hover {
    position: absolute;
    top: -5px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #cccccc;
    border: 1.29px solid #8CBFFF;
    border-radius: 5px;
}
.checkmark:disabled{
    color:red
}
.container input:checked~.checkmark :hover{
    background-color: #3c84cc;
    border-color: red;
}

 .container input:disabled~.checkmark {
    /* background-color: #5db2f8; */
    opacity: 0.7; 
    background-color: #cccccc;
 } 

 .container input:disabled~.checkmark:after{
    left: 6px;
    top: 1px;
    width: 7px;
    height: 14px;
    border: solid #999999;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(50deg);
 } 

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 6px;
    top: 1px;
    width: 7px;
    height: 14px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(50deg);
}

