.editMenuCloseButton {
	border: none;
	background: none;
	padding: 0;
	display: flex;
	align-items: center;
}

.headerTitle {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 18px;
	color: rgba(36, 33, 36, 0.8);
	margin: 0;
}

.headerTableDescription {
	margin-left: 16px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: rgba(36, 33, 36, 0.6);
}

.advancedOptionsButton,
.advancedOptionsButton:hover,
.advancedOptionsButton:active,
.advancedOptionsButton:focus {
	margin-left: 16px;
	height: 28px;
	border: none;
	display: flex;
	align-items: center;
	color: #326eaa !important;
	font-family: Roboto;
	font-size: 14px;
	font-weight: 500;
}

.equalsLabel {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: white;
	height: 24px;
	width: 130px;
	background: #3b82c9;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.averageLabel {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #242124cc;
}

.tableCell {
	display: flex;
	flex-direction: column;
	width: 150px;
	padding: 8px;
}

.tableHeaderHover {
	background: rgba(235, 242, 249, 0.8);
	border: 1px solid #3b82c9;
}

.tableHeaderSelected {
	background: rgba(233, 233, 233, 0.5);
	border-bottom: 2px solid #3b82c9;
	box-sizing: border-box;
}
.tableHeaderSelectedWithOperation {
	background: rgba(233, 233, 233, 0.5);
}

.tableCellHover {
	background: rgba(235, 242, 249, 0.8);
}

.tableCellSelected {
	background: rgba(233, 233, 233, 0.5);
}

.tableCellSelectedWithOperation {
	background: rgba(233, 233, 233, 0.5);
	border: 1px solid #8db8e3;
	box-sizing: border-box;
}

.dragHandle {
	cursor: pointer;
	display: flex;
	top: -24px;
	position: absolute;
	background: #3b82c9;
	height: 24px;
	width: 100%;
	border-radius: 4px 4px 0px 0px;
	align-items: center;
	justify-content: center;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 15px;
	color: #ffffff;
}