.slider-wrapper {
    background-color: white;
    color: dodgerblue;
    border: solid 1px lightgrey;
    padding: 6.5px;
    border-radius: 2px;
    display: flex;

    > span {
        display: inline-block;
        vertical-align: middle;
    }

    > .slider {
        position: relative;
        margin-left: 4px;
        margin-right: 4px;
        cursor: pointer;
        vertical-align: middle;
    }
}
