.root {
	display: flex;
	flex-direction: column;
}

.editMenuHeader {
	background: #ffffff;
	justify-content: space-between;
	align-items: center;
	display: flex;
}

.editMenuHeaderTitle {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	color: #333333;
	margin: 0;
}

.editMenuCloseButton {
	border: none;
	background: none;
	z-index: 1;
}

.sectionBar {
	height: 48px;
	padding-left: 16px;
	padding-right: 16px;
	display: flex;
}

.sectionTitleContainer {
	cursor: pointer;
	height: 100%;
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: 2px solid #e9e9e9;
}

.sectionTitleContainerSelected {
	border-bottom: 2px solid #3b82c9;
}

.sectionTitle {
	color: #333333cc;
	font-family: Roboto;
	font-size: 16px;
	font-weight: 400;
}

.sectionTitleSelected {
	font-weight: 500;
	color: #333333;
}

.filterRoot {
	display: flex;
	align-items: center;
	margin: 10px 0 0 0;
}

.filterButton {
	cursor: pointer;
}

.filterOption {
	padding: 8px;
	cursor: pointer;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	white-space: nowrap;
	color: rgba(51, 51, 51, 0.8);
}

.filterOptionSelected {
	border: 1px solid #3b82c9;
	color: #326eaa;
	border-radius: 16px;
}

.chartsGrid {
	display: grid;
	grid-row-gap: 12px;
	grid-column-gap: 14px;
	grid-template-columns: repeat(2, 140px);
	margin: 10px 0 0 0;
}

.chartCard {
	cursor: pointer;
	width: 140px;
	height: 140px;
	background: #ffffff;
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	padding: 12px;
	text-align: center;
}

.chartCard:hover {
	background: #ebf2f9;
}

.upDownButtons {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 17px;
	background-color: #ffffff;
	border: none;
	border-radius: 4px;
	color: #333333cc;
	font-family: Roboto;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
}

.upDownButtons:hover,
.upDownButtons:active,
.upDownButtons:focus {
	background-color: #e9e9e9;
	color: #333333cc;
	border: none;
}

.editMenuAdvancedOption {
	margin-top: 20px;
	border: none;
	background: none;
	font-family: Roboto;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	color: #333333B2;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	border-bottom: 1px solid #e9e9e9;
}

.editMenuAdvancedOptionIcon {
	width: 12px;
	height: 6px;
	transform: rotate(90deg);
	transition: all 0.2s ease-out;
}

.editMenuAdvancedOptionIcon > path {
	fill: #606060;
}

.chevronOpen {
	transform: rotate(0deg);
}

.chartCardPremiumBagdge {
	background-color: white;
		margin-top: 2px;
    display: flex;
    color: #3b82c9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
		font-size: 9pt;
		font-weight: bold;
		text-transform: uppercase;
}

.chartTooltip {
	width: 164px;
	border-radius: 10px;
}
