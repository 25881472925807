.editMenuHeader {
    position: relative;
    padding: 15px 20px;
    background: rgba(101, 125, 149, 0.05);
}

.dbLabel {
    position: absolute;
    bottom: 1vh;
    right: 2vw;
}

.editMenuHeaderTitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: rgba(38, 46, 64, 0.7);
    margin: 0;
}

.editMenuCloseButton {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
}

.tabMenu {
    display: flex;
    margin: 0 10px;
}

.tabMenu > a {
    flex-grow: 1;
}

.tabTitle {
    justify-content: center;
}

.tab {
    opacity: 1;
}
