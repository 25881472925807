

.backdrop {
    background-color: rgba(0, 0, 0, 0.2);
}

.dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border: 1px solid rgba(245, 245, 245, 0.5);
    backdrop-filter: blur(
        8px
    ); /* Note: backdrop-filter has minimal browser support */
    border-radius: 10px;
    padding: 40px;
    max-height: 656px;
    max-width: 735px;
    width: 75vw;
    height: 75vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 24px;
    margin: 0 0 10px 0;
    font-weight: 600;
    color: #333;
}

.lastUpdated {
    color: #333;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 15px;
    display: inline-block;
}

.dialog > div {
    background: transparent;
    box-shadow: none;
}

.dialogContent {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.content {
    padding: 0;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.content iframe {
    height: 426px;
    background: #fafafa;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    overflow: auto;
}

.actionButtonsContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
}

.confirmCheckbox {
    font-size: 18px;
    color: #333;
}

.confirmCheckbox > input {
    margin-right: 5px;
}

.confirmCheckbox > label {
    font-weight: 400;
}

.button {
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    margin-right: 10px;
    padding: 10px 20px;
    width: 112px;
    background: #3b82c9;
    border-radius: 6px;
}

.button:last-child {
    margin: 0;
}

.button:disabled {
    opacity: 0.5;
}

.buttonLight {
    border: 1px solid #e0e0e0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #3b82c9;
    margin-right: 10px;
    padding: 10px 20px;
    width: 112px;
    background: #fff;
    border-radius: 6px;
}
