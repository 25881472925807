article.movable-card-wrapper {
    border-radius: 3px;
    border-bottom: 1px solid #ccc;
    background-color: #fff;
    position: relative;
    padding: 10px;
    cursor: pointer;
    max-width: 250px;
    margin-bottom: 7px;
    min-width: 230px;
}

article.movable-card-wrapper:hover {
    background-color: #f0f0f0;
    color: #000;
}
