.questionsContainer {
    position: relative;
    padding: 15px;
    padding-top: 60px;
    padding-bottom: 120px;
}

.questionsHeader {
    font-size: 20px;
    font-weight: 400;
    color: #242124;
    position: absolute;
    top: 10px;
    left: 10px;
}

.add {
    font-weight: 500;
    padding: 5px;
    color: #1f8efa;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    user-select: none;
}

.add:hover {
    transform: scale(1.2);
}

.questionWrapper {
    display: flex;
    flex-direction: column;
}

.questionDivLine {
    padding-top: 5px;
    border-bottom: 1px solid #e9e9e9;
}

.removeQuestion {
    padding: 5px;
    fill: #3b82c9;
    fill-opacity: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.removeQuestion:hover {
    transform: scale(1.2);
}

.questionTitleInput {
    border: 0;
    outline: none;
    width: 90%;
    text-overflow: ellipsis;
}

.questionTitleInput:focus {
    border: 1px solid #1f8efa;
    /*#555*/
    border-radius: 5px;
}

.root {
    display: flex;
    flex-direction: column;
}

.editMenuHeader {
    background: #ffffff;
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.editMenuHeaderTitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    margin: 0;
}

.editMenuCloseButton {
    border: none;
    background: none;
    z-index: 1;
}

.sectionBar {
    height: 48px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
}

.sectionTitleContainer {
    cursor: pointer;
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #e9e9e9;
}

.sectionTitleContainerSelected {
    border-bottom: 2px solid #3b82c9;
}

.sectionTitle {
    color: #333333cc;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
}

.sectionTitleSelected {
    font-weight: 500;
    color: #333333;
}

.filterRoot {
    display: flex;
    align-items: center;
    margin: 10px 0 0 0;
}

.filterButton {
    cursor: pointer;
}

.filterOption {
    padding: 8px;
    cursor: pointer;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    white-space: nowrap;
    color: rgba(51, 51, 51, 0.8);
}

.filterOptionSelected {
    border: 1px solid #3b82c9;
    color: #326eaa;
    border-radius: 16px;
}

.chartsGrid {
    display: grid;
    grid-row-gap: 12px;
    grid-column-gap: 14px;
    grid-template-columns: repeat(2, 140px);
    margin: 10px 0 0 0;
}

.chartCard {
    cursor: pointer;
    width: 140px;
    height: 140px;
    background: #ffffff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    padding: 12px;
    text-align: center;
}

.chartCard:hover {
    background: #ebf2f9;
}

.upDownButtons {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 17px;
    background-color: #ffffff;
    border: none;
    border-radius: 4px;
    color: #333333cc;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
}

.upDownButtons:hover,
.upDownButtons:active,
.upDownButtons:focus {
    background-color: #e9e9e9;
    color: #333333cc;
    border: none;
}

.editMenuAdvancedOption {
    margin-top: 20px;
    border: none;
    background: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #333333b2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #e9e9e9;
}

.editMenuAdvancedOptionContent {
    margin: 15px 0px 0px 5px;
}

.editMenuAdvancedOptionIcon {
    left: 5px;
    position: absolute;
    width: 12px;
    height: 6px;
    transform: rotate(-90deg);
    transition: all 0.2s ease-out;
}

.editMenuAdvancedOptionIcon > path {
    fill: #606060;
}

.chevronOpen {
    transform: rotate(0deg);
}

.accordion button {
	border-bottom: none !important;
}

.allowNansLabel {
    display: block;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(51, 51, 51, 0.7);
}
