.card {
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    margin: 3vh 4vw 0 4vw;
    padding: 3vh 2vw 3vh 2vw;
}

.contentWrapper {
    max-width: 580px;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vh;
    color: #72899F;
    font-size: 25px;
    font-weight: 800;
}

.text {
    color: #72899F;
    font-size: 18px;
    font-weight: 300;
}

.textSmall {
    font-size: 14px;
}

.subtitle {
    display: flex;
    text-align: justify;
    text-justify: inter-word;
    justify-content: center;
    align-items: center;
}

.emailForm {
    margin-top: 5vh;
}

.emailInput {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.input {
    width: 80%;
    height: 33px;
    background: #ffffff;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    outline: none;
}

.line {
    border-bottom: 1px solid #72899F;
    margin-bottom: 2vh;
}

.icon {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: pink;
}

.userWrapper {
    display: flex;
    padding: 5px 0 5px 0;
    gap: 10px;
    justify-content: space-between;
}

.userProfile {
    display: flex;
    gap: 10px;
}

.userList {
    padding-top: 4vh;
}