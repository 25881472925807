.pointerEventsButton {
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
    position: absolute;
    border-radius: 3px;
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
    background-color: white;
    border-color: #000000;
    color: #000000;
    font-size: 25px;
}

.pointerEventsButtonActive {
    border-color: #3c83ca;
    color: #3c83ca;
}
