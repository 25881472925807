.root {
	border: none;
	background: none;
	padding: 10px 15px;
	text-align: left;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.root:last-child {
	margin: 0;
}

.root:focus {
	border: none;
	background: none;
}

.root:active {
	border: none;
	background: none;
	outline: none;
	box-shadow: none;
}

.root:hover {
	border: none;
	background: #3B82C9;
}

.root:active:focus {
	border: none;
	background: none;
	outline: none;
}

.left {
	flex-grow: 1;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.08em;
	color: #fafcff;
	white-space: nowrap;
	display: flex;
	align-items: center;
}

.right {
	padding: 4px;
	display: flex;
}

.left > svg {
	width: 12px;
	height: 12px;
	margin-right: 20px;
}

.premium {
	font-size: 12px;
	color: #C5BCBC;
	margin-left: 10px;
}