.expressionRow {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
}

.title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #606060;
  margin-right: 5px;
}

.expression {
  display: flex;
  flex: 1 1 auto;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  padding: 2px;
}

.parenthesisField {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #606060;
  display: flex;
  align-items: center;
}

.leftParenthesis {
  border-right: 1px solid #E2E2E2;
  padding-left: 5px;
  padding-right: 8px;
}

.rightParenthesis {
  padding-left: 8px;
  padding-right: 5px;
}

.field {
  border-right: 1px solid #E2E2E2;
  padding: 1px 2px;
}

.input {
  color: #333;
  border-radius: 4px;
  border: 1px solid transparent;
  padding: 8px;
}

.input:hover {
  border-color: rgb(204, 204, 204);
}

.conditionField {
  position: relative;
  flex: 1 1 auto;
}

.linkElement {
  position: absolute;
  top: calc(100% + 10px);
  display: flex;
  align-items: center;
}

.linkElementLabel {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #333333;
  margin-left: 5px;
}
