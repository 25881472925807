.react-chatbot-kit-chat-container {
    width: 100%;
    height: 100%;
}

.react-chatbot-kit-chat-inner-container {
    height: 100%;
    background-color: #ffffff;
}

.react-chatbot-kit-chat-message-container {
    padding-left: 0;
    padding-right: 0;
}

.react-chatbot-kit-user-chat-message-container {
    justify-content: flex-start;
    margin: 0;
}

.react-chatbot-kit-chat-header {
    background-color: #333333;
    border-radius: 0px 0px 0px 0px;
    height: 58px;
    padding: 0;
}

.react-chatbot-kit-chat-input {
    font-size: 14px;
}
