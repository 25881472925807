.switchLabel {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #333333;
}

.stylesMenu {
	margin-top: 10px;
	margin-bottom: 20px;
}

.stylesMenuOption {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 15px 0;
}

.accordionMenu {
	margin-top: 20px;
}

.accordionMenu {
	padding-top: 20px;
	border: none;
	border-top: 1px solid #E9E9E9;
	background: none;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 14px;
	color: #242124;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.accordionMenuIcon {
	width: 12px;
	height: 6px;
	transform: rotate(90deg);
	transition: all .2s ease-out;
}

.accordionMenuIcon > path {
	fill: #242124;
}

.chevronOpen {
	transform: rotate(0deg);
}

.switchOn > div:first-child {
	opacity: .2;
}
