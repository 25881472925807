.content {
	margin: 18px 0 0 0;
	position: relative;
}

.tab {
	display: flex;
	width: auto;
	height: 60px;
	padding: 0 33px;
	align-items: center;
	justify-content: center;
	color: #4C5C6B;
	border-radius: 33px;
	background: rgba(228, 247, 255, 0.2);
	border: 1px solid rgba(152, 167, 185, 0.31);
	margin: 0 15px 0 0;
	transition: all 0.2s linear
}

.tab:hover {
	color: #4C5C6B;
	background: #E4F7FF;
	border: 1px solid #3B82C9;
	transition: all 0.2s linear
}

.tab span {
	display: block;
	margin: 0 0 0 16px;
}

.tab img {
	width: 32px;
	height: 32px;
	object-fit: cover;
}

.tabActive {
	color: #fff;
	background: #9B72F2;
	border: 1px solid #5200FF;
}

.scrollableTabsWrapper {
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	white-space: nowrap;
	overflow-x: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.scrollableTabsWrapper::-webkit-scrollbar {
	display: none;
}

.scrollArrow {
	display: none;
	width: 40px;
	height: 100%;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.2);
	border: none;
	position: absolute;
	top: 0;
	z-index: 999;
	transition: all 0.1s linear;
	transform: rotate(-180deg);
}

.scrollableTabsWrapper:hover .scrollArrow {
	display: flex;
}

.scrollArrow:hover {
	background-color: rgba(0, 0, 0, 0.5);
	transition: all 0.1s linear;
}

.scrollArrowRight {
	right: 0;
	transform: rotate(-360deg);
}
