.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.3;
}

.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    background: #ffffff;
    border: 1px solid rgba(245, 245, 245, 0.5);
    border-radius: 10px;
    width: 475px;
    height: 165px;
    display: flex;
    flex-direction: column;
}

.title {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */
    color: #75869a;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    margin-left: 20px;
    margin-top: 15px;
}

.input {
    margin-top: 17px;
    background: #ffffff;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    margin-left: 20px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    outline: none;
    height: 35px;
    width: calc(100% - 40px);
}

.buttonsContainer {
    display: flex;
    width: 100%;
    padding-right: 20px;
    margin-bottom: 14px;
    justify-content: flex-end;
}

.saveButton {
    background: linear-gradient(94.78deg, #1a60b3 0%, #13529b 100%), #ffffff;
    border: 1px solid rgba(4, 30, 55, 0.1);
    box-shadow: 0px 1px 2px rgba(49, 54, 58, 0.04);
    border-radius: 4px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    width: 75px;
    height: 29px;

    color: #ffffff;
}

.saveButton:hover {
    background: #1a60b3;
}

.saveButton:active {
    background: #13529b;
}

.saveButton:disabled {
    background: #dddddd;
    color: #737373;
}

.cancelButton {
    background: #ffffff;
    border: 1px solid #1a60b2;
    border-radius: 6px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    width: 75px;
    height: 29px;
    color: #1a60b2;
}

.cancelButton:hover {
    background: #ccebfa;
}

.cancelButton:active {
    background: #ccebfa;
}

.cancelButton:disabled {
    background: #dddddd;
    color: #737373;
}
