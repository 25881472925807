
/* .OuterBox {
  height: 300px;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
} */

.Background{
    background-image: linear-gradient(180deg, rgb(117, 117, 117), rgb(20, 20, 20));
}

.Wrapper h1{
  color: white; /* or hexidecimal */
}