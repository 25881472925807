.displayMode {
	display: flex;
	align-items: center;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	margin: 10px 0;
}

.title {
	font-size: 12px;
	line-height: 14px;
	color: #242124;
	margin-right: 5px;
}
