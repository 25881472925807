.root {
	z-index: 999;
	cursor: pointer;
	position: absolute;
}

.content {
	width: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: url(icons/pin.svg);
	height: 32px;
}

.text {
	font-family: Roboto;
	font-size: 12px;
	color: #39f;
	margin-bottom: 3px;
}