.dataContainer {
	display: grid;
    height: 100%;
	grid-template-columns: repeat(3, 100px);
	border-bottom: 1px solid #d1d1d1;
	border-left: 1px solid #d1d1d1;
}

.optionContainer {
	margin-top: 20px;
	display: flex;
	align-items: center;
}

.input {
	color: #333333;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	border: 1px solid #d1d1d1;
	outline: none;
	width: 56px;
	height: 35px;
}
