.root > div {
	transition: opacity .2s ease-out;
}

.showStart > div {
	opacity: 1;
}

.showEnd > div {
	opacity: 1;
}

.hideStart > div {
	opacity: 0;
}

.hideEnd > div {
	opacity: 0;
}
