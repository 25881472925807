.datasetsContainer {
    display: flex;
    width: 100%;
    margin-top: 30px;
}

.datasetSelector {
    width: 270px;
    margin-left: 40px;
}

.textItem {
    padding-left: 3px;
    margin-bottom: 11px;
    color: #555555;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
}


.variablesContainer {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    width: 218px;
    border: 1px solid #DBDBDB;
    border-radius: 6px;
    padding: 18px;
    z-index: 8;
}

.editHeader {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 3px;
}

.variableOperationContainer {
    display: flex;
}

.formatOptionsContainer {
    display: flex;
}

.borderLine {
    height: 1px;
    background-color: #e8e8e8;
    margin-left: 22px;
    margin-right: 30px;
}

.deleteBtn {
    background-color: var(--danger-color) !important;
}

.deleteBtn:hover {
    background-color: #de5959 !important;
}

.deleteBtn:active {
    box-shadow: 0px 0px 5px 0px #de595980;

}

.bottomBar {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    margin-right: 76px;
}

.decimalContainer input {
    height: 26px;
    padding-left: 6px;
}