.addLaneButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #3b82c9;
    border-width: 1;
    border-color: rgba(51, 51, 51, 0.1);
    font-family: Roboto;
    font-weight: 500;
    transition: background-color 0.3s ease;
    height: 48px;
    margin-top: 0;
    margin-right: 0;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 0;
    border-radius: 0;
}

.addLaneButton:hover,
.addLaneButton:active,
.addLaneButton:focus {
    color: #3b82c9 !important;
    background-color: #ededed !important;
    transition: background-color 0.3s ease;
}
.card:hover {
    border-color: #555555 !important;
}