.root {
    padding: 20px;
}


.optionContainer {
	margin-top: 20px;
	display: flex;
	align-items: center;
}

.optionName {
	color: #333333;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	min-width: 130px;
}
