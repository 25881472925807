.micBtnWrapper {
  width: 6rem;
  height: 6rem;
  padding-top: 0.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 173, 147, 1);
}

.contentWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10vh;
}

@keyframes slideup {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.iconSlideUp {
  animation: slideup 0.5s ease-out;
}
