.container {
	overflow: auto;
	z-index: 500;
	position: absolute;
	right: 0;
	bottom: 0;
	height: calc(100% - 60px);
	background-color: var(--sheet-ribbon-background-color);
	border-radius: 0;
	box-shadow: -4px 0px 4px 0px #657d951a;
}

.slideNameStyle {
	margin-left: 10px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 13px;
	color: #000000;
	font-weight: 600;
	font-family: Roboto;
}
