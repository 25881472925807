.root {
	display: inline-block;
	border-radius: 50%;
	width: 28px;
	height: 28px;
	overflow: hidden;
}

.image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.textAvatar {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	color: #fff;
	background: linear-gradient(93.16deg, #196AE4 5.24%, #1557CD 94.76%), #FFFFFF;
}
