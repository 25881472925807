.root {
    position: relative;
}

.appsIcon {
    opacity: 0.5;
    width: 16px;
    height: 16px;
}

.nav {
    display: flex;
}

.tab {
    opacity: 1;
    border: none;
}

.presentationIcon {
    opacity: 0.5;
    width: 10px;
    height: 12px;
}

.templateIcon {
    width: 18px;
    height: 18px;
}

.trashIcon {
    opacity: 0.5;
    width: 14px;
    height: 15px;
    margin-bottom: 2px;
}

.actionButton {
	border: none;
	background: #fff;
	padding: .6rem .9rem;
	display: flex;
	align-items: center;
}

.actionButton:focus {
	outline: none;
}

.actionButton:first-child {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.actionButton:last-child {
	border: none;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

.activeButton > svg > path {
	fill: rgba(36, 33, 36, 0.8);
}

.tabContentHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.presentationsTitle {
    color: #4A6781;
    margin: 6px 0 29px 0;
}

@media (max-width: 550px) {
    .nav {
        display: block;
    }
}

.separator {
    height: 1px;
    background-color: #00000026;
}

.yourApps {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: rgba(51, 51, 51, 0.7);
}

.publishButton {
    border: none;
    outline: none;
    width: 86px;
    height: 37px;
    background: #ffffff;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    color: rgba(51, 51, 51, 0.7);
}

.publishButton:hover {
    background: #828282;
}

.publishButton:active {
    background: #a1a1a1;
}

.publishButton:disabled {
    opacity: 0.5;
}

.loadButton {
    border: none;
    outline: none;
    background: #3b82c9;
    border-radius: 6px;
    width: 158px;
    height: 40px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
}

.loadButton:hover {
    background: #11508f;
}

.loadButton:active {
    background: #639cd4;
}

.loadButton:disabled {
    opacity: 0.5;
}

.searchContainer {
    margin-top: 21px;
    display: flex;
    flex-wrap: wrap;
}
