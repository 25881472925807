.listOfHiddenElements {
  position: absolute;
  top: 0;
  right: 190px;
}

.listItem {
  width: max-content;
}

.listOfHiddenElements .listItem div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--content-primary-text-color);
  padding: 0 8px;
}

.listOfHiddenElements .listItem div span {
  width: auto;
}

.listOfHiddenElements div {
  opacity: 1;
}

.listOfHiddenElements .listOfHiddenElementsToggle {
  padding: 0 20px 0 0;
  display: flex;
  align-items: center;
}

.listOfHiddenElements .listOfHiddenElementsToggle:hover {
  background-color: transparent;
}

.listOfHiddenElements:active *:active {
  background-color: transparent;
}

.listOfHiddenElements .listOfHiddenElementsToggle p {
  color: var(--primary-text-color);
  font-weight: 500;
  margin: 0 0 0 4px;
}

.noHiddenElementsText {
  color: var(--content-primary-text-color);
  font-weight: 500;
  margin: auto;
  text-align: center;
}

.listOfHiddenElements svg,
.listOfHiddenElements svg path {
  width: 15px;
  height: 15px;
  stroke: var(--primary-text-color);
  fill: var(--primary-text-color);
}


.listOfHiddenElements .listItem button {
  background-color: transparent;
  color: var(--content-primary-text-color);
  border: none;
}

.listIsHidden {
  display: none;
}