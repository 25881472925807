.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.3;
}

.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    background-color: white;
    border-radius: 8px;
    padding: 30px;
    box-shadow: 10px 10px 25px -12px rgba(0,0,0,0.75);
    width: 100%;
    height: calc(100% - 122px);
    max-width: 1000px;
    max-height: 855px;
    overflow-y: auto;
}

.sectionTitle {
    margin: 0;
    margin-top: 40px;
    font-size: 18px;
}

.sectionDesc {
    color: #333333B2;
}

.buttonsContainer {
    display: flex;
    justify-content: flex-end;
}

.button {
    padding: 10px;
    background-color: #fff;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    font-weight: 500;
    width: 100px;
}

.button2 {
    margin-left: 10px;
    padding: 10px;
    background-color: #3b82c9;
    color: #fff;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    font-weight: 500;
    width: 100px;
}

.cards {
    overflow-x: auto;
    min-height: 260px;
}

.cards > div > div {
    grid-auto-flow: column;
}