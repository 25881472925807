.root {
  padding: 20px;
  padding-bottom: 80px;
}

.inputLabel {
  display: block;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}

.textInput {
  border: 1px solid #D1D1D1;
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: rgba(51, 51, 51, 0.8);
  padding: 10px 15px;
}

.userSelect {
  margin-top: 15px;
}

.otherFields {
  margin-top: 25px;
}

.switchField {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.switchLabel {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  margin-left: 10px;
}

.editMenuAdvancedOption {
  margin: 20px 0;
  padding-bottom: 10px;
  border: none;
  border-bottom: 1px solid #E9E9E9;
  background: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.editMenuAdvancedOptionIcon {
  width: 12px;
  height: 6px;
  transform: rotate(90deg);
  transition: all .2s ease-out;
}

.editMenuAdvancedOptionIcon > path {
  fill: #242124;
}

.chevronOpen {
  transform: rotate(0deg);
}
