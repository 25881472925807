.searchContainer {
    display: flex;
    background: #ffffff;
    border: 1px solid #d1d1d1;
    box-sizing: border-box;
    border-radius: 4px;
    width: 230px;
    height: 35px;
    align-items: center;
    padding-left: 13px;
}

.toolbarContainer {
    margin-left: 34px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.searchInput {
    font-family: Roboto;
    margin-left: 13px;
    border: none;
    padding: 0;
    width: 100%;
    outline: none;
    color: #666666;
}

.searchInput::placeholder {
    color: #666666b2;
}

.borderLine {
    height: 1px;
    background-color: #e8e8e8;
    margin-left: 22px;
    margin-right: 30px;
}

.grid {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.gridRow {
    width: 100%;
    display: flex;
}

.gridTitle {
    margin-top: 23px;
    margin-bottom: 23px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #333333;
}

.actionButton {
    background: #fff;
    border: 1px solid #d1d1d1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
}

.actionButton:focus {
    outline: none;
}

.actionButton:first-child {
    border-right: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.actionButton:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.activeButton {
    background-color: #75869a;
}

.dropzoneContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.gridItem {
    color: #555555;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-top: 23px;
    margin-bottom: 23px;
    display: flex;
    align-items: center;
}

.manageButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
}

.manageButton {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    width: 150px;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    color: #3b82c9;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    text-align: center;
    border-radius: 3px;
}

.manageButton:hover {
    outline: none;
    background-color: #d1d1d1;
}
.manageButton:active {
    outline: none;
    background-color: #c1c1c1;
}
.manageButton:disabled {
    opacity: 0.5;
    background-color: transparent !important;
}

.editInput {
    font-family: Roboto;
    border: 1px solid #d1d1d1;
    background-color: white;
    outline: none;
    color: #666666;
    width: 170px;
}

.collectionContainer {
    margin: 10px;
    display: flex;
    flex-wrap: wrap;
}

.fileContainer {
    width: 100px;
    height: 100px;
    cursor: pointer;
}

.fileContainerDragArea {
    width: 150px;
    display: flex;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    border: 2px dashed #a3a3a3;
}

.reorderCategory {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.reorderCategoryArrow {
    display: block;
    border: none;
    background-color: transparent;
}

.reorderCategoryArrow svg {
    width: 40px;
    height: 40px;
}

.reorderCategoryArrowUp {
    transform: rotate(180deg);
}
