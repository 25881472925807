.root {
    width: 300px;
    height: 200px;
    border-radius: 6px;
    filter: drop-shadow(0px 2px 8px rgba(38, 46, 64, 0.08));
    background: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.image {
    object-fit: contain;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #e9ecf0;
    width: 100%;
    height: 138px;
}

.content {
    padding: 1.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e9ecf0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.leftSide {
    display: flex;
    align-items: center;
}

.icon {
    margin-right: 1.5rem;
    width: 18px;
    height: 18px;
    opacity: 0.5;
    flex-shrink: 0;
}

.title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    margin: 0;
    margin-bottom: 0.4rem;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.avatar {
    flex-shrink: 0;
}

.deleteButton {
    padding: 0;
    border: none;
    background: none;
    position: absolute;
    top: 10px;
    right: 10px;
}

.deleteButton:focus {
    outline: none;
}

.deleteButton:hover {
    opacity: 0.7;
}

.deleteIcon {
    width: 20px;
    height: 20px;
}
