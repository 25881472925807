.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.3;
}

.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    background-color: white;
    border-radius: 8px;
    padding: 30px;
    box-shadow: 10px 10px 25px -12px rgba(0,0,0,0.75);
    max-width: 730px;
}

.optionDesc {
    display: inline-block;
    color: #333333B2;
    margin-top: 40px;
    margin-bottom: 20px;
}

.optionsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.option {
    border: 1px solid #d1d1d1;
    padding: 8px 16px;
    border-radius: 30px;
    margin-right: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    user-select: none;
}

.optionSelected {
    background-color: #75869a;
    color: #fff;
}

.buttonsContainer {
    display: flex;
    justify-content: flex-end;
}

.button {
    padding: 10px;
    background-color: #fff;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    font-weight: 500;
    width: 100px;
}

.button2 {
    margin-left: 10px;
    padding: 10px;
    background-color: #3b82c9;
    color: #fff;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    font-weight: 500;
    width: 100px;
}