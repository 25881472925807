.root {
  margin-top: 15px;
}

.fileRow {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.rowIndex {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  margin-right: 5px;
}

.rowInput {
  background: #F6F6F6;
  border: 1px solid #E2E2E2;
  border-radius: 4px;
  padding: 8px;
  flex: 1 1 auto;
}

.addButton {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #326EAA;
  cursor: pointer;
}
