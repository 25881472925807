.root {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.root > span {
	padding: 1rem .5rem;
}

.icon {
	margin-right: 1.5rem;
	width: 18px;
	height: 18px;
	opacity: .5;
	flex-shrink: 0;
}

.title {
	display: flex;
	align-items: center;
	flex: 1 1 40%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;
}

.owner {
	flex: 1 1 30%;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
}

.lastUpdatedAt {
	flex: 1 1 30%;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
}
