.root {
	margin-top: 16px;
	display: flex;
	flex-direction: column;
}

.highlightVariable {
	background-color: #ebf2f9;
	border-top: 2px solid #8db8e3 !important;
	border-bottom: 2px solid #8db8e3 !important;
	border-left: 2px solid #8db8e3 !important;
}

.highlightValue {
	background-color: #ebf2f9;
	border-top: 2px solid #8db8e3 !important;
	border-bottom: 2px solid #8db8e3 !important;
	border-right: 2px solid #8db8e3 !important;
}

.greyedOutValue {
	background-color: #E0E0E0;
}

.highlightValueVerticalTop {
	background-color: #ebf2f9;
	border-top: 2px solid #8db8e3 !important;
	border-left: 2px solid #8db8e3 !important;
	border-right: 2px solid #8db8e3 !important;
}

.highlightValueVertical {
	background-color: #ebf2f9;
	border-left: 2px solid #8db8e3 !important;
	border-right: 2px solid #8db8e3 !important;
}

.highlightValueVerticalBottom {
	background-color: #ebf2f9;
	border-bottom: 2px solid #8db8e3 !important;
	border-left: 2px solid #8db8e3 !important;
	border-right: 2px solid #8db8e3 !important;
}

.addItemButton {
	background-color: transparent !important;
	border: none;
	color: #326eaa !important;
	font-weight: 500;
	font-size: 14px;
	width: 93px;
}

.addColumnButton {
	background-color: transparent !important;
	border: none;
	color: #326eaa !important;
	font-weight: 700;
	font-size: 14px;
	width: 30px;
	display: flex;
	justify-content: center;
	height: 30px;
}

.deleteColumnButton {
	color: #989898 !important;
}

.selectedDataset {
	padding: 5px 8px 6px;
	display: flex;
	align-items: center;
	background: var(--greyColor);
	/* background: #d7d7d7; */
	border-radius: 4px;
	flex-grow: 1;

	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: #555;
}

.sectionTitle {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	color: #555;
}

.connectButton {
	border: 1px solid #8db8e3 !important;
	background-color: #ebf2f9 !important;
	color: #326eaa !important;
	font-weight: 500;
	font-size: 14px;
}


.header {
	overflow: hidden;
	padding-left: 4px;
	height: 48px;
	display: flex;
	align-items: center;
	font-size: 16px;
	color: #555;
	font-weight: 500;
	font-family: Roboto;
	border: none;
	border-top: 1px solid #d1d1d1;
	border-right: 1px solid #d1d1d1;
}

.aggregationOptionContainer {
	cursor: pointer;
	margin-top: 8px;
	height: 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #ffffff;
	border: 1px solid rgba(51, 51, 51, 0.1);
	border-radius: 4px;
	padding: 12px;
	font-size: 14px;
	color: #555;
	font-family: Roboto;
}

.aggregationOptionContainer:hover {
	background: #ebf2f9;
}

.thinOptionName {
	margin-top: 20px;
	color: #333333B2;
	font-family: Roboto;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #555;
}

.optionName {
	margin-left: 14px;
	width: 154px;
	margin-right: 28px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
}

.optionContainer {
	margin-top: 10px;
	display: flex;
	align-items: center;
}

.optionCheckboxName {
	margin-left: 5px;
	margin-right: 5px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	color: #555;
}

.filterContainer {
	display: flex;
	align-items: center;
	margin-top: 30px;
}

.textItem {
	padding-left: 3px;
	color: #555;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	width: auto;
	margin-right: 15px;
	margin-bottom: 11px;
	display: flex;
	align-items: center;
}

.collapsible {
	background-color: '#E1E1E1'
}

.advancedOptionsWrapper {
	padding: 5px 10px;
	background-color: var(--greyColor);
	border-radius: 5px;
	width: 100%;
}

.advancedOptions {
	border: none;
	margin: 0;
	padding: 0;
	width: 100%;
}

.advancedOptions>div {
	display: flex;
	justify-content: space-between;
	align-items: center;
	vertical-align: middle;
	width: 100%;
	background: var(--greyColor);
	/* background: #d7d7d7; */
	padding-block: 8px;
	/* border: 1px solid red */
}

.advancedOptionsCollapsed {
	display: flex;
	justify-content: space-evenly;
	width: 100%
}

.advancedOptionsCollapsed>div {
	padding: 10px;
	margin: 10px;
	background-color: white;
	border-radius: 5px;
	/* border: 1px solid red; */


}

.buttonsContainer {
	display: flex;
	margin-block: 20px;
	justify-content: center;
}

.advancedOptionsIcon {
	margin-right: 10px;

}

.editMenuAdvancedOptionIcon {
	/* font-size: 15px; */
	/* border: 1px solid red; */
	/* font-weight: 200; */
	width: 20px;

	height: 20px;
}