.slideshowExitHint {
	z-index: 100000000;
}

.slideshowArrowHint {
	left: 100%;
	top: 50%;
	transform: translate(calc(-100% - 20px), -50%);
	width: 300px;
	z-index: 100000000;
}

.icon > path {
	fill: white;
}
