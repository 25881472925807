body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.react-chatbot-kit-chat-message-container {
    overflow: auto !important;
}

.react-chatbot-kit-chat-input-container {
    padding: 15px 25px;
    box-sizing: border-box;
}

.react-chatbot-kit-chat-input-form {
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.react-chatbot-kit-chat-input {
    border-top: none !important;
    outline: none;
}

.react-chatbot-kit-chat-btn-send {
    background-color: #fff !important;
}

.react-chatbot-kit-chat-btn-send-icon {
    fill: #3b82c9 !important;
}

/* This is the container that defines the inner background of the chatbot Could use this to set the color options for the bot.   I added a background-color here because prior to this, it was inheriting the background color behavior in the surrounding div class=dashboard-rect-canvas" */ 
.react-chatbot-kit-chat-inner-container {
    padding-bottom: 60px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* We set the background color dynamically in index.tsx */
    background-color: transparent;
}

@keyframes flicker {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
}

.cursor {
    display: inline-block;
    width: 1ch;
    animation: flicker 0.75s infinite;
    margin-bottom: 0px;
}


