.content {
	margin: 30px 0 56px 0;
}

.themeContainer {
    position: relative;
    display: inline-block;
    width: 320px;
    height: auto;
    margin: 0 20px 0 0;
}

.theme {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.themeCover {
    width: 100%;
    height: 180px;
    background: linear-gradient(115.19deg, #40C9C9 2.92%, #266A4E 96.14%);
    border: 1px solid #E9ECF0;
    border-radius: 6px;
    object-fit: cover;
}

.appCover {
    display: flex;
    width: 100%;
    height: 190px;
    justify-content: center;
    align-items: center;
    border: 1px solid #E9ECF0;
    border-radius: 6px;
    background-color: #fff;
}

.appCover h2 {
    margin: 0;
}

.themeLabel {
    margin: 9px 0 0 20px;
}

.scrollableTabsWrapper {
	flex-wrap: nowrap;
	white-space: nowrap;
	overflow-x: scroll;
	width: 100%;
}
