.root {
	position: fixed;
	top: 20px;
	left: 50%;
	transform: translateX(-50%);
	transition: opacity .2s ease-out;
	padding: 10px 30px;
	background: #2a2d32;
	color: #fafafa;
	visibility: hidden;
	opacity: 0;
}

.showStart {
	visibility: visible;
	opacity: 1;
}

.showEnd {
	visibility: visible;
	opacity: 1;
}

.hideStart {
	visibility: hidden;
	opacity: 0;
}

.hideEnd {
	visibility: hidden;
	opacity: 0;
}

.idle {
	left: -500px;
}

.root > h4 > span {
	border: 1px solid #fafafa;
	padding: 4px 6px;
}
