.mainContainer {
    background: #fff;
    margin: 15px 19px 26px 0;
    border-radius: 10px;
    width: 100%;
    padding-inline: 25px;
}

.datasetsContainer {
    width: 100%;
    margin-top: 30px;
    max-width: 800px;
}

.datasetSelector {
    width: 270px;
    margin-left: 40px;
}

.textItem {
    padding-left: 3px;
    margin-bottom: 11px;
    color: #555555;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
}

.variablesContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--greyColor);
    padding: 14px;
    border-radius: 5px;
}

.variablesWrapper {
    background-color: #fff;
    border-radius: 5px;
}

.dropMissingContainer {
    margin-top: 22px;
    display: flex;
    margin-left: 40px;
}

.borderLine {
    height: 1px;
    background-color: #e8e8e8;
    margin-left: 22px;
    margin-right: 30px;
}

.bottomBarButton {
    border: none;
    outline: none;
    background: #3b82c9;
    border-radius: 6px;
    width: 158px;
    height: 40px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
}

.bottomBarButton:hover {
    background: #11508f;
}

.bottomBarButton:active {
    background: #639cd4;
}

.bottomBarButton:disabled {
    opacity: 0.5;
}

.bottomBar {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    margin-right: 76px;
}

.inputOutline:focus {
    outline: 1px solid #2684ff !important;
}

@media only screen and (min-width:2000px) {
    .inputOutline {
        height: 31px !important;
    }
}