.formatSelectors {
  display: flex;
  align-items: center;
}

.formatSelectors > * {
  margin-right: 10px;
}

.formatSelectors > *:last-child {
  margin: 0;
}

.textInput {
  border: 1px solid #D1D1D1;
  border-radius: 4px;
  padding: 8px;
  width: 45px;
}

.colorSelectOption {
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 0 5px;
}

.colorSample {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 2px;
}
