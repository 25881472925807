.appCreatorDropzone {
  display: flex;
  margin: 10px 0 0 21px;
  width: 100px;
  height: 100px;
  text-align: center;
  border-radius: 4px;
  align-items: center;
  background-color: rgb(217, 221, 225);
  color: var(--popup-primary-text-color);
}

.appCreatorLable {
  margin: 0 0 0 23px;
  color: var(--popup-primary-text-color);
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto";
}

.appCreatorPWAIconContainer {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 5px;
  align-items: center;
  justify-content: center;
  display: flex
}

.appCreatorPWAIconContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  padding: 2px;
}
