.postMessageContainer {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
}

.postMessageHeader {
    font-size: 20px;
    font-weight: 400;
    color: #242124;
}

.subtitle {
    margin: 0;
}

.selectWrapper {
    padding-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.add {
    font-weight: 500;
    padding: 5px;
    color: #1f8efa;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    user-select: none;
}

.removeQuestion {
    padding: 5px;
    margin-right: 21px;
    fill: #3b82c9;
    fill-opacity: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.removeQuestion:hover {
    transform: scale(1.2);
}
