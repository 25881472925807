.fullScreenContainer {
    /* 60px is the navigation bar height */
    height: calc(100vh - 60px);
    max-height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
}

.previewContainer {
    flex: 1;
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    background-color: white;
}
