.barOverlay {
    padding: 3px;
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 0px 3px rgba(59, 130, 201, 0.5);
    border-radius: 6px;
    border: 1px solid #3b82c9;
    display: flex;
    flex-direction: column;
}

.input {
    background: #ffffff;
    border: 1px solid #d1d1d1;
    box-sizing: border-box;
    border-radius: 4px;
    height: 26px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    outline: none;
    color: rgba(51, 51, 51);
    text-align: center;
}
.input::placeholder {
    color: rgba(51, 51, 51, 0.6);
}

.chevronContainer {
    background: #3b82c9;
    cursor: pointer;
    display: flex;
    padding: 3px;
}
