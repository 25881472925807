.rowWithoutPlusMinus {
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    outline: none;
}

.rowWithoutPlusMinus:focus-within {
    border: 1px solid rgba(59, 130, 201, 0.7);
    box-shadow: 0px 0px 4px rgba(59, 118, 201, 0.5);
    border-radius: 4px;
}
