.dataContainer {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	border-bottom: 1px solid #d1d1d1;
	border-left: 1px solid #d1d1d1;
}

.borderCell {
	border: none;
	border-top: 1px solid #d1d1d1;
	border-right: 1px solid #d1d1d1;
}

.optionName {
	color: #333333;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	min-width: 130px;
}

.optionContainer {
	margin-top: 20px;
	display: flex;
	align-items: center;
}
.input {
	color: #333333;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	border: 1px solid #d1d1d1;
	outline: none;
	width: 56px;
	height: 35px;
}


.blankCell {
	overflow: hidden;
	display: flex;
	align-items: center;
	font-size: 16px;
	color: #333333;
	font-weight: 500;
	font-family: Roboto;
	border: none;
	border-top: 1px solid #d1d1d1;
	border-right: 1px solid #d1d1d1;
}

.dropArea {
	box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.highlightDropArea {
	background-color: #ebf2f9;
	border: 2px solid #8db8e3 !important;
}
