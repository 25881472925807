.dataContainer {
	display: flex;
	flex-direction: column;
}

.optionName {
	color: #333333;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
}

.optionContainer {
	margin-top: 20px;
	display: flex;
	align-items: center;
}

.input {
	color: #333333;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	border: 1px solid #d1d1d1;
	outline: none;
	width: 56px;
	height: 38px;
	text-align: center;
	border-radius: 3px;
}

.selectLabel {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #000000;
	display: block;
	margin-bottom: 10px;
}

.colorContainer {
	padding-top: 5px;
	padding-bottom: 5px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
