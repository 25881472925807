.container {
	width: 300px;
	position: absolute;
	z-index: 999;
	background: #ffffff;
	box-shadow: 0 2px 14px rgb(0 0 0 / 15%), 0 0 0 0.5px rgb(0 0 0 / 20%);
}

.editTextStyle {
	resize: none;
	outline: none;
	border: none;
	font-size: 12px;
	color: #000000;
	font-weight: 400;
	font-family: Roboto;
}

.newTextStyle {
	border-top: 1px solid #cccccc;
	padding-top: 10px;
	padding-left: 10px;
	padding-right: 10px;
	width: 100%;
	min-height: 40px;
}

.toggleStyle {
	font-weight: 400;
	font-family: Roboto;
	font-size: 13px;
	color: #000000;
}

.menuStyle {
	background: #ffffff;
	max-width: 20px;
	box-shadow: 0 2px 14px rgb(0 0 0 / 15%), 0 0 0 0.5px rgb(0 0 0 / 20%);
}

.menuItemStyle {
	display: block;
	padding: 5px;
	text-align: center;
	font-weight: 400;
	font-family: Roboto;
	font-size: 13px;
	color: #000000;
}

.userNameTextStyle {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 13px;
	color: #000000;
	font-weight: 600;
	font-family: Roboto;
}

.timeTextStyle {
	margin-left: 10px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 13px;
	color: #b3b3b3;
	font-family: Roboto;
}