@import "../styles/App.css";
@import "../styles/buttons.css";
@import "../styles/input.css";
@import "../styles/div_span.css";

.AppWrapper {
    width: 500px;
    height: 400px;
    position: relative;
}

@keyframes fade {
    from {
        opacity: 0;
        transform: scale3D(0.95, 0.95, 0.95);
    }
    to {
        opacity: 1;
        transform: scale3D(1, 1, 1);
    }
}

.Form {
    animation: fade 200ms ease-out;
}

.FormGroup {
    margin: 0 15px 20px;
    padding: 0;
    border-style: none;
    will-change: opacity, transform;
    /*box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
        inset 0 1px 0 #829fff;*/
    border-radius: 4px;
}

.FormRow {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
}

.FormRow:first-child {
    border-top: none;
}

.FormRowLabel {
    width: 15%;
    min-width: 70px;
    padding: 11px 0;
    color: var(--popup-primary-text-color);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.FormRow select {
    border: none;
    color: var(--selectors-text-color);
    background-color: var(--selectors-background-color);
}

@keyframes void-animation-out {
    0%,
    to {
        opacity: 1;
    }
}
.FormRowInput:-webkit-autofill {
    -webkit-text-fill-color: var(--selectors-text-color);
    /* Hack to hide the default webkit autofill */
    transition: background-color 100000000s;
    animation: 1ms void-animation-out;
}

.FormRowInput {
    color: var(--selectors-text-color) !important;
    font-size: 16px;
    width: 100%;
    padding: 11px 15px 11px 0;
    animation: 1ms void-animation-out;
}

.FormRowInput::placeholder {
    color: rgba(0,0,0, 0.5) !important; /* #03b3e48a; */
}

.StripeElement--webkit-autofill {
    background: transparent !important;
}

.StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
}

.SubmitButton {
    display: block;
    font-size: 16px;
    width: calc(100% - 30px);
    height: 40px;
    margin: 40px 15px 0;
    /*
    background-color: #03b3e4;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
        inset 0 1px 0 #ffb9f6;
    border-radius: 4px;
    color: #fff;
    */
    font-weight: 600;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    will-change: transform, background-color, box-shadow;
}

.SubmitButton:active {
    /*
    background-color: #0599c2;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
        inset 0 1px 0 #0599c2;
    */
    transform: scale(0.99);
}

.SubmitButton.SubmitButton--error {
    transform: translateY(15px);
}
.SubmitButton.SubmitButton--error:active {
    transform: scale(0.99) translateY(15px);
}

.SubmitButton:disabled {
    opacity: 0.5;
    cursor: default;
    /* background-color: #0599c2; */
    box-shadow: none;
}

.ErrorMessage {
    color: var(--popup-primary-text-color);
    /* position: absolute; */
    display: flex;
    justify-content: center;
    padding: 0 15px;
    font-size: 13px;
    margin-top: 0px;
    width: 100%;
    box-sizing: border-box;
    transform: translateY(-15px);
    opacity: 0;
    animation: fade 150ms ease-out;
    animation-delay: 50ms;
    animation-fill-mode: forwards;
    will-change: opacity, transform;
}

.ErrorMessage svg {
    margin-right: 10px;
}

.Result {
    margin-top: 50px;
    text-align: center;
    animation: fade 200ms ease-out;
}

.ResultTitle {
    color: var(--popup-primary-text-color);
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 17px;
    text-align: center;
}

.ResultMessage {
    color: var(--popup-primary-text-color);
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 25px;
    line-height: 1.6em;
    text-align: center;
}

.ResetButton {
    border: 0;
    cursor: pointer;
    background: transparent;
}

.section-main {
}

.section-content {
}

.section-content table {
}

.section-content table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

/*
Comment these styles as they affect all tables
td,
th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: #3f4bcc;
}*/

/* New Checkout Form */

.Logo {
    position: relative;
    width: 60px;
    height: 60px;
    margin: -25px auto 12px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 0 10px -1px rgb(50 50 93 / 25%), 0 0 5px -1px rgb(0 0 0 / 30%);
    overflow: hidden;
}

.LogoImage {
    width: 60px;
    height: 60px;
}

.CheckoutContainer {
    z-index: 1;
    width: 580px;
    place-self: center;
}

.TitleContainer {
    color: var(--popup-primary-text-color);
    text-align: center;
}

.CheckoutContainer .Form {
    margin-top: 16px;
    padding: 16px 40px 40px;
    border-radius: 0 0 6px 6px;
}

.CheckoutContainer .FormGroup {
    display: flex;
}

.CheckoutContainer .FormRowInput {
    color: var(--selectors-text-color);
}

.CheckoutContainer .FormRowLabel {
    color: var(--popup-primary-text-color);
}

.CheckoutContainer .ElementsApp,
.CheckoutContainer .ElementsApp .InputElement {
    color: var(--selectors-text-color) !important;
}

input.InputElement {
    color: var(--selectors-text-color) !important;
}
