.sectionContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 11px;
}

.title {
    font-size: 30px;
    font-weight: bold;
    font-family: Roboto;
    color: white;
    display: flex;
    justify-content: left;
    margin-top: 28px;
    margin-bottom: 28px;
    padding-left: 50px;
    width: 100%;
}

.description {
    font-size: 20px;
    font-family: Roboto;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.fieldsContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 20px;
}

.logo {
    max-width: 90%;
    max-height: 90%;
    margin-bottom: 28px;
}

.field {
    box-sizing: border-box;

    width: 100%;
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    border: none;

    background: rgba(10, 10, 10, 0.4);
    border-radius: 6px;

    font-family: Roboto;
    font-size: 16px;
    line-height: 19px;
    color:white;
}

.field:-webkit-autofill,
.field:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
    -webkit-box-shadow: none !important;
    -webkit-text-fill-color: white !important;
}
.field:-webkit-autofill::first-line {
    font-size: 16px;
    line-height: 19px;
}
.field:-webkit-autofill::first-line {
    font-size: 16px;
    line-height: 19px;
}
.field[data-autocompleted] {
    background-color: transparent !important;
}

.field:focus {
    outline: none;
}

.field::placeholder,
.field::-webkit-input-placeholder,
.field:-moz-placeholder,
.field::-moz-placeholder,
.field:-ms-input-placeholder,
.field::-ms-input-placeholder {
    font-family: Roboto;
    font-size: 16px;
    line-height: 19px;
    color: rgba(140, 140, 140, 0.9) !important;
    opacity: 1;
}

.fieldLabel {
    font-size: 16px;
    font-family: Roboto;
    color: #FFFFFFB2;
    display: flex;
    justify-content: left;
    margin-bottom: 6px;
    padding-left: 15px;
    width: 100%;
}

.forgotPassword {
    width: 100%;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding-right: 15px;

    display: flex;
    justify-content: flex-end;
    margin-bottom: 35px;

    color: #3b82c9;
}

.btnBlue {
    box-shadow: none;
    background-color: #3b82c9 !important;
    border-color: #3b82c9 !important;
    border-radius: 6px !important;
    color: white !important;
    font-size: 18px !important;
    line-height: 1 !important;
    margin-top: 35px !important;
    margin-bottom: 21px !important;
}
.btnBlue:hover {
    box-shadow: 0 14px 26px -12px rgba(52, 71, 103, 0.4),
        0 4px 23px 0 rgba(52, 71, 103, 0.15),
        0 8px 10px -5px rgba(52, 71, 103, 0.2);
}
.btnBlue {
    border: 1px solid #3b82c9 !important;
}
.btnBlue:not(:disabled):not(.disabled).active,
.btnBlue:not(:disabled):not(.disabled):active {
    color: color-yiq(#3b82c9) !important;
    background-color: #3b82c9 !important;
}

.bgGradient {    
    background: 
        linear-gradient(0deg, #387EC5 0%, rgba(117, 132, 148, 0) 171.86%),
        linear-gradient(0deg, #141414 -6.6%, rgba(255, 255, 255, 0) 35.17%),
        linear-gradient(0deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 77.78%);
    opacity: 1;
    mix-blend-mode: normal;
    transform: matrix(1, 0, 0, -1, 0, 0);
    position: absolute;
    background-size: cover;
    background-position: 50%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.footer {
    display:flex;
    justify-content: center;
    align-items:center;
    background: #242424;
    margin-bottom: 0 !important;
    width: 100%;
    height: 54px;
    border-radius: 0px 0px 9px 9px;
}

.footerText {
    margin: 0px;
    font-family: Roboto;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    color: white;
}

.footerLink {
    padding-left: 5px;
    cursor: pointer;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #3b82c9;
}

.errorMessage {
    margin-bottom: 21px !important;
}
