.container {
	display: flex;
	padding: 10px;
	flex-direction: column;
	cursor: default;
	position: relative;
}

.container:hover {
	background-color: #e5e5e5;
}

.highlighted {
		
}

.headerContainer {
	display: flex;
	margin-left: 5px;
	align-items: flex-end;
}

.userInfoContainer {
	display: flex;
	margin-top: 10px;
}

.pin {
	width: 16px;
	background: url(icons/pin.svg);
	height: 16px;
}

.lastCommentTextStyle {
	margin-top: 10px;
	resize: none;
	outline: none;
	border: none;
	font-size: 10px;
	color: #000000;
	background-color: transparent;
	font-weight: 400;
	font-family: Roboto;
}

.secondaryTextStyle {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 10px;
	color: #b3b3b3;
	font-family: Roboto;
}