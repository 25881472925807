.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.3;
}

.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    background: #f9f9f9;
    border: 1px solid rgba(245, 245, 245, 0.5);
    box-sizing: border-box;
    border-radius: 3px;
}

.topContainer {
    margin-top: 38px;
    margin-left: 27px;
    margin-right: 27px;
    display: flex;
}

.cardsContainer {
    margin-top: 37px;
    display: flex;
    padding-left: 45px;
    padding-right: 31px;
    margin-bottom: 52px;
}

.cardContainer {
    margin-right: 14px;
    width: 240px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
    padding-top: 79px;
    padding-left: 18px;
    padding-right: 18px;
    display: flex;
    flex-direction: column;
}

.cardContainer:hover {
    border: 1.5px solid #3b82c9;
    filter: drop-shadow(0px 0px 10px #3b82c9);
}

.cardContainerCurrent {
    border: 1px solid #3b82c9;
}

.title {
    width: 100%;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
}

.cardTitle {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #333333;
}

.cardSubtitle {
    margin-top: 11px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #737373;
}

.oneLineSeparator {
    background-color: #d9d9d9;
    margin-top: 26px;
    height: 1px;
    width: 100%;
}

.twoLineSeparator {
    background-color: #d9d9d9;
    margin-top: 13px;
    height: 1px;
    width: 100%;
}

.feature {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    margin-bottom: 19px;
    color: rgba(51, 51, 51, 0.9);
}

.pricePaymentContainer {
    display: flex;
    margin-top: 10px;
    margin-bottom: 27px;
}

.paymentType {
    align-self: flex-end;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
}

.price {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}

.selectButton {
    height: 40px;
    border: none;
    outline: none;
    align-self: center;
    align-items: center;
    justify-content: center;
    background: #dcebfa;
    border-radius: 6px;
    width: 204px;
    margin-bottom: 12px;
    margin-top: 57px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    color: #326eaa;
}

.selectButton:hover {
    border: 1px solid #326eaa;
}

.selectButton:active {
    background: #ccebfa;
}

.selectButton:disabled {
    background: #dddddd;
    color: #737373;
}
