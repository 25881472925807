.root {
    padding: 20px;
}

.field {
    margin-bottom: 10px;
}

.label {
    display: block;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: rgba(51, 51, 51, 0.7);
    margin-bottom: 5px;
}


.otherFields {
    margin-top: 25px;
}

.switchField {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.switchLabel {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    margin-left: 10px;
}

.editMenuAdvancedOption {
    margin: 20px 0;
    padding-bottom: 10px;
    border: none;
    border-bottom: 1px solid #e9e9e9;
    background: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.editMenuAdvancedOptionIcon {
    width: 12px;
    height: 6px;
    transform: rotate(90deg);
    transition: all 0.2s ease-out;
}

.editMenuAdvancedOptionIcon > path {
    fill: #242124;
}

.chevronOpen {
    transform: rotate(0deg);
}
