.button {
	border: none;
	background: none;
	color: #000;
	padding: 0;
}

.button:hover {
	background: none;
	color: #000;
	outline: none;
}

.button:active {
	background: none;
	color: #000;
	outline: none;
	box-shadow: none;
}

.button:focus {
	background: none;
	color: #000;
	outline: none;
}

.button:active:hover {
	background: none;
	color: #000;
	outline: none;
}
