.rdw-fontsize-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap
}
.rdw-fontsize-dropdown {
  min-width: 40px;
  height: 20px;
}
.rdw-fontsize-button {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.07);
  color: #fff;
  border: none;
}
.rdw-fontsize-option {
  display: flex;
  justify-content: center;
}
