#bars {
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bar {
  background: rgba(0, 173, 147, 1);
  bottom: 1px;
  height: 5px;
  width: 8px;
  margin: 0px 2px;
  border-radius: 5px; 
}

.bar.loading {
  height: 5px;
  animation: sound 0ms -300ms ease-out infinite alternate;
}


@keyframes sound {
  0% {
     opacity: .35;
      height: 10px; 
  }
  100% {
      opacity: 1;       
      height: 150px;        
  }
}

.loading {
  background: rgb(255, 85, 85);
  bottom: 1px;
  height: 3px;
  width: 10px;
  margin: 0px 2px;
  border-radius: 5px;
  animation: loading 0ms -300ms ease-out infinite alternate;
}

@keyframes loading {
  0% {
     opacity: 1;
      height: 5px; 
  }
  100% {
      opacity: 1;       
      height: 50px;        
  }
}

/*
.loading:nth-child(1)  { left: 1px; animation-duration: 500ms; animation-delay: -100ms;}
.loading:nth-child(2)  { left: 15px; animation-duration: 500ms; animation-delay: -60ms;}
.loading:nth-child(3)  { left: 29px; animation-duration: 500ms; animation-delay: -40ms;}
.loading:nth-child(4)  { left: 43px; animation-duration: 500ms; animation-delay: -20ms;}
.loading:nth-child(5)  { left: 57px; animation-duration: 500ms; animation-delay: 0ms;}
.loading:nth-child(6)  { left: 71px; animation-duration: 500ms; animation-delay: 40ms;}
.loading:nth-child(7)  { left: 85px; animation-duration: 500ms; animation-delay: 60ms;}
.loading:nth-child(8)  { left: 99px; animation-duration: 500ms; animation-delay: 100ms;}
.loading:nth-child(9)  { left: 113px; animation-duration: 500ms; animation-delay: 140ms;}
.loading:nth-child(10) { left: 127px; animation-duration: 500ms; animation-delay: 180ms;}

.bar:nth-child(1)  { left: 1px; animation-duration: 674ms; }
.bar:nth-child(2)  { left: 15px; animation-duration: 633ms; }
.bar:nth-child(3)  { left: 29px; animation-duration: 607ms; }
.bar:nth-child(4)  { left: 43px; animation-duration: 658ms; }
.bar:nth-child(5)  { left: 57px; animation-duration: 600ms; }
.bar:nth-child(6)  { left: 71px; animation-duration: 627ms; }
.bar:nth-child(7)  { left: 85px; animation-duration: 641ms; }
.bar:nth-child(8)  { left: 99px; animation-duration: 619ms; }
.bar:nth-child(9)  { left: 113px; animation-duration: 687ms; }
.bar:nth-child(10) { left: 127px; animation-duration: 642ms; }
*/

.loading:nth-child(1)  { left: 1px; animation-duration: 500ms; animation-delay: -100ms; }
.loading:nth-child(2)  { left: 15px; animation-duration: 500ms; animation-delay: -60ms; }
.loading:nth-child(3)  { left: 29px; animation-duration: 500ms; animation-delay: -40ms; }
.loading:nth-child(4)  { left: 43px; animation-duration: 500ms; animation-delay: -20ms; }
.loading:nth-child(5)  { left: 57px; animation-duration: 500ms; animation-delay: 0ms; }
.loading:nth-child(6)  { left: 71px; animation-duration: 500ms; animation-delay: 40ms; }
.loading:nth-child(7)  { left: 85px; animation-duration: 500ms; animation-delay: 60ms; }
.loading:nth-child(8)  { left: 99px; animation-duration: 500ms; animation-delay: 100ms; }
.loading:nth-child(9)  { left: 113px; animation-duration: 500ms; animation-delay: 140ms; }
.loading:nth-child(10) { left: 127px; animation-duration: 500ms; animation-delay: 180ms; }
.loading:nth-child(11) { left: 141px; animation-duration: 500ms; animation-delay: 200ms; }
.loading:nth-child(12) { left: 155px; animation-duration: 500ms; animation-delay: 220ms; }
.loading:nth-child(13) { left: 169px; animation-duration: 500ms; animation-delay: 240ms; }
.loading:nth-child(14) { left: 183px; animation-duration: 500ms; animation-delay: 260ms; }
.loading:nth-child(15) { left: 197px; animation-duration: 500ms; animation-delay: 280ms; }
.loading:nth-child(16) { left: 211px; animation-duration: 500ms; animation-delay: 300ms; }
.loading:nth-child(17) { left: 225px; animation-duration: 500ms; animation-delay: 320ms; }
.loading:nth-child(18) { left: 239px; animation-duration: 500ms; animation-delay: 340ms; }
.loading:nth-child(19) { left: 253px; animation-duration: 500ms; animation-delay: 360ms; }
.loading:nth-child(20) { left: 267px; animation-duration: 500ms; animation-delay: 380ms; }

.bar.loading:nth-child(1)  { left: 1px; animation-duration: 674ms; }
.bar.loading:nth-child(2)  { left: 15px; animation-duration: 633ms; }
.bar.loading:nth-child(3)  { left: 29px; animation-duration: 607ms; }
.bar.loading:nth-child(4)  { left: 43px; animation-duration: 658ms; }
.bar.loading:nth-child(5)  { left: 57px; animation-duration: 600ms; }
.bar.loading:nth-child(6)  { left: 71px; animation-duration: 627ms; }
.bar.loading:nth-child(7)  { left: 85px; animation-duration: 641ms; }
.bar.loading:nth-child(8)  { left: 99px; animation-duration: 619ms; }
.bar.loading:nth-child(9)  { left: 113px; animation-duration: 687ms; }
.bar.loading:nth-child(10) { left: 127px; animation-duration: 642ms; }
.bar.loading:nth-child(11) { left: 141px; animation-duration: 674ms; }
.bar.loading:nth-child(12) { left: 155px; animation-duration: 633ms; }
.bar.loading:nth-child(13) { left: 169px; animation-duration: 607ms; }
.bar.loading:nth-child(14) { left: 183px; animation-duration: 658ms; }
.bar.loading:nth-child(15) { left: 197px; animation-duration: 600ms; }
.bar.loading:nth-child(16) { left: 211px; animation-duration: 627ms; }
.bar.loading:nth-child(17) { left: 225px; animation-duration: 641ms; }
.bar.loading:nth-child(18) { left: 239px; animation-duration: 619ms; }
.bar.loading:nth-child(19) { left: 253px; animation-duration: 687ms; }
.bar.loading:nth-child(20) { left: 267px; animation-duration: 642ms; }