.wrapper {
    margin-top: 15px;
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-columns: 1fr 1fr;
}

.flexRow {
    display: flex;
    flex-direction: row;
}

.input {
    max-width: 40px;
    margin-left: 20px;
    border: 2px solid #e9e9e9;
}

.input:focus {
    border: 2px solid #3b82c9;
    outline: none !important;
}

.text {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 20px;
}
