.clearDataButton {
    font-weight: 400;
    font-size: 15px;
    min-width: 110px;
    border: 1px solid #e8e8e8;
    border-radius: 6px;
    font-weight: bold;
    outline: none !important;
    background: var(--danger-color);
    color: #ffffff;
    padding-top: 1px;
    padding-bottom: 1px;
}

.clearDataButton:hover,
.clearDataButton:focus,
.clearDataButton:active {
    background: linear-gradient(94.78deg, #b3351a 0%, #ac3116 30%), #ffffff;
    color: #ffffff;
    border: 1px solid #e8e8e8;
}

.clearDataButton:disabled {
    opacity: 0.5;
}
