.expressionRow {
  margin-top: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #E9E9E9;
}

.expressionRow:last-child {
  border: none;
  padding: 0;
}

.expressionColorRow {
  display: flex;
  align-items: center;
}

.expressionColorRow > * {
  margin-right: 10px;
}

.expressionColorRow > *:last-child {
  margin: 0;
}

.expressionCollapseButton {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: rgba(51, 51, 51, 0.7);
  border: none;
  background: none;
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.expressionCollapseButton:hover {
  background: none;
  border: none;
}

.expressionCollapseButton:focus {
  background: none;
  border: none;
}

.expressionCollapseButton:active {
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
}

.expressionCollapseButton:active:focus {
  background: none;
  border: none;
  outline: none;
}

.expressionCollapseButtonIcon {
  width: 12px;
  height: 6px;
  transform: rotate(-90deg);
  transition: all .2s ease-out;
  margin-right: 10px;
}

.expressionCollapseButtonIcon > path {
  fill: #606060;
}

.chevronOpen {
  transform: rotate(0deg);
}

.textInput {
  border: 1px solid #D1D1D1;
  border-radius: 4px;
  padding: 8px;
  width: 45px;
}

.addRemoveButtonsContainer {
  display: flex;
  justify-content: space-between;
}

.addRemoveButton {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #3B82C9;
  padding: 10px 0;
  background: none;
  border: none;
}

.addRemoveButton:hover {
  background: none;
  border: none;
}

.addRemoveButton:focus {
  background: none;
  border: none;
}

.addRemoveButton:active {
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
}

.addRemoveButton:active:focus {
  background: none;
  border: none;
  outline: none;
}
