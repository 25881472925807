.dataContainer {
	width: 300px;
	display: grid;
	grid-template-columns: repeat(2, 150px);
	border-bottom: 1px solid #d1d1d1;
	border-left: 1px solid #d1d1d1;
}

.thinOptionName {
	margin-top: 20px;
	color: #333333B2;
	font-family: Roboto;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #555;
}

.input {
    width: 92%;
    min-height: 38px;
	border-radius: 4px;
	border: 1px solid #d1d1d1;
	resize: none;
	overflow: hidden;
	display: inline-block;
    padding-left: 5px;
}

.input:focus {
	border: 2px solid rgb(51, 153, 255);
	box-shadow: none;
	outline: none !important;
}
