.content {
	max-width: 1260px;
	margin: 0 auto;
	padding-top: 25px;
}

.topBarsContainer {
	background: rgba(238, 238, 238, 0.4);
	padding: 0 0 12px 0;
}

.createNewSection {
	display: flex;
	align-items: center;
	margin: 26px 0 0 0;
}

.createNewSection p {
	margin: 0 13px 0 0;
	color: #4A6781;
}

.smallText {
	font-family: "Roboto";
	font-size: 14px;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: 0em;
}

.middleText {
	font-family: "Roboto";
	font-size: 16px;
	font-weight: 600;
	line-height: 19px;
	letter-spacing: 0em;
}

.bigText {
	font-family: "Roboto";
	font-size: 18px;
	font-weight: 500;
	line-height: 21px;
	letter-spacing: 0em;
}

.smallTitle {
	font-family: "Roboto";
	font-size: 18px;
	font-weight: 600;
	line-height: 22px;
	letter-spacing: 0.02em;
	color: #4A6781;
	margin: 0;
}

@media (max-width: 550px) {
	.content {
		padding: 25px 8px 0 8px;
	}
}
