.root {
	position: fixed;
	z-index: 9999;
	box-shadow: rgb(0 0 0 / 30%) 0px 0px 4px;
	background: rgb(255, 255, 255);
	bottom: 0;
	height: 0;
	overflow: auto;
}

.open {
	height: 200px;
	animation: popout .2s ease-out;
	-webkit-animation: popout .2s ease-out;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px;
	background: rgba(101, 125, 149, 0.05);
}

.headerTitle {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 18px;
	color: rgba(36, 33, 36, 0.8);
	margin: 0;
}

.headerTableDescription {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: rgba(36, 33, 36, 0.6);
}

@keyframes popout {
	from{transform:scale(0)}
	to{transform:scale(1)}
}

@-webkit-keyframes popout {
	from{-webkit-transform:scale(0)}
	to{-webkit-transform:scale(1)}
}
