.dataHubTitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;
    color: rgba(51, 51, 51, 0.7);
}

.dataHubButtonsContainer {
    background: #ffffff;
    border: 1px solid #d1d1d1;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-radius: 4px;
    height: 35px;
    padding-left: 1px;
    padding-right: 1px;
    width: fit-content;
}

.linkIcon {
    background-color: var(--saveBtn-color);
    width: 44px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
    border: none;
    outline: none;
    position: absolute;
    right: 2px;
}

.linkIcon:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.linkIcon:hover {
    background-color: var(--saveBtn-hoverColor);
}

.linkIcon:active {
    box-shadow: 0px 0px 5px 0px #4AA5FC80;
}

.advancedOptionsChild {
    flex: 1;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-inline: 25px;
}

.nameDataset {
    width: 100%;
    height: 38px;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    color: var(--selectors-text-color);
    font-size: 14px;
    height: 37px;
    border: 1px solid #d1d1d1;
    padding-left: 6px;
}

.nameDataset::placeholder {
    color: #33333380 !important
}

.nameDataset:focus {
    outline-color: rgba(38, 132, 255) !important;
}

.inputBorder:focus {
    outline: 1px solid rgba(38, 132, 255) !important;
}

.inputBorder:focus:hover {
    outline: 2px solid rgba(38, 132, 255) !important;
    outline-offset: -1px;
}

.nameDataset:hover {
    border: 1px solid #b3b3b3
}

.url::placeholder {
    color: #33333380 !important;
}

.backBtn:active {
    outline: none !important;
    box-shadow: none !important;
}


.valueBox {
    color: var(--selectors-text-color) !important;
    background-color: #fff !important;
    border-radius: 6px !important;
}

.valueBox::placeholder {
    color: #33333380 !important;
}

.dataHubButton {
    border-radius: 2px;
    height: 31px;
    width: 94px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: none;
    outline: none;
    color: rgba(51, 51, 51, 0.7);
}

.dataHubButton:hover {
    background-color: #d1d1d1;
    color: #000;
}

.dataHubButton:active {
    background-color: #d1d1d1;
    color: #000 !important;
}

.dataHubButton:focus {
    background-color: var(--acceptBtn-color) !important;
    color: #ffffff !important;
}

.dataHubButtonSelected {
    background-color: var(--acceptBtn-color) !important;
    color: #ffffff !important;
}

.tableName {
    cursor: pointer;
    border-bottom: 1px solid lightgrey;
    padding: 6px 12px;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
}
.tableName:hover{
    background-color: #3B82C936;
    border-radius: 6px;
}
.tableList {
    background-color: rgb(255, 255, 255);
    border-radius: 6px;
    max-height: 345px;
    overflow: auto;
}
.previewContainer{
    border: 1px solid #d1d1d1;
    width: calc(100% - 25px);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-right: 20px;
    border-radius: 6px;
    min-height: 400px;
}
.hideOverflow{
    overflow: revert !important;
}