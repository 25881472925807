.container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.container > *:not(:first-child) {
	margin-left: 5px;
}

.dropdownMenu {
	top: 15px!important;
}

.dropdownMenuLeftOffset {
	left: -27px !important;
}

.row {
	display: flex;
	flex-direction: row;
}

.arrow {
	background-color: black;
	position: absolute;
	z-index: -1;
	transform: rotate(45deg);
	padding: 15px;
	top: 15;
	left: 0;
}

.spreadsheetOptionHoverEffect:hover {
	background-color: #3B82C9;
}

.spreadsheetOptionHoverEffect:hover input {
	background-color: #1B1B1B;
	border-color: #3F3F3F;
	color: #fff;
}

.spreadsheetDropdownItem {
	margin: 6px 0;
}

.menuItem {
	padding: 5px 15px;
}

.menuItemInfoIcon {
	display: flex;
	align-items: center;
}

.menuItemInfoIcon svg {
	margin: 0 0 0 8px;
	width: 20px;
}
