.root {
	position: relative;
}

.label {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	color: rgba(38, 46, 64, 0.7);
	position: absolute;
	top: -7px;
	left: 10px;
	z-index: 1;
	background: #fff;
}
