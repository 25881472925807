.rdw-text-align-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap
}
.rdw-text-align-dropdown {
  width: 50px;
  z-index: 90;
}
.rdw-text-align-dropdownOption {
  height: 40px;
  display: flex;
  justify-content: center;
}
.rdw-right-aligned-block {
  text-align: right;
}
.rdw-left-aligned-block {
  text-align: left !important;
}
.rdw-center-aligned-block {
  text-align: center !important;
}
.rdw-justify-aligned-block {
  text-align: justify !important;
}
