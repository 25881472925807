.rootButton {
	border: none;
	background: none;
	padding: 0;
}

.root {
	width: 300px;
	height: 200px;
	border-radius: 6px;
	/*
	border: 1px dashed #262E41;
	opacity: 0.5;
	*/
	background: rgba(255, 255, 255, 0.8);
	border: 1px solid #E9ECF0;
	box-sizing: border-box;
	backdrop-filter: blur(4px);
	filter: drop-shadow(0px 2px 8px rgba(38, 46, 64, 0.08));
	display: flex;
	justify-content: center;
	align-items: center;
}

.text {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	color: #13529B;
	margin: 0;
}

.text:hover {
	text-decoration-line: underline
}
