.dataContainer {
    display: grid;
    height: 300px;
    overflow: auto;
    width: 100%;
    grid-template-columns: 30% 10% 10% 10% 30% 10%;
}

.checkboxColumn {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.optionName {
    color: #333333b2;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
}

.mediumOptionName {
    padding-left: 10px;
    display: flex;
    align-items: center;
    font-weight: 500;
    height: 40px;
}

.dataContainer > div:nth-child(12n + 13),
.dataContainer > div:nth-child(12n + 14),
.dataContainer > div:nth-child(12n + 15),
.dataContainer > div:nth-child(12n + 16),
.dataContainer > div:nth-child(12n + 17),
.dataContainer > div:nth-child(12n + 18) {
    background: #f0f4f9;
}
.dataContainer > div:nth-child(12n + 1),
.dataContainer > div:nth-child(12n + 2),
.dataContainer > div:nth-child(12n + 3),
.dataContainer > div:nth-child(12n + 4),
.dataContainer > div:nth-child(12n + 5),
.dataContainer > div:nth-child(12n + 6),
.dataContainer > div:nth-last-child(6),
.dataContainer > div:nth-last-child(5),
.dataContainer > div:nth-last-child(4),
.dataContainer > div:nth-last-child(3),
.dataContainer > div:nth-last-child(2),
.dataContainer > div:last-child {
    box-shadow: 0px -1px 0px #d1d1d1, inset 0px -1px 0px #d1d1d1;
}

.buttonsContainer {
    display: flex;
    margin-top: 20px;
    margin-bottom: 15px;
}
