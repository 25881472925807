.root {
	margin: 10px 0 0 0;
	margin-bottom: 3rem;
}

.header {
	display: flex;
	align-items: center;
}

.columnHeader {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;
	padding: 1.2rem .5rem;
}

.title {
	/* flex: 1 1 40%; */
	width: 40%;
}

.owner {
	/* flex: 1 1 30%; */
	width: 30%;
}

.lastUpdatedAt {
	/* flex: 1 1 30%; */
	width: 30%;
}

.rows > div {
	padding: .8rem .5rem;
	border-top: 1px solid rgba(49, 54, 58, 0.1);
}

.skeleton {
	height: 40px;
	margin-top: .8rem;
}
