.arrow {
	position: relative;
	display: block;
	cursor: pointer;
	width: 21px;
	height: 21px;
}

.arrow:before, .arrow:after {
	position: absolute;
	display: block;
	content: '';
	width: 15px;
	height: 15px;
	left: 50%;
	top: 30%;
	border-width: 2px;
	border-style: solid;
	border-color: transparent;
	border-bottom-color: #4a4047;
	border-right-color: #4a4047;
	border-radius: 0 0 3px 0;
	transform: translate(-50%, -50%) rotate(45deg);
	transition: all .4s ease-in-out;
}

.arrow.inverted {
	transform: rotate(180deg);
}

.arrow.active:before :global {
	border-radius: 50%;
	animation: .8s rotateClockwise .4s linear forwards infinite;
}

.arrow.active:after :global {
	width: 22px;
	height: 22px;
	border-radius: 50%;
	animation: .8s rotateCounterClockwise .4s linear forwards infinite;
}

@keyframes :global(rotateClockwise) {
	to {
		transform: translate(-50%, -50%) rotate(405deg);
	}
}

@keyframes :global(rotateCounterClockwise) {
	to {
		transform: translate(-50%, -50%) rotate(-315deg);
	}
}
