.root {
	background: linear-gradient(90deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 94.48%), url('../../../icons/new-user-bg.png');
	background-repeat: no-repeat;
	background-position: 0 0, right;
	padding: 20px 0;
	height: 240px;
}

.innerContainer {
	max-width: 1400px;
	height: 100%;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

.buttonsContainer {
	display: flex;
}

.title {
	font-family: Roboto;
	font-style: normal;
	font-weight: 300;
	font-size: 24px;
	line-height: 28px;
	color: #262E40;
	margin: 0;
	margin-bottom: 24px;
}

.description {
	font-family: Roboto;
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 19px;
	color: #262E40;
	margin-bottom: 24px;
}

.createButton {
	margin-right: 20px;
}

.cardsContainer {
	display: flex;
	margin-left: 50px;
}

.cardsContainer > div {
	margin-right: 20px;
}

.cardsContainer > div:last-child {
	margin: 0;
}
