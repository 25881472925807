.btn-small-significance {
    background-color: var(--levers-background-color);
    color: #98a7b9;
    padding: 0.5rem 0.5rem;
    font-size: 1rem;
    border: none;
}
.btn-small-significance:hover,
.btn-small-significance:active,
.btn-small-significance:focus,
.btn-small-significance:active:focus {
    background-color: #008bf5;
    color: white;
    padding: 0.5rem 0.5rem;
    font-size: 1rem;
    border: none;
}

.btn-small-significance[disabled],
.btn-small-significance[disabled]:hover,
.btn-small-significance[disabled]:active,
.btn-small-significance[disabled]:focus,
.btn-small-significance[disabled]:active:focus {
    background-color: var(--selectors-background-hover-color);
    color: #98a7b9;
    padding: 0.5rem 0.5rem;
    font-size: 1rem;
    opacity: 1.0; /* not greyed out when disabled */
    cursor: default;
    box-shadow: none; /* no shadow when clicked */
    border: none;
}


.btn-small-significance-selected,
.btn-small-significance-selected:hover,
.btn-small-significance-selected:active,
.btn-small-significance-selected:focus,
.btn-small-significance-selected:active:focus {
    background-color: #39F;
    color: white;
    padding: 0.5rem 0.5rem;
    font-size: 1rem;
    border: none;
}

.btn-small-significance-selected[disabled],
.btn-small-significance-selected[disabled]:hover,
.btn-small-significance-selected[disabled]:active,
.btn-small-significance-selected[disabled]:focus,
.btn-small-significance-selected[disabled]:active:focus {
    background-color: #008bf5;
    color: white;
    padding: 0.5rem 0.5rem;
    font-size: 1rem;
    opacity: 1.0; /* not greyed out when disabled */
    cursor: default;
    box-shadow: none; /* no shadow when clicked */
    z-index: 0;
    border: none;
}

.btn-small-like-select {
    background-color: var(--selectors-background-color);
    color: var(--selectors-text-color);
    border-width: 0;
    padding: 0;
    font-size: 1rem;
}

.btn-small-like-select:hover,
.btn-small-like-select:active,
.btn-small-like-select:focus,
.btn-small-like-select:active:focus {
    background-color: var(--btn-blueBg);
    color: var(--selectors-text-color);
    border-width: 0;
    padding: 0;
    font-size: 1rem;
    box-shadow: none;
    outline: none;
}

.btn-small-like-select[disabled],
.btn-small-like-select[disabled]:hover,
.btn-small-like-select[disabled]:active,
.btn-small-like-select[disabled]:focus,
.btn-small-like-select[disabled]:active:focus {
    background-color: var(--selectors-background-hover-color);
    color: var(--selectors-text-color);
    border-width: 0;
    padding: 0;
    font-size: 1rem;
    opacity: 1.0; /* not greyed out when disabled */
    cursor: default;
    box-shadow: none; /* no shadow when clicked */
}

.btn-small-questionnaire,
.btn-small-questionnaire:hover,
.btn-small-questionnaire:active,
.btn-small-questionnaire:focus,
.btn-small-questionnaire:active:focus,
.btn-small-questionnaire[disabled],
.btn-small-questionnaire[disabled]:hover,
.btn-small-questionnaire[disabled]:active,
.btn-small-questionnaire[disabled]:focus,
.btn-small-questionnaire[disabled]:active:focus {
    background-color: transparent;
    color: #39F;
    border-color: #39F;
    border-width: 2px;
    padding: 0;
    font-size: 1rem;
}

.btn-small-questionnaire:hover,
.btn-small-questionnaire:active,
.btn-small-questionnaire:focus,
.btn-small-questionnaire:active:focus {
    background-color: hsla(0, 0%, 50%, 0.2);
}

.btn-small-questionnaire[disabled],
.btn-small-questionnaire[disabled]:hover,
.btn-small-questionnaire[disabled]:active,
.btn-small-questionnaire[disabled]:focus,
.btn-small-questionnaire[disabled]:active:focus {
    opacity: 1.0; /* not greyed out when disabled */
    cursor: default;
    box-shadow: none; /* no shadow when clicked */
}

.btn-processing-tab,
.btn-processing-tab:focus,
.btn-processing-tab:active:focus,
.btn-processing-tab:hover,
.btn-processing-tab:active,
.btn-processing-tab[disabled],
.btn-processing-tab[disabled]:focus,
.btn-processing-tab[disabled]:active:focus,
.btn-processing-tab[disabled]:hover,
.btn-processing-tab[disabled]:active {
    background-color: #3f4b62;
    color: white;
    padding: 0;
    font-family: Roboto;
    display: flex;
    align-items: center;
    border-radius: 0;
    border: 0;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
}

.btn-processing-tab:hover,
.btn-processing-tab:active {
    background-color: #4f5b72;
}

.btn-processing-tab-blue,
.btn-processing-tab-blue:focus,
.btn-processing-tab-blue:active:focus {
    background-color: #39f;
    color: white;
    padding: 0;
    font-family: Roboto;
    display: flex;
    align-items: center;
    border-radius: 0;
    border: 0;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
}

.btn-processing-tab-blue:hover,
.btn-processing-tab-blue:active {
    background-color: #00b3db;
    color: white;
    padding: 0;
    font-family: Roboto;
    display: flex;
    align-items: center;
    border-radius: 0;
    border: 0;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
}

.show-on-hover {
    opacity: 0;
}

.show-on-hover:hover {
    opacity: 1;
}
