.container {
    background: #ffffff;
    border: 1px solid #e8e8e8;
    width: 363px;
    position: absolute;
    left: 0px;
    top: 60px;
    z-index: 500;
    height: calc(100% - 60px);
}

.mainTitle {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
    color: #424242;
    padding-left: 8px;
}

.slidesContainer {
    overflow: hidden auto;
    height: calc(100% - 80px);
    padding-bottom: 10px;
}

.themesContainer {
    display: flex;
    flex-direction: column;
    overflow: hidden auto;
    height: calc(100% - 81px);
    padding-bottom: 10px;
}

.title:focus {
    border: 1px solid #dddddd;
}

.slide {
    border: 1px solid #d3d3d3;
    box-shadow: 0px 2px 7px rgba(4, 11, 20, 0.12);
    border-radius: 3px;
}

.header {
    background-color: #F9F9F9;
    border-bottom: 1px solid #E3E3E3;
    padding: 10px 0 0 0;
}

.newTemplateButton {
    border: none;
    outline: none;
    background: #3b82c9;
    border-radius: 6px;
    width: 110px;
    height: 40px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
}

.newTemplateButton:hover {
    background: #11508f;
}

.newTemplateButton:active {
    background: #639cd4;
}

.newTemplateButton:disabled {
    opacity: 0.5;
}

.sectionContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #f9f9f9;
    border-radius: 0px 0px 0px 10px;
    height: 81px;
    width: calc(100% / 3);
    cursor: pointer;
}

.sectionContainerBothRadius {
    border-radius: 0px 0px 10px 10px;
}

.sectionContainerRightRadius {
    border-radius: 0px 0px 10px 0px;
}

.sectionContainerActive {
    background: #f0f0f0;
    border-radius: 0px 0px 10px 10px;
}

.sectionTitle {
    margin-top: 5px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #424242;
}

.collectionTitle {
    margin-top: 10px;
    margin-left: 17px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #424242;
}


.fileContainer {
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.fileContainer:hover {
    transform: scale(1.1);
}

.collectionContainer {
    margin-top: 10px;
    margin-left: 17px;
    display: flex;
    flex-wrap: wrap;
}

.collection:hover {
    background-color: #ebf2f9;
}

.themeCollectionTitle {
    margin: 29px 0 5px 28px;
    font-family: "Roboto";
    font-style: normal;
    width: 248px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.7);
    outline: none;
    border: none;
    font-size: 26px;
    font-weight: 400;
    line-height: 30px;
}

.themeCollectionTitle:focus {
    border: 1px solid #dddddd;
}

.themeHoverElement {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    font-family: "Roboto";
    font-weight: 600;
    line-height: 23px;
}

.themeHoverElement span {
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.19) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.themeTitle {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    height: 30%;
    align-items: center;
    padding: 0 0 0 10px;
    font-family: "Roboto";
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
}

.title {
    font-size: 18px;
    font-weight: 500;
    font-family: "Roboto";
    margin: 0 0 0 17px;
}

.themeFog {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70%;
    background: linear-gradient(0deg, rgba(255,255,255,1) 3%, rgba(0,0,0,0) 50%);
}

.themeCollectionUncollapser:hover,
.themeCollectionUncollapser:active:focus,
.themeCollectionUncollapser:focus {
    background-color: transparent;
}

.dashboardsContainer {
    padding: 12px;
    position: relative;
}