:root {
    --guidance-wizard-header-height: 85px;
}

.icon > path {
    fill: white;
}

.header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--guidance-wizard-header-height);
    background-color: #ffffff;
}

.previewModeTitle {
    font-size: 20px;
    font-family: Roboto;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
}

.homeOption {
    position: absolute;
    left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
}

.optionWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
}

.option {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    cursor: pointer;
    border-bottom: 2px solid #809ab2;
    color: #809ab2;
    font-size: 20px;
    min-width: 110px;
}

.buttonContainer {
    position: absolute;
    right: 60px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.previewButton {
    width: 110px;
    height: 35px;
    border-radius: 6px !important;
    border: 1px solid #dddddd !important;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 19px !important;
    letter-spacing: 0em !important;
    text-align: center;
    background-color: white !important;
    color: #245180 !important;
}

.previewButton:hover {
    background-color: #edf3fa !important;
    color: #245180 !important;
    border: 1px solid #dddddd !important;
}

.previewButton:focus:active {
    outline: none;
}

.previewButton:active {
    background-color: #edf3fa !important;
    border: 1px solid #dddddd !important;
    box-shadow: 0px 0px 5px 0px #4aa5fc80 !important;
}

.nextButton {
    width: 110px;
    height: 35px;
    border-radius: 6px !important;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 19px !important;
    letter-spacing: 0em !important;
    text-align: center;
    background-color: #3474b2 !important;
    color: white !important;
}

.nextButton:hover {
    background-color: #3c84cc !important;
    color: white !important;
    border: 1px solid transparent !important;
}

.nextButton:focus:active {
    outline: none;
}

.nextButton:active {
    background-color: #3c84cc !important;
    box-shadow: 0px 0px 5px 0px #4aa5fc80 !important;
    border: 1px solid transparent !important;
}

.active {
    color: #3b82c9;
    border-bottom: 2px solid #3b82c9;
    font-weight: 700;
}

@media (max-width: 1100px) {
    .homeOption {
        display: none;
    }
}
