.addColorButton {
	background-color: transparent !important;
	border: none;
	color: #326eaa !important;
	font-weight: 700;
	font-size: 14px;
	width: 24px;
    display: flex;
	align-items: center;
    justify-content: center;
    height: 24px;
	cursor: pointer;
}

.deleteColorButton {
	color: #989898 !important;
}
