.createButton {
	background: linear-gradient(183.16deg, rgba(44, 116, 200, 0.8) 5.24%, #2C74C8 94.76%), #FFFFFF;
	border: 1px solid rgba(4, 30, 55, 0.1);
	box-shadow: 0px 1px 2px rgba(49, 54, 58, 0.04);
	border-radius: 4px;
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 1.2rem;
	line-height: 14px;
	letter-spacing: 0.12em;
	text-transform: uppercase;
	color: #fff;
	padding: .7rem 4rem;
}

.createButton:hover {
	box-shadow: 2px 2px 6px 6px rgba(49, 54, 58, 0.04);
}

.createButton:focus {
	outline: none;
}

.searchButton {
	border: none;
	background-color: transparent;
	display: flex;
    align-items: center;
}

.searchContainer {
	display: flex;
	border: 1px solid #DBDBDB;
	width: 262px;
	height: 42px;
	border-radius: 42px;
	background-color: #fff;
	padding: 0 0 0 20px;
}

.searchInput {
	border: none;
	width: 100%;
	height: 100%;
	background-color: transparent;
	text-align: left;
}

.searchInput:focus {
	outline: none;
}

@media (max-width: 784px) {
	.content {
		padding: 0 8px;
		justify-content: flex-end;
	}
}