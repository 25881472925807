.root {
	margin: 10px 0;
	display: grid;
	grid-template-columns: repeat(auto-fill, 300px);
	gap: 20px;
}

@media (max-width: 620px) {
	.root {
		justify-content: center;
	}
}
