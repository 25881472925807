.tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style: none;
    border-bottom: 1px solid #e8e8e8;
    padding: 0;
}

.tab {
    padding: 20px 50px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    color: #75869a;
}

.tabSelected {
    background-color: #e8e8e8;
}