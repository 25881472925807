.root {
	background-color: black;
	border-radius: 6px;
	border: none;
	margin-top: 5px!important;
}

.arrow {
	background-color: black;
	position: absolute;
	z-index: -1;
	transform: rotate(45deg);
	padding: 15px;
	top: 0;
}
