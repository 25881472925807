.container {
	display: flex;
	margin-left: 10px;
}
.linkButton {
	background-color: transparent;
	cursor: pointer;
	min-width: 20px;
	min-height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
}

.linkButton:hover {
	background-color: #cccccc;
}

.linkButton:active {
	background-color: #aaaaaa;
}

.caret {
	color: #ffffff;
}