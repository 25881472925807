.dataHubTitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;
    color: rgba(51, 51, 51, 0.7);
}

.dataHubButtonsContainer {
    background: #ffffff;
    border: 1px solid #d1d1d1;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-radius: 4px;
    height: 35px;
    padding-left: 1px;
    padding-right: 1px;
    width: fit-content;
}

.dataHubButton {
    border-radius: 2px;
    height: 31px;
    width: 94px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: none;
    outline: none;
    color: rgba(51, 51, 51, 0.7);
}

.dataHubButton:hover {
    background-color: #d1d1d1;
    color: #000;
}

.dataHubButton:active {
    background-color: #d1d1d1;
    color: #000 !important;
}

.dataHubButtonSelected {
    background-color: #75869a !important;
    color: #ffffff !important;
}
