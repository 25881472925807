.title {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */

    color: #000000;
}

.description {
    margin-left: 5px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
}

.infoRow {
    display: flex;
    margin-top: 20px;
}

.summaryContainer {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.range {
    margin-top: 10px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 200;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
}

.count {
    margin-top: 10px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
}

.container {
    padding: 12px 10px 12px 10px;
    min-width: 250px;
    z-index: 5000;
    min-height: 200px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    position: absolute;
}
