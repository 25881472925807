.root {
	width: 100%;
}

.item {
	display: flex;
	align-items: center;
	border-radius: 4px;
	width: 100%;
	font-size: 16px;
}

.menu {
	padding: 0;
	width: 100%;
}

.listItem {
	cursor: pointer;
	padding: 10px;
	font-size: 14px;
}

.listItemSelected {
	background: #ebf2f9;
}

.listItem:hover {
	background: #f6f6f6;
}

.text {
	margin-left: 8px;
	color: #333333;
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
}

.iconContainer {
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
}