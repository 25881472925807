.root {
    height: 60px;
    background: linear-gradient(90.2deg, #323232 50.28%, rgba(50, 50, 50, 0.9) 100%);
    background-color: white;
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999999999;
}
.logoNavButton {
	height: 100%;
	display: flex;
	align-items: center;
	padding: 0 10px;
	border-right: 1px solid #535353;
}

.logoNavButton:hover {
	border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.logoNavButton:focus {
	border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.logoNavButton:active {
	border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.logoNavButton:active:focus {
	border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.logo {
	margin-right: 0.5rem;
}

.title {
	font-family: Roboto;
	font-style: normal;
	font-weight: 300;
	font-size: 15px;
	line-height: 18px;
	letter-spacing: 12px;
	color: #E9ECF0;
	margin: 0;
	margin-left: 2rem;
}

.canvasTitle {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	height: 100%;
	font-size: 16px;
	line-height: 18.75px;
	color: #e9e9e9;
	margin: 0;
	padding: 8px;
}

.zoomTitle {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	height: 100%;
	font-size: 14px;
	line-height: 16px;
	color: #e9e9e9;
}
.titleContainer {
	flex: 1 1 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.canvasBarContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
	margin-left: 10px;
}

.rightSide {
	display: flex;
	align-items: center;
}

.notificationButton {
	border: none;
	background: none;
}

.notificationButton:focus {
	outline: none;
}

.notificationDropdown {
	padding: 10px 0 40px;
	top: 10px!important;
}

.avatarButton {
	padding: 0 25px 0 0;
}

.avatarChevron {
	margin-left: 1.5rem;
}

.navChevron {
	transform: rotate(270deg);
	margin-left: 15px;
}

.avatarMenu {
	top: 17px!important;
}

.mainDropdownMenu {
	top: 3px!important;
}

.labelStyle {
	width: 100%;
	margin: 0;
	padding: 3px 20px 3px 20px;
	cursor: pointer;
	color: #626262;
}

.shareButton {
	background: #3E3E3E;
	border: 1px solid #535353;
	border-radius: 7;
	margin: 0 13px 0 0;
	transition: background-color 0.3s linear;
}

.shareButton:hover {
	background: #606060;
	border: 1px solid #535353;
	transition: background-color 0.3s linear;
}

@media (max-width: 450px) {
	.title {
		display: none;
	}
	.titleContainer {
		justify-content: flex-end;
	}
}
