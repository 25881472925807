.fullScreenContainer {
    /* 60px is the navigation bar height */
    height: calc(100vh - var(--guidance-wizard-header-height) - 60px);
    max-height: calc(100vh - var(--guidance-wizard-header-height) - 60px);
    display: flex;
    flex-direction: column;
    border-top: 1px solid #e8e8e8;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.container {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.previewContainer {
    flex: 1;
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    background-color: white;
}

.leftPanel {
    width: 290px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: white;
}

.leftPanelOptionTitle {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
}

.leftPanelOptionInput {
    border: 1px solid black;
    border-radius: 5px;
    outline: none;
    width: 100%;
    text-overflow: ellipsis;
    padding: 5px 10px;
}

.monitorContainer {
    background-color: transparent;
    padding: 40px 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.monitor {
    border: 4px solid black;
    border-radius: 20px;
    flex: 1;
    width: 100%;
    padding: 15px 40px;
    background-color: white;
}

.monitorStand {
    border-bottom: 50px solid black;
    border-left: 80px solid transparent;
    border-right: 80px solid transparent;
    height: 0;
    width: 300px;
}

.monitorInnerContainer {
    width: 100%;
    height: 100%;
    padding-top: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4), 0 4px 8px 0 rgba(0, 0, 0, 0.3);
}

.logoCenterContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logoContainer {
    position: relative;
    width: 162px;
    height: 93px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    border-radius: 5px;
}

.logoCrossContainer {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
