.root {
    padding: 20px;
}

.selectLabel {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    display: block;
    margin-bottom: 10px;
}
