.editText {
  font-family: Roboto;
  font-size: 14px;
  cursor: pointer;
}

.editMenuHeader {
  padding: 10px;
  background: #ffffff;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.editMenuHeaderTitle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  margin: 0;
}

.editMenuCloseButton {
  border: none;
  background: none;
}

.editMenuBody {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
}

.dragArea {
  position: fixed;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none;
}

.draggable {
  pointer-events: all;
}

.tablePreviewContainer {
  width: 100%;
  height: 100%;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 7px 31px -15px rgba(0, 0, 0, 0.75);
}

.tablePreviewContainerMinimized {
  pointer-events: all;
  width: 200px;
  height: 30px;
  position: absolute;
  bottom: 15px;
  left: 10px;
}

.tablePreviewHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  height: 30px;
  width: 100%;
}
