.customTable {
    padding: 0 20px;
    width: 100%;
    margin: 0;
}

.grid {
    display: flex;
    flex-direction: column;
    margin-inline: auto;
}

.headerRow {
    width: 100%;
    display: flex;
    padding-left: 20px;
    background-color: #ebf3fa;
    height: 40px;
    align-items: center;
    border-bottom: 1px solid #e1e6ef;
}

.gridRow {
    width: 100%;
    display: flex;
    padding-left: 20px;
    border-bottom: 1px solid #e1e6ef;
    align-items: center;
}

.gridRowIfDataEmpty {
    width: 100%;
    display: flex;
    padding-left: 20px;
    align-items: center;
}

.gridTitle {
    margin-block: 5px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #555;
}

.gridItem {
    color: #555555;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding-block: 10px;
    display: flex;
    align-items: center;
}

.sortButton {
    padding-left: 5vw;
    background-color: transparent;
    border: none;
    margin: 0;
    line-height: 1;
    font-size: 15px;
    color: black;
    cursor: pointer;
    transition: transform 0.05s ease-out;
}

.sortReverse {
    transform: rotate(180deg);
}

.sortInactive {
    opacity: 0.5;
}

.text {
    margin: 0;
}
