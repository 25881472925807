.leaflet-heatmap-layer {
  /* It should be above the SVG overlay */
  z-index: 201 !important;
  pointer-events: none;
}

#highlighted-tile {
  filter: brightness(1.2);
}

.map-flag-tooltip-text {
  display: flex;
  font-size: 10px;
  line-height: 13px;
  font-weight: 500;
  font-family: "Roboto";
  width: 100%;
  height: 22px;
  text-align: center;
  align-items: center;
  justify-content: center;
  overflow-wrap: anywhere;
  white-space: pre-line;
}

