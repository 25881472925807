.root {
	border-bottom: 1px solid #E9E9E9;
}

.editMenuAdvancedCollapseButton {
	border: none;
	background: none;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #326EAA;
	padding: 0;
	padding-bottom: 10px;
	margin-top: 10px;
	display: flex;
	align-items: center;
}

.editMenuAdvancedCollapseButtonIcon {
	width: 14px;
	height: 6px;
	transform: rotate(90deg);
	transition: all .2s ease-out;
}

.editMenuAdvancedCollapseButtonIcon > path {
	fill: #326EAA;
}

.advancedMenu {
  margin-bottom: 10px;
}

.chevronOpen {
	transform: rotate(0deg);
}
