.editMenuHeader {
	position: relative;
	padding: 10px;
	text-align: center;
	background: rgba(101, 125, 149, 0.05);
}

.editMenuHeaderTitle {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	color: rgba(38, 46, 64, 0.7);
	margin: 0;
}

.editMenuCloseButton {
	position: absolute;
	top: 10px;
	right: 10px;
	border: none;
	background: none;
}

.editArea {
	padding: 15px;
	padding-top: 0;
}

.editMenuBody {
	padding-top: 20px;
}

.editMenuAdvancedTitle {
	display: flex;
	justify-content: flex-end;
}

.editMenuAdvancedCollapseButton {
	border: none;
	background: none;
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: rgba(39, 91, 170, 0.9);
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	text-align: right;
	padding-bottom: 10px;
	margin-top: 20px;
}

.editMenuAdvancedCollapseButtonIcon {
	width: 16px;
	height: 8px;
	transform: rotate(90deg);
	transition: all .2s ease-out;
}

.editMenuAdvancedCollapseButtonIcon > path {
	fill: rgba(39, 91, 170, 0.9);
}

.editMenuAdvancedOption {
	margin-top: 10px;
	padding-top: 10px;
	border: none;
	border-top: 1px solid #E9E9E9;
	background: none;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 14px;
	color: #242124;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.editMenuAdvancedOptionIcon {
	width: 12px;
	height: 6px;
	transform: rotate(90deg);
	transition: all .2s ease-out;
}

.editMenuAdvancedOptionIcon > path {
	fill: #242124;
}

.chevronOpen {
	transform: rotate(0deg);
}

.geoJsonButton {
	margin-top: 10px;
	padding: 5px 8px;
	font-size: 14px;
}

.tabs {
	display: flex;
	border: none;
}

.tabs > a {
	flex: 1 1 auto;
	text-align: center;
}

.tabTitle {
	justify-content: center;
}

.tab {
	opacity: 1;
}
