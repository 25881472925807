.menu {
    animation: fade--in .2s ease-in-out;
}

@keyframes fade--in {

    0% {
        opacity: 0;
        transform: translateY(-5rem);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}