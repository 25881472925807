.App {
    text-align: center;
}

.wrapper {
    background: var(--main-background-color);
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

input,
textarea {
    caret-color: auto;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.content {
    padding-left: 35px;
}

.main-wrapper {
    height: 100vh;
    height: var(--vh);
    background-color: var(--content-background-color);
    overscroll-behavior-y: none;
}

.content-wrapper {
    height: 100vh;
    overflow-y: scroll;
    /* background-color: var(--content-background-color); */
    padding-top: 60px !important;
    margin-left: 0px !important;
    background: var(--greyColor);
}

.no-top-padding {
    padding-top: 0px !important;
}

.content-header>h3 {
    color: var(--primary-text-color);
    font-family: "Roboto";
    font-size: var(--page-title-size);
    font-weight: 300;
    line-height: 36px;
}

.shape-canvas-focus {
    border: none;
    outline: none;
}

.shape-canvas-focus:focus {
    border: none !important;
}

.dashboard-rect {
    border-radius: 2px;
    background-color: var(--content-background-color);
    box-shadow: 0 6px 13px 0 rgba(21, 33, 56, 0.53);
}

.dashboard-rect-card {
    border-radius: 2px;
    background-color: var(--primary-content-color);
    box-shadow: 0 6px 13px 0 rgba(21, 33, 56, 0.53);
}

.dashboard-rect-canvas {
    border-radius: 2px;
    background-color: var(--slide-data-processing-content-color);
    /* box-shadow: 0 6px 13px 0 rgba(21, 33, 56, 0.53); */
}

.dashboard-rect-spreadsheet {
    border-radius: 5px;
    background-color: var(--slide-spreadsheet-content-color);
}

.dashboard-rect-canvas-focus {
    border-radius: 5px;
    border: 1px solid var(--secondary-content-color);
    outline: none;
}

.dashboard-rect-canvas-focus:focus {
    border-width: 2px !important;
}

.flex-simple-columns {
    padding: 0;
}

.dashboard-rect-insights {
    border-radius: 10px;
    background-color: var(--primary-content-color);
    box-shadow: 0 6px 13px 0 rgba(21, 33, 56, 0.53);
}

.dashboard-rect-journey {
    border-radius: 5px;
    background-color: var(--exploration-content-color);
    box-shadow: 0 6px 13px 0 rgba(21, 33, 56, 0.53);
}

.dashboard-rect-journey-focus {
    border-radius: 5px;
    background-color: var(--exploration-content-color);
    box-shadow: 0 6px 13px 0 rgba(21, 33, 56, 0.53);
    border: 1px solid var(--exploration-border-color);
    outline: none;
    margin-top: 10px;
    margin-bottom: 10px;
}

.dashboard-rect-journey-focus:focus {
    border-radius: 5px;
    background-color: var(--exploration-content-color);
    box-shadow: 0 6px 13px 0 rgba(21, 33, 56, 0.53);
    border: 2px solid var(--exploration-border-color);
    margin-top: 10px;
    margin-bottom: 10px;
}

.journey-variable {
    box-sizing: border-box;
    border-radius: 5px;
    background-color: var(--selectors-background-color);
    border: 1px solid #fff;
}

.journey-variable-selected {
    box-sizing: border-box;
    border-radius: 5px;
    background-color: var(--selectors-background-color);
    border: 1px solid var(--secondary-content-color);
}

.questionnaire-variable {
    box-sizing: border-box;
    border-radius: 5px;
    background-color: var(--slide-data-processing-option-backgound-color);
    border: 2px solid var(--slide-data-processing-secondary-text-color);
}

.questionnaire-variable-selected {
    box-sizing: border-box;
    border-radius: 5px;
    background-color: var(--slide-data-processing-option-backgound-color);
    border: 2px solid var(--slide-data-processing-secondary-text-color);
}

.questionnaire-small-variable {
    box-sizing: border-box;
    border-radius: 5px;
    background-color: var(--slide-data-processing-option-backgound-color);
    border: 2px solid var(--slide-data-processing-secondary-text-color);
}

.questionnaire-small-variable-selected {
    box-sizing: border-box;
    border-radius: 5px;
    background-color: var(--slide-data-processing-option-backgound-color);
    border: none;
}

.journey-variable-selected-freezed {
    box-sizing: border-box;
    border-radius: 5px;
    background-color: var(--selectors-background-color);
    border: none;
    opacity: 0.5;
}

.journey-small-variable {
    box-sizing: border-box;
    border-radius: 5px;
    background-color: var(--selectors-background-color);
    border: 1px solid #fff;
}

.journey-small-variable-selected {
    box-sizing: border-box;
    border-radius: 5px;
    background-color: var(--secondary-content-color);
    border: none;
}

.dashboard-popup {
    background-color: var(--primary-content-color);
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.5);
}

.dashboard-rect .dashboard-title {
    margin-left: 43px;
    margin-top: 23px;
    height: 12.06px;
    color: #cdd3dd;
    font-family: Arial;
    font-size: 17px;
    line-height: 20px;
}

.dashboard-rect .data-title {
    margin-left: 19px;
    margin-top: 16px;
    color: #cdd3dd;
    font-family: Arial;
    font-size: 17px;
    line-height: 20px;
}

.dashboard-rect .data-title small {
    display: block;
    font-size: 10px;
    color: #98a7b9;
    font-family: Arial;
    font-size: 12px;
    margin-top: 10px;
    margin-left: 23px;
}

.my-row {
    display: flex;
}

.my-column {
    flex: 50%;
}

.my-outline {
    background: #FFF !important;
    border: 1px solid #e8e8e8 !important;
}

.my-outline:hover {
    background: #EDF3FA !important;
    color: var(--saveBtn-color);
    border: 1px solid #e0e0e0;
}

.my-outline:focus:active {
    outline: none;
}

.my-outline:active {
    background: #EDF3FA !important;
    border: 1px solid #e0e0e0 !important;
    box-shadow: 0px 0px 5px 0px #4AA5FC80 !important;
}

.my-primary,
.my-primary:focus {
    color: white !important;
    border: 1px solid transparent;
    background: var(--saveBtn-color) !important;
}

.my-primary:hover {
    background: var(--saveBtn-hoverColor) !important;
    color: #fff;
    border: 1px solid transparent;
}

.my-primary:focus:active {
    outline: none;
}

.my-primary:active {
    background: var(--saveBtn-hoverColor);
    box-shadow: none;
    box-shadow: 0px 0px 5px 0px #4AA5FC80;
    border: 1px solid transparent !important;
}

.my-circle,
.my-circle:focus {
    border-radius: 50%;
    color: white;
    background-color: var(--secondary-content-color);
}

.my-circle:hover {
    border-radius: 50%;
    color: white;
    background-color: #00b3db;
}

.flex-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.flex-simple-column {
    display: flex;
    flex-direction: column;
}

.my-text-area {
    border: none;
    height: 128px;
    margin-left: 59px;
    margin-right: 19px;
    border-radius: 5px;
    background-color: var(--tertiary-content-color);
    font-family: "Roboto";
    font-size: 12px;
    color: #1f8efa;
    padding: 10;
}

.code-text-area {
    border: none;
    height: 50px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 5px;
    background-color: var(--tertiary-content-color);
    font-family: "Roboto";
    font-size: 14px;
    color: #1f8efa;
    padding: 10;
}

.input-range {
    border: none;
    background-color: var(--selectors-background-color);
    font-family: "Roboto";
    font-size: 14px;
    color: var(--selectors-text-color);
}

.insights-text-area {
    border: 1px solid transparent;
    outline: none;
    resize: none;
    height: 50px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 5px;
    background-color: transparent;
    font-family: "Roboto";
    font-weight: 300;
    color: var(--content-primary-text-color);
    padding: 10;
}

.insights-text-area:focus {
    border: 1px solid var(--secondary-content-color);
}

.insights-text-area-popup {
    border: 1px solid #d1d1d1;
    outline: none;
    resize: none;
    height: 50px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 5px;
    background-color: transparent;
    font-family: "Roboto";
    font-weight: 300;
    color: var(--popup-primary-text-color);
    padding: 10;
}

.insights-text-area-popup:focus {
    border: 1px solid var(--secondary-content-color);
}

.my-text-area::placeholder,
::placeholder {
    color: #869aac !important;
}

.element::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
.element::-webkit-scrollbar-track {
    background: var(--tertiary-content-color);
    border-radius: 5px;
}

/* Handle */
.element::-webkit-scrollbar-thumb {
    background: #1f8efa;
    border-radius: 5px;
}

/* Handle on hover */
.element::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.element::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
}

.element {
    scrollbar-color: #1f8efa var(--tertiary-content-color);
    scrollbar-width: auto;
}

.dock-content {
    background-color: var(--tertiary-content-color);
    box-shadow: 0 6px 13px 0 rgba(21, 33, 56, 0.53);
    border-top: solid var(--primary-content-color) 3px;
}

.flex-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 1em;
    grid-column-gap: 1em;
}

.flex-grid-one-column {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 1em;
    grid-column-gap: 1em;
}

.center-container {
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.zoom-button {
    width: 30px;
    border: 1px solid var(--content-background-color);
    background-color: transparent;
    outline: none;
    color: var(--primary-text-color);
}

.zoom-button:hover {
    border: 1px solid #a0d1dc;
}

.clear-notebook-button,
.clear-notebook-button:focus {
    background-color: #3e4e6c;
    border: none;
    border-radius: 40px;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    outline: 0;
}

.clear-notebook-button:hover {
    background-color: #4e5e7c;
    border: none;
    border-radius: 40px;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
}

.clear-notebook-button:active {
    background-color: #3e4e6c;
    border: none;
    border-radius: 40px;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
}

.legend-table th {
    color: var(--secondary-text-color);
    font-family: Roboto;
    font-size: 12px;
}

.legend-table td {
    color: var(--primary-text-color);
    font-family: Roboto;
    font-size: 10px;
}

.legend-table thead>tr>th {
    border: none;
    text-align: left;
}

.legend-table tbody>tr>td {
    border: none;
    text-align: left;
}

.variables-grid {
    display: grid;
    grid-template-columns: 110px 110px 110px;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.insightswizard-left-enter {
    transform: translate(100%);
}

.insightswizard-left-enter-active {
    transform: translate(0%);
    transition: transform 500ms ease-in-out;
}

.insightswizard-left-exit {
    transform: translate(-100%);
}

.insightswizard-left-exit-active {
    transform: translate(-100%);
    transition: transform 500ms ease-in-out;
}

.insightswizard-right-enter {
    transform: translate(-100%);
}

.insightswizard-right-enter-active {
    transform: translate(0%);
    transition: transform 500ms ease-in-out;
}

.insightswizard-right-exit {
    transform: translate(-100%);
}

.insightswizard-right-exit-active {
    transform: translate(-100%);
    transition: transform 500ms ease-in-out;
}

.collapse-expand-div:hover {
    background-color: var(--sections-hover-color);
}

.collapse-expand-div {
    background-color: var(--sections-background-color);
}

.journeywizard-top-enter {
    transform: translate(0, 100%);
}

.journeywizard-top-enter-active {
    transform: translate(0, 20%);
    transition: transform 500ms ease-in;
}

.journeywizard-top-exit {
    transform: translate(0%, 100%);
}

.journeywizard-top-exit-active {
    transform: translate(0%, 200%);
    transition: transform 500ms ease-in;
}

.journeywizard-bottom-enter {
    transform: translate(0, -100%);
}

.journeywizard-bottom-enter-active {
    transform: translate(0, 20%);
    transition: transform 500ms ease-in;
}

.journeywizard-bottom-exit {
    transform: translate(0%, 100%);
}

.journeywizard-bottom-exit-active {
    transform: translate(0%, 200%);
    transition: transform 500ms ease-in;
}

.share-fade-enter {
    opacity: 0;
}

.share-fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.share-fade-exit {
    opacity: 1;
}

.share-fade-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
}

.journey-column-enter {
    opacity: 0.01;
}

.journey-column-enter-active {
    opacity: 1;
    transition: 700ms;
}

.journey-column-exit {
    opacity: 1;
}

.journey-column-exit-active {
    opacity: 0.01;
    transition: 700ms;
}

.scaled-animated-column-enter {
    transform: scaleX(0) translate(0%);
}

.scaled-animated-column-enter-active {
    transform: scaleX(1) translate(0%);
    transition: transform 500ms ease-in-out;
}

.scaled-animated-column-exit {
    transform: scaleX(1) translate(0%);
}

.scaled-animated-column-exit-active {
    transform: scaleX(0) translate(0%);
    transition: transform 500ms ease-in-out;
}

.big-title-span {
    font-family: Roboto;
    font-size: var(--primary-path-title-size);
    color: var(--primary-text-color);
}

.exploration-big-title-span {
    font-family: Roboto;
    font-size: var(--primary-path-title-size);
    color: var(--exploration-primary-text-color);
}

.sections-title-span {
    color: var(--sections-title-color);
    font-family: Roboto;
    font-size: var(--sections-title-size);
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow-wrap: break-word;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

.hide-scroll::-webkit-scrollbar {
    width: 0 !important;
    display: none;
}

.hide-scroll::-webkit-scrollbar-track {
    width: 0 !important;
}

.hide-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

@media (hover: none) {
    @media screen and (orientation: landscape) and (max-device-height: 850px) {
        .landscape-warning {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: var(--primary-content-color);
            z-index: 999999;
            align-items: center;
            display: flex !important;
            padding: 10px;
            justify-content: center;
        }

        .popup-content {
            margin: 0 !important;
            width: 100% !important;
            height: 100% !important;
        }

        .scrollable {
            height: 100%;
        }

        .card-content-landscape-hidden {
            display: none !important;
            width: 100% !important;
            height: 100% !important;
            margin: 0 !important;
        }
    }
}

.combined-shape {
    border-radius: 50%;
    box-sizing: border-box;
    height: 66px;
    width: 66px;
    border: 2px dashed #70889e;
}

.canvas-switch {
    color: #fff;
    font-family: Arial;
    font-size: 6px;
    letter-spacing: 1.33px;
    line-height: 7px;
}

.canvas-menu {
    width: 60px;
    background-color: white;
    box-shadow: 0px 10px 38px -19px rgba(0, 0, 0, 0.45);
    border-radius: 6px;
    border: 1px solid #eaeaea;
    overflow: hidden;
}

.canvas-menu button {
    width: calc(100% - 4px);
    background-color: white;
    color: black;
    font-size: 12px;
    border: none;
    margin: 2px;
    border-radius: 6px;
    padding: 5px 5px;
}

.canvas-menu button:hover {
    background-color: lightgray;
    font-weight: 500;
}

.canvas-menu label {
    width: 100%;
    background-color: white;
    color: black;
    font-size: 12px;
    border: none;
    margin: 0;
    padding: 10px;
    font-weight: normal;
    cursor: pointer;
    text-align: center;
}

.canvas-menu label:hover {
    background-color: lightgray;
}

.canvas-root {
    outline: none;
}

[id="canvas-root-element"]::selection {
    background: transparent;
    /* Safari */
}

[id="canvas-root-element"]::-moz-selection {
    background: transparent;
    /* Firefox */
}

canvas::selection {
    background: transparent;
    /* Safari */
}

canvas::-moz-selection {
    background: transparent;
    /* Firefox */
}

.element-leaflet-map {
    height: 100%;
}

.hover-div:hover {
    background-color: #ccc;
}

.selection-area {
    position: relative;
    background: rgba(46, 115, 252, 0.11);
    border: 2px solid rgba(98, 155, 255, 0.81);
    border-radius: 0.1em;
}

.transform-none {
    -webkit-tranfrom: none;
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    transform: none;
}

.width-100 {
    width: 100%;
}

.height-100 {
    height: 100%;
}

/* For share Popup Starts */

.sharepopup .my-row {
    display: none;
}

.sharepopup .MessagePopup_header__353jb {
    font-size: 20px;
    justify-content: left !important;
    padding: 15px 20px 0;
}

.sharepopup .MessagePopup_header__353jb>div {
    flex-grow: 0 !important;
}

.sharepopup .MessagePopup_content__3b01e {
    width: 100%;
}

.sharepopup .MessagePopup_crossIconContainer__2c-dJ {
    position: absolute;
    right: 0;
}

.css-1ckng2k-singleValue {
    text-align: left;
    padding: 3px 9px;
    background: #eee;
    display: table;
    position: absolute;
    top: 0px;
    grid-area: initial;
    width: auto;
    background: #e0eefb;
    border-radius: 50px;
}

.can_edit .css-1ckng2k-singleValue {
    background: transparent;
    padding: 0;
    position: static;
    grid-area: 1/1/2/3;
}

.css-wv2syo-control:focus {
    border: 1px solid #2684ff;
    box-shadow: none;
}

.css-198w020-control {
    border: 1px solid #2684ff !important;
    box-shadow: none !important;
}

.css-319lph-ValueContainer {
    padding: 2px 0 !important;
}

.css-1iwwqq2-placeholder {
    text-align: left !important;
}

span.fields {
    position: relative;
}

.css-jl3ilf-container .css-1ckng2k-singleValue {
    background: transparent;
    position: static;
    padding: 0;
}

/* For share Popup Ends */


.removeBtn {
    background: transparent;
    border: 0;
    font-size: 30px;
    line-height: 13px;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    color: #2a86e3;
    text-align: center;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    min-width: 20px;
    border: 1px solid lightgray;
}

.cancelBtn {
    padding: 8px 20px;
    font-weight: 500;
    font-size: 15px;
    width: 110px;
    border: 1px solid #e8e8e8;
    border-radius: 6px;
    background: #fff;
    color: var(--saveBtn-color);
    margin-right: 10px;
    font-weight: bold;
    outline: none !important;
    box-shadow: none !important;
}

.cancelBtn:hover {
    background: #EDF3FA !important;
    color: var(--saveBtn-color);
    border: 1px solid #e0e0e0;
}

.cancelBtn:focus {
    background: #EDF3FA !important;
    border: 1px solid #e0e0e0;
}

.cancelBtn:active {
    background: #EDF3FA !important;
    border: 1px solid #e0e0e0 !important;
    box-shadow: 0px 0px 5px 0px #4AA5FC80 !important;
}

.cancelBtn:disabled {
    opacity: 0.5;
}

.react-switch-off:hover .react-switch-bg {
    background-color: #808080 !important;
}

.react-switch-on:hover .react-switch-bg {
    background-color: #3C84CC !important;
}

.react-switch-off:active .react-switch-bg {
    box-shadow: 0 0 4px 0 #808080 !important;
}

.react-switch-on:active .react-switch-bg {
    box-shadow: 0 0 4px 0 #3B82C9 !important;
}

.textBtn {
    color: #3473b2;
    cursor: pointer;
    padding: 3px;
    background-color: transparent;
}

.textBtn:hover {
    color: #3c84cc;
    background-color: #ebf3fa;
}

.textBtn:active {
    color: #3c84cc;
    background-color: #ebf3fa;
}


.textBtn {
    color: #3473b2;
    cursor: pointer;
    padding: 3px;
}

.textBtn:hover {
    color: #3c84cc;
    background-color: #ebf3fa;
}

.textBtn:active {
    color: #3c84cc;
    background-color: #ebf3fa;
}

.datasetGroup {
    margin: 14px 0;
}

.datasetGroup .flex-simple-column {
    padding: 3px;
}

.pageEndbuttons {
    display: flex;
    justify-content: flex-end;
    /* margin-right: 45px; */
    margin-block: 25px;
}

.buttonrow {
    display: flex;
    justify-content: flex-end;
    margin-right: 75px;
    margin-block: 25px;
}

.popupbuttonrow {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    /* margin-top: 20px; */
}

.buttonsContainer {
    display: flex;
    margin-block: 20px;
    justify-content: center;
}

.acceptBtn {
    font-weight: 400;
    font-size: 15px;
    min-width: 110px;
    border: 1px solid #e8e8e8;
    border-radius: 6px;
    font-weight: bold;
    background: var(--saveBtn-color);
    color: #fff;
    outline: none !important;
}

.acceptBtn:hover {
    background: var(--saveBtn-hoverColor) !important;
    color: #fff;
    border: 1px solid #e8e8e8;
}

.acceptBtn:disabled {
    opacity: 0.5;
}

.acceptBtn:active {
    background: var(--saveBtn-hoverColor);
    box-shadow: none;
    box-shadow: 0px 0px 5px 0px #4AA5FC80;
}


.scrollBar::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.scrollBar::-webkit-scrollbar-corner {
    background-color: var(--greyColor);
}

.scrollBar::-webkit-scrollbar-track {
    background: transparent;
    /* background: red; */
    /* border-radius: 0px 0px 9px 9px; */
}

.scrollBar::-webkit-scrollbar-thumb {
    background: rgb(173, 173, 173);
    border-radius: 10px;
}

.scrollBar::-webkit-scrollbar-thumb:hover {
    background: rgb(124, 124, 124);
}


.hideIcon input::-webkit-inner-spin-button,
.hideIcon input::-webkit-outer-spin-button {
    -webkit-appearance: none;
}


.previewContainer {
    background-color: var(--greyColor);
    border: 1.46823px solid #d4d4d4;
    border-radius: 8.80937px;
    width: 100%;
    min-height: 195px;
    max-width: 100%;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mergedTableWrapper {
    background-color: var(--greyColor);
    width: 100%;
    max-width: 100%;
    overflow: auto;
    border-radius: 9px;
}

.OROperator {
    border: 1px solid lightgray;
    width: 50px;
    text-align: center;
    margin-block: 17px;
    padding: 4px;
    border-radius: 3px;
    font-size: 13px;
}

.ANDOperator {
    border: 1px solid lightgray;
    width: 50px;
    text-align: center;
    margin-block: 6px;
    padding: 4px;
    border-radius: 3px;
    position: relative;
    font-size: 13px;
}

.ANDOperator::after {
    content: "";
    width: 1px;
    height: 19px;
    position: absolute;
    background-color: lightgray;
    top: 29px;
    left: 50%;
}

.ANDOperator::before {
    content: "";
    width: 1px;
    height: 9px;
    position: absolute;
    background-color: lightgray;
    top: -10px;
    left: 50%;
}

.maareBtn {
    border: 1px solid lightgray;
    border-radius: 3px;
    width: 50px;
}

.test {
    height: 10px;
    width: 10px;
    margin-top: 7px;
    cursor: pointer;
}

.test path {
    stroke-width: 4px;
}

.customPreview {
    width: 100%;
    margin-top: 26px;
    padding: 14px;
    /* border: 1px solid #DCDCDC; */
    justify-content: center;
    max-height: 300px;
    background: var(--greyColor);
    border-radius: 6px;
    max-width: 570px;
    min-width: 100%;
}

.customPreview>div {
    overflow-x: auto;
    width: 100%;
    justify-content: center;
    background-color: var(--greyColor);
    border-radius: 6px;
    /* border-top-left-radius: 6px;
    border-top-right-radius: 6px; */
}

.textItem {
    padding-left: 3px;
    color: #555;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    width: auto;
    margin-right: 15px;
    margin-bottom: 11px;
    display: flex;
    align-items: center;
}

.newVariable {
    width: 170px;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 25px;
    text-align: left;
    margin-left: 4px;
    margin-right: 4px;
    flex: 1;
    border: 1px solid #ccc;
    color: #555;
}

.mainContainer {
    background: #fff;
    border-radius: 10px;
    width: 100%;
    margin: 15px 15px 15px -5px;
    padding: 25px 25px 0px 25px;
    height: fit-content;
}

.datasetsContainer {
    display: flex;
    width: 100%;
    margin-top: 5px;
}

.dataset {
    width: 100%;
    max-width: 100%;
}

.datasetSelector {
    width: 100%;
    margin-left: 0;
    max-width: 800px;
    margin-bottom: 15px;
}





.Select-create-option-placeholder {
    display: none;
}

[class*="-option"]:empty {
    display: none;
}

/* 
[class*="-menu"] {
    box-shadow: none !important;
} */

/* <Input Field Element> */
.MDFilledInputFieldLabel,
.MDOutlinedInputFieldLabel {
    /* 25px: 20 - left padding, 5 - extra additive */
    width: calc(100% - 25px);
    margin: 0;
    padding: 0;
    position: absolute;
    top: 20%;
    left: 20px;
    transform: translateY(-50%);
    font-weight: 400;
    font-family: "Roboto";
    cursor: text;
    transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
    text-align: left;
    transform-origin: top left;
    text-overflow: ellipsis;
    background-color: transparent;
    border: none;
    will-change: transform;
    cursor: auto;
}


.MDFilledInputFieldLabel:focus,
.MDOutlinedInputFieldLabel:focus,
.MDFilledInputFieldLabel:focus-visible,
.MDOutlinedInputFieldLabel:focus-visible,
.MDFilledInputFieldLabel:active,
.MDOutlinedInputFieldLabel:active {
    border: none;
    outline: none;
}

.MDFilledInputFieldLabel.active,
.MDOutlinedInputFieldLabel.active {
    color: var(--secondary-content-color);
    transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
}

.MDFilledInputFieldLabel.active {
    transform: translateY(-106%) scale(0.6);
}

.MDFilledInputFieldLabel.multiline.active {
    transform: scale(0.6);
    left: 20px;
    top: 8px;
}

.MDOutlinedInputFieldLabel.active {
    transform: translateY(-25%) scale(0.6);
    left: 20px;
    top: 0;
}

.MDOutlinedInputFieldWhiteLabelSpace {
    width: 100%;
    height: 100%;
    background-color: #fff;
    transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
}

.MDOutlinedInputFieldWhiteLabelSpace.notActive {
    transform: scale(0);
}

.MDOutlinedInputFieldWhiteLabelSpace.active {
    transform: scale(1);
    transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
}

.MDFilledInputField {
    background-color: rgb(231, 231, 231) !important;
    border-radius: 4px 4px 0 0;
    padding: 20px 0 0 0;
}

.MDFilledInputFieldLine {
    width: 100%;
    height: 2px;
    background-color: var(--secondary-content-color);
    position: absolute;
    top: 100%;
    transform: scale(0);
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.MDFilledInputFieldLine.active {
    transform: scale(1);
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

/* </Input Field Element> */



.import_cstom_table .import-table .row:nth-child(2)>.col-sm-12 {
    overflow: auto;
}

.import_cstom_table .import-table .row:nth-child(2)>.col-sm-12::-webkit-scrollbar {
    width: 0 !important;
}

.inputOutline:hover:focus {
    outline: 2px solid #2684ff !important;
}

.inputOutline:focus {
    outline: 1px solid #2684ff !important;
}

.textInput {
    width: auto;
    height: 36px;
	background: #FFFFFF;
	border: 1px solid #D1D1D1;
	border-radius: 4px;
	padding: 10px;
    box-sizing: border-box;

    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #3B3B3B;
}

.textInput:focus,
.textInput:hover:focus {
    outline: none;
}

.APILabel {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    color: var(--section-portal-secondary-text-color);
}

.inputErrorMessage {
    color: var(--section-portal-condition-inputs-error-color);
    margin: 2px 0 0 20px;
}

.saveRegressionHeader {
    display: flex;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    text-align: left;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px 10px !important;
    width: 100%;
    border-bottom: 1px solid #d7d7d7;
    background-color: #fff;
    margin-bottom: 16px;
    text-transform: uppercase;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.saveRegressionHeader p {
    margin: 0;
    font-size: 21px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
}

@media (max-width: 784px) {
    body {
        overflow: hidden;
    }
}
