
.container {
    display: flex;
    align-items: center;
    overflow: auto;
    z-index: 999;
    position: fixed;
    top: 54%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    /* -webkit-filter: drop-shadow(0px 8px 20px rgba(38, 46, 64, 0.12)); */
    /* filter: drop-shadow(0px 8px 20px rgba(38, 46, 64, 0.22)); */
    border-radius: 8px;
    background: #fff;
    max-width: 900px;
    /* outline: 1px solid #d7d7d7; */
  }
  
  .flex-simple-column.messagePopupContainer {
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: inherit;
  }
  
  .messagePopupContainer {
    position: "relative";
    flex-grow: 1;
    box-shadow: 0px 0px 2px 0px grey;
    border-radius: 7px;
    border: 1px solid #d1d1d1;
    height: 100%;
    padding: 0;
    background-color: var(--greyColor);
  }
  
  .header {
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
    padding: 25px 20px 20px; 
    width: 100%;
    border-bottom: 1px solid #d7d7d7;
    color: #4d5966;
    background-color: #ffffff;
    text-transform: uppercase;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-bottom: 16px;
    height: 10vh;
  }
  
  .header p {
    font-size: 18px;
    margin: 0 0 0 0;
    padding-left: 20px;
    text-align: left;
  }
  
  .mainContent {
    color: #7b7b7b;
    display: flex;
    justify-content: center;
    font-family: Roboto;
    font-style: normal;
    font-size: 16px;
    font-weight: bold;
    margin-inline: 0;
    align-self: baseline;
    text-align: left;
    max-height: 370px;
    overflow: auto;
    width: 100%;
    min-height: 65px;
    margin-bottom: 5px;
    margin-left: 0;
    margin-top: 5vh;
    padding-left: 0;
    padding-bottom: 15px;
  }
  
  .subContent {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3vh;
    width: 100%;
    color: #767676;
  }
  
  .paragraph {
    max-width: 400px;
    text-align: center;
    font-style: italic;
    font-size: 14px;
  }
  
  .btnRow {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    justify-content: flex-end;
    margin-top: '30vh';
  }
  
  
  @media (max-width: 784px) {
    .container {
        width: 80%;
    }
  
    .container {
        top: 70px;
        transform: translate(-50%, 0);
        width: 96%;
        position: absolute;
    }
  }
  