.moduleInput {
	outline: none;
	border: none;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 18.75px;
	padding: 8px;
    color: #e9e9e9;
	background-color: transparent;
}

.moduleInput:focus {
    color: #242124;
	background-color: #FFFFFF;
}
