.formatColumnDropdownButton {
	position: absolute;
	bottom: -5px;
	right: 0;
	background-color: transparent;
	border: none;
	align-items: center;
}

.subTitle {
	border: none;
	border-radius: 0;
	padding-left: 0;
}

.subTitle:focus {
	outline: none;
}

.subTitle:disabled {
	background-color: transparent;
}

.editableSubTitle {
	border: 1px dashed #acacac;
	border-radius: 3px;
	padding-left: 2px;
}

.editableSubTitle:focus {
	outline: none;
}

.titleEditMode {
	border: none;
	border-radius: 0;
	padding-left: 0;
}

.titleEditMode:focus {
	outline: none;
}

.logo {
	color: #fff;
	display: inline-flex;
	border-radius: 5px;
	justify-content: center;
	margin: 0 2px 0 0;
	padding: 4px 6px;
}

.logoText {
	margin: 0;
}

.table {
	display: flex;
	flex-direction: column;
	position: relative;
}

.row {
    color: var(--primary-text-color);
    font-family: Roboto;
    font-size: 10px;
	display: flex;
	flex-direction: row;
}

.cell {
	height: 100%;
}

.header {
    color: var(--secondary-text-color);
    font-family: Roboto;
    font-size: 12px;
	display: flex;
	flex-direction: row;
}

.headerCell {
	height: 100%;
}
