.input {
	color: #333333;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	border: 1px solid #d1d1d1;
    border-radius: 4px;
	outline: none;
	width: "100%";
	height: 35px;
}