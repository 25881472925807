.colorMenu {
	margin-top: 10px;
}

.colorMenuFirstRow {
	display: flex;
	align-items: flex-start;
}

.colorSelect {
	flex-grow: 1;
}

.markerMenu {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-right: 8px;
}

.markerArea {
	width: 60px;
	height: 60px;
	background: #F7F7F7;
	border: 1px dashed #D1D1D1;
	margin-bottom: 5px;
	position: relative;
}

.markerIcon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.changeIconButton {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	color: #326EAA;
	cursor: pointer;
}

.colorOptionsMenu {
	display: flex;
	align-items: center;
	flex-grow: 1;
}
