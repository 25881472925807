.toggleButton {
  border: none;
  background: none;
  padding: 0;
}

.toggleButton:focus {
  border: none;
  background: none;
}

.toggleButton:hover {
  border: none;
  background: none;
}

.toggleButton:active {
  border: none;
  background: none;
  outline: none;
  box-shadow: none;
}

.toggleButton:active:focus {
  border: none;
  background: none;
  outline: none;
}

.menu {
  padding: 5px;
  border: 1px solid rgba(51, 51, 51, 0.1);
  box-shadow: 0px 8px 20px rgba(38, 46, 64, 0.12);
  border-radius: 4px;
}

.item {
  width: 100%;
  border: none;
  background: none;
  border-radius: 3px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  padding: 5px;
  display: flex;
  align-items: center;
}

.item:hover {
  background: #EAF0FA;
  font-weight: 500;
}

.avatar {
  object-fit: contain;
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.placeholder {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.placeholderImage {
  object-fit: cover;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.placeholderLabel {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: rgba(51, 51, 51, 0.5);
}

.selectOption {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

.selectOption.selected {
  padding: 5px 0;
}

.selectOption.selected:hover {
  background: transparent;
}

.selectOptionLabel {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  margin-left: 10px;
}
