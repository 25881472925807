.carousel {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;    
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* Enables bounce effect on iOS devices */
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .carousel::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  
  .item {
    flex-shrink: 0;
    padding: 5px;
    margin: 5px;
    background-color: #7575756d;
    border: 1px solid #757575dd;
    border-radius: 4px;
    min-width: 150px;
    margin-right: 10px;
    text-align: center;
    color: white;
    font-size: small;
    font-weight: 800;
    user-select: none;
    overflow: hidden; /* Ensures the ripple stays within the item */

    transition: box-shadow 0.5s ease;
    /*background: linear-gradient(270deg, #7575756d, #757575ec, #374ba1, #86a8e7, #6a7997);*/
    background: linear-gradient(270deg, #757575f4, #757575b6, #7575756d, rgba(0, 173, 147, 0.1), rgba(0, 173, 147, 0.5), rgba(0, 173, 147, 1));
    background-size: 500% 500%;
    animation: gradientAnimation 60s ease infinite;
  }
  
  
  @keyframes gradientAnimation {
    0% {
      background-position: 100% 50%;
    }
    50% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
  
  