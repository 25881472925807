.content {
    max-width: 1260px;
    margin: 0 auto;
    padding-top: 25px;
}

.topBarsContainer {
    background: rgba(238, 238, 238, 0.4);
    padding: 0 0 12px 0;
}
