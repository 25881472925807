.QuestionHeader {
    font-family: "Montserrat", "Manrope", "Roboto";
    font-size: 20px;
    margin: 0px 0px 15px 0px;
    font-weight: 400;
    word-wrap: break-word; 
    position: relative;  
    color: #3f3f3f;
}

.QuestionHeader:not(:first-child) {
    margin-top: 30px;
}

.sliderLegend {
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: #3399ff;
    height: 18px;
}

.sliderWrapper {
    padding: 0px 8px 0px 8px;
}

.editModeCameraInnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btnWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.formWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.inputText {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #d1d1d1;
    resize: none;
    overflow: hidden;
    display: inline-block;
}

.inputText:focus {
    border: 2px solid rgb(51, 153, 255);
    box-shadow: none;
    outline: none !important;
}

.inputLongText {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #d1d1d1;
    resize: none;
    display: inline-block;
}

.inputLongText:focus {
    border: 2px solid rgb(51, 153, 255);
    box-shadow: none;
    outline: none !important;
}
