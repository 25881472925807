.root {
	padding: 15px 20px;
	display: flex;
	align-items: center;
	position: relative;
	height: 100%;
}

.title {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	margin-left: 10px;
	color: rgba(36, 33, 36, 1);
}

.selected > .title {
	font-weight: 500;
}

.selected::after {
	content: '';
	position: absolute;
	bottom: -2px;
	left: 0;
	right: 0;
	height: 2px;
	background: linear-gradient(0deg, #2C74C8, #2C74C8), #FFFFFF;
}
