.gradientPickerContainer {
	position: relative;
	width: 100%;
	max-width: 290px;
	margin: 0 0 60px 0;
}

.gradientPreviewLine {
	width: 100%;
	height: 50px;
	border: none;
	padding: 0;
	border-radius: 10px;
}

.colorPointerTriangle {
	width: 10px;
	height: 10px;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 8px solid #555;
  	margin: 0 0 3px 0;
}

.colorPointerFlask {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: none;
}
