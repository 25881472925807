.root {
	border: none;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 2;
	padding: 10px 8px 10px 8px;
	background: transparent;
	border-radius: 11px;
}

.rootOpen {
	background: #000 !important;
}

.rootActive {
	background: #3C83CA !important;
}

.root:focus {
	border: none;
	background: transparent;
}

.root:active {
	border: none;
	background: #000;
	outline: none;
	box-shadow: none;
	
}

.root:hover {
	border: none;
	background: #000;
}

.root:active:focus {
	border: none;
	background: #000;
	outline: none;
}