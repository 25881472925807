.modelContainer {
    padding: 10px;
    background: rgba(246, 246, 246, 0.8);
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: #75869a;
    margin-bottom: 10px;
}

.summaryArea {
    font-family: Roboto;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    white-space: pre-wrap;
}

.modelArea {
    border: none;
    padding-top: 10px;
    height: 40px;
}
