.datasetsContainer {
    margin-top: 30px;
}

.datasetsContainer>div {
    width: 50%;
}


.previewContainer {
    background: var(--greyColor);
    border: 1px solid #d4d4d4;
    border-radius: 6px;
    width: 100%;
    min-height: 200px;
    padding: 10px;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.textItem {
    padding-left: 3px;
    margin-bottom: 11px;
    color: #555555;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
}

.editInput {
    font-family: Roboto;
    font-size: 16px;
    border: 1px solid #d1d1d1;
    background-color: white;
    outline: none;
    color: #000000;
    margin-bottom: 0;
    width: 100%;
    margin-top: 7px;
    height: 36px;
    padding-left: 6px;
    border-radius: 6px;
}

.editInput:focus {
    box-shadow: 0 0 0 1px var(--acceptBtn-color);
}

.focusHover:hover {
    box-shadow: 0 0 0 2px var(--acceptBtn-color);

}

.borderLine {
    height: 1px;
    background-color: #e8e8e8;
    margin-left: 22px;
    margin-right: 30px;
}


.bottomBarButton:disabled {
    opacity: 0.5;
    color: red;
}

.bottomBar {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    margin-right: 76px;
}