.swapButtonContainer {
	width: 48px;
	min-height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.swapButton {
	width: 32px;
	height: 32px;
	cursor: pointer;
}

.swapButton:hover {
	outline: 1px solid #d1d1d1;
}