.labelStyle {
	width: 100%;
	margin: 0;
	padding: 3px 20px 3px 20px;
	cursor: pointer;
	color: #626262;
}

.button {
	width: 88px;
	height: 33px;
	left: 220px;
	top: 462px;
	background: #3B82C9;
	border-radius: 5px;
	color: #ffffff;
}

.button:hover {
	color: #ffffff;
	background: linear-gradient(94.78deg, #326eaa 0%, #275a8e 100%), #ffffff;
}
.button:focus,
.button:active {
	color: #ffffff;
}
