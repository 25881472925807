.mainContainer {
    background: rgb(255, 255, 255);
    border-radius: 10px;
    width: 100%;
    margin: 15px 15px 26px 0px;
}

.datasetsContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.datasetSelector {
    width: 100%;
    padding: 25px;
}

.textItem {
    padding-left: 3px;
    color: #555;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    width: auto;
    margin-right: 15px;
    margin-bottom: 11px;
    display: flex;
    align-items: center;
}

.summaryArea {
    font-family: Roboto;
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    white-space: pre-wrap;
}

.mainPreviewContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 6fr 6fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    justify-items: stretch;
    align-items: stretch;
}

.heading2 {
    width: 120px;
    height: 19px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #555;
}

.previewContainer {
    background: #ffffff;
    border: 1.46823px solid #d4d4d4;
    box-sizing: border-box;
    border-radius: 8.80937px;
    width: 70%;
    min-height: 200px;
    max-width: 70vw;
}

.modelContainer {
    margin-top: 5px;
    background: rgba(246, 246, 246, 0.8);
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: #75869a;
    margin-bottom: 10px;
}

.borderLine {
    height: 1px;
    background-color: #e8e8e8;
    margin-left: 22px;
    margin-right: 30px;
}

.thinOptionName {
    color: #555;
    border: 1px solid red;
}