body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.api-table>thead>tr>th {
  border-bottom: transparent !important;
}

.api-table>tbody>tr:first-child>th,
.api-table>tbody>tr:first-child>td,
.api-table>thead>tr:first-child>td {
  border-top: transparent !important;
}

.api-table>tbody>tr>td {
  padding: 30px 10px;
}

.api-table>thead>tr>th,
.api-table>tbody>tr:not(:first-child)>th,
.api-table>tfoot>tr>th,
.api-table>thead>tr:not(:first-child)>td,
.api-table>tbody>tr:not(:first-child)>td,
.api-table>tfoot>tr>td {
  border-top: 1px solid var(--graphs-stroke-color)
}