.container {
    width: 100%;
    height: 100%;
    padding: 20px;
}

.title {
    font-family: Roboto;
    color: #333333;
    font-weight: 500;
    font-size: 20px;
    display: flex;
    justify-content: center;
}