textarea.multiline-input {
    overflow-x: hidden; /* for Firefox */
    word-wrap: break-word;
    min-height: 18px;
    max-height: 112px; /* optional, but recommended */
    resize: none;
    width: 100%;
    height: 18px;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-align: inherit;
    background-color: transparent;
    box-shadow: none;
    box-sizing: border-box;
    border-radius: 3px;
    border: 0;
    padding: 0 8px;
    outline: 0;
}

.focus-no-border:focus {
    background-color: white;
}

.focus-border:focus {
    background-color: white;
    box-shadow: inset 0 0 0 2px #0079bf;
}
