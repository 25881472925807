.container {
    display: flex;
    align-items: center;
    overflow: visible;
    position: fixed;
    z-index: 100000000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: drop-shadow(0px 8px 20px rgba(38, 46, 64, 0.12));
    border-radius: 4px;
    background: white;
}

.crossIconContainer {
    cursor: pointer;
    margin-right: 15px;
}

.crossIcon:hover {
    stroke: #bababa;
}

.header {
    display: flex;
    height: 45px;
    background: linear-gradient(
            90.2deg,
            #323232 49.83%,
            rgba(50, 50, 50, 0.9) 100%
        ),
        #ffffff;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #ffffff;
}

.content {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #242124;
    margin-top: 24px;
    margin-left: 24px;
    margin-right: 24px;
}

.messageButton {
    height: 35px;
    min-width: 98px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    border: 1px solid rgba(4, 30, 55, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(49, 54, 58, 0.04);
    border-radius: 4px;
}

.rejectButton {
    background: #ffffff;
    color: #13529b;
}

.rejectButton:hover,
.rejectButton:focus,
.rejectButton:active {
    background: #eaeaea;
    color: #13529b;
}

.acceptButton {
    font-weight: 400;
    font-size: 15px;
    min-width: 110px;
    border: 1px solid #e8e8e8;
    border-radius: 6px;
    font-weight: bold;
    outline: none !important;
    background: var(--acceptBtn-color);
    color: #ffffff;
}

.acceptButton:hover,
.acceptButton:focus,
.acceptButton:active {
    background: linear-gradient(94.78deg, #1a60b3 0%, #13529b 30%), #ffffff;
    color: #ffffff;
    border: 1px solid #e8e8e8;
}

.acceptButton:disabled {
    opacity: 0.5;
}

.acceptButtonDanger {
    font-weight: 400;
    font-size: 15px;
    min-width: 110px;
    border: 1px solid #e8e8e8;
    border-radius: 6px;
    font-weight: bold;
    outline: none !important;
    background: var(--danger-color);
    color: #ffffff;
}

.acceptButtonDanger:hover,
.acceptButtonDanger:focus,
.acceptButtonDanger:active {
    background: linear-gradient(94.78deg, #b3351a 0%, #ac3116 30%), #ffffff;
    color: #ffffff;
    border: 1px solid #e8e8e8;
}

.acceptButtonDanger:disabled {
    opacity: 0.5;
}

@media (max-width: 784px) {
    .container {
        width: 80% !important;
    }
}
