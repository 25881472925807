.searchContainer {
    display: flex;
    background: #ffffff;
    border-bottom: 1px solid #BFBFBF;
    box-sizing: border-box;
    border-radius: 0;
    width: 100%;
    height: 35px;
    align-items: center;
    padding-left: 0;
    max-width: 300px;
}


.toolbarContainer {
    height: 62px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-right: 25px;
}

.searchInput {
    font-family: Roboto;
    border: none;
    padding: 0;
    width: 100%;
    outline: none;
    color: #555;
}

.searchInput::placeholder {
    color: #555;
}

.borderLine {
    height: 1px;
    background-color: #e8e8e8;
    margin-left: 22px;
    margin-right: 30px;
}

.customTable {
    padding: 0 20px;
    width: 100%;
    margin: 0;
}

.grid {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-inline: auto;
    /* max-height: 363px; */
}

.grid thead tr {
    border-radius: 5px;
}

.customTable::-webkit-scrollbar {
    width: 5px;
    height: 6px;
}

.customTable::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.customTable::-webkit-scrollbar-thumb {
    background: rgb(173, 173, 173);
}

.customTable::-webkit-scrollbar-thumb:hover {
    background: rgb(124, 124, 124);
}


.gridRow {
    width: 100%;
    display: flex;
    padding-left: 20px;
}

.gridTitle {
    margin-block: 5px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #555;
}

.gridItem {
    color: #555555;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding-block: 3px;
    display: flex;
    align-items: center;
}

.actionButton {
    background: #fff;
    border: 1px solid #d1d1d1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
}

.actionButton:focus {
    outline: none;
}

.actionButton:first-child {
    border-right: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.actionButton:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.activeButton {
    background-color: #75869a;
}

.manageButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
}

.manageButton {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    color: #3b82c9;
    background-color: transparent;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    text-align: center;
    border-radius: 3px;
}

.editInput {
    font-family: Roboto;
    border: 1px solid #d1d1d1;
    background-color: white;
    outline: none;
    color: #666666;
}


.sortButton {
    background-color: transparent;
    border: none;
    padding: 5px 10px;
    margin: 0;
    line-height: 1;
    font-size: 15px;
    color: black;
    cursor: pointer;
    transition: transform 0.05s ease-out;
}

.sortReverse {
    transform: rotate(180deg);
}

.controles {
    cursor: pointer;
    color: #4f98e1;
    font-weight: normal;
}

.controles:hover {
    color: #2674c1;
}

.dropdownMenu {
    box-shadow: 0 0px 12px rgb(0 0 0 / 30%);
    border-radius: 6px;
    padding: 5px;
}

.DropdownMenuCustom {
    background-color: black;
    position: absolute;
    z-index: -1;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    padding: 15px;
    top: 0;
}

.dropdownToggle {
    background-color: transparent;
    border: none;
    width: 32px;
    border-radius: 50px;
    text-align: center;
    padding-block: 5px;
}

.dropdownToggle:hover {
    background-color: var(--greyColor);
    border: none;
}

.dropdownToggle:focus:active {
    background-color: var(--greyColor);
    border: none;
}

.dropdownToggle:focus {
    background-color: var(--greyColor);
    border: none;
}

.dropdownToggle:active {
    background-color: var(--greyColor);
    border: none;
}

.dropdownItem {
    font-size: 16px;
    line-height: 20px;
    display: flex;
    width: 100%;
    padding: 6px;
    padding-left: 15px;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    vertical-align: middle;
    align-items: center;
    border-bottom: 0;
    border-radius: 6px;
    cursor: pointer;
}

.dropdownItem img {
    margin-right: 7px;
}


.dropdownItem:last-child {
    border-bottom: 0;
}

.dropdownItem:hover {
    color: #0c0c0c;
    background: var(--greyColor);
    font-weight: 500;
}

.arrow {
    background-color: white;
    position: absolute;
    z-index: -1;
    transform: rotate(45deg);
    padding: 10px;
    top: -5px;
    left: 133px;
}

.previewTable {
    width: 100%;
    margin-inline: auto;
    margin-block: 0;
    border: 1px solid;
    border: 1px solid #DCDCDC;
    min-width: 800px;
}

.customPreview {
    margin: 17px 0;
    padding: 18px;
    border: 1px solid #DCDCDC;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    max-height: 200px;
    overflow: auto;
    border-radius: 6px;
}

.previewRow {
    border: 1px solid #dcdcdc;
}

.highlightmain {
    width: 100%;
    display: flex;
    padding-left: 20px;
    background-color: rgba(59, 130, 201, 0.1);
    /* background-color: red; */
}

.previewNull {
    line-height: 165px;
}

.previewHeaderColumn {
    padding: 5px;
    text-align: right;
    border: 1px solid #dcdcdc;
}

.previewColumn {
    text-align: right;
    border: 1px solid #dcdcdc;
    padding: 5px;
}

.popupCustom .popupWrapper {
    margin: 0 auto;
    border-radius: 8px;
    padding: 0;
    /* background: #fff; */
}

.popupCustom .popupContent {
    margin-top: 0px;
}

.popupCustom .popupContent .boxRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}

.popupCustom .popupContent .boxRow .customColumn {
    width: 31%;
    background: #ECECEC;
    border-radius: 6px;
    position: relative;
    border: 2px solid #D7D7D7;
    overflow: hidden;
    margin-block: 20px;
}

.popupCustom .popupContent figure {
    position: relative;
    background: #fff;
    border-radius: 6px;
    overflow: hidden;
    margin: 0;
}

.popupCustom .popupContent figure img {
    width: 100%;
}

.popupCustom .popupContent .outLink {
    position: absolute;
    top: 6px;
    right: 5px;
    cursor: pointer;
}

.popupCustom .popupContent .infoBar {
    display: flex;
    align-items: center;
    padding: 10px 0px 10px 10px;
}

.popupCustom .popupContent .infoBar span.linkIcon {
    padding-right: 10px;

}

.popupCustom .popupContent .infoBar p {
    margin: 0;
    font-size: 17px;
    color: #0a0a0a;
    font-weight: 300;
}

.noDataFound {
    width: 100%;
    text-align: center;
    min-height: 80px;
    line-height: 80px;
    /* font-size: 20px; */
    font-weight: 300;
}

.noData{
    /* position: absolute;
    transform: translate(-50%, 0%);
    left: 50%;
    top: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    color: #656565;
}

.imgWidth{
    max-width: 100%;
}

.searchInput::-webkit-input-placeholder {
    color: #555;
}

.searchInput:-ms-input-placeholder {
    color: #555;
}

.searchInput::placeholder {
    color: #555;
}

/* For shared popup starts */

.popup_custom .popup_wrapper {
    margin: 0 auto;
    border-radius: 8px;
    padding: 0;
    /* background: #fff; */
}

.popup_custom .popup_content {
    margin-top: 10px;
}

.popup_custom .popup_hdr h2 {
    margin: 0;
    font-size: 30px;
}

.popup_custom .popup_hdr h2 span {
    font-weight: 400;
    font-size: 24px;
}

.dropdownToggle:hover .dropdownMenu {
    display: block;
}

/* For shared popup ends */


@media only screen and (max-width: 650px) {
    .toolbarContainer {
        padding-right: 0;
    }

    .customTable {
        padding: 0 0;
        margin: 0;
    }

    .grid {
        min-width: 600px;
    }

    .mainContainer {
        width: 90%;
        padding: 0 3%;
    }


}

@media only screen and (max-width: 767px) {
    .popupCustom .popupContent .boxRow .customColumn {
        width: 48%;
    }
}

@media only screen and (max-width: 480px) {
    .popupCustom .popupContent .boxRow .customColumn {
        width: 100%;
        margin-block: 7px;
    }

    .mainContainer {
        width: 83%;
        padding: 0 3%;
    }


}


.dropdown {
    position: relative;
    display: inline-block;
}

.dropdownContent {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 100000000;
    padding: 5px;
    right: 0;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
}


.dropdown:hover .dropdownContent {
    display: block;
}