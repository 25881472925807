.barContainer {
    background: var(--canvas-page-bar-background-color);
	border: 2px solid #E8E8E8;
    border-radius: 4px;
    display: flex;
    height: 34px;
    align-items: center;
}

.sectionRow {
	display: flex;
	align-items: center;
	width: 100%;
    border-bottom: 1px solid #44556b;
}

.sectionName {
    white-space: nowrap;
    outline: none;
    border: none;
    width: 100%;
    text-align: center;

    padding-top: 2px;
    padding-bottom: 2px;
    color: var(--canvas-page-bar-text-color);
    background-color: transparent;

    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    color: #000000;
}

.sectionNameActive {
    font-weight: 600;
}

.sectionContainer {
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    height: 50px;
}
