input.like-select {
    background-color: var(--selectors-background-color);
    border: 1px solid #D1D1D1;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 2px 8px;
    min-height: 38px;
    color: var(--selectors-text-color);
    font-size: 14px;
    font-family: Roboto;
}

input.like-select:focus {
    outline: none;
}

input.bordered {
    background-color: var(--content-background-color);
    border: 1px solid var(--secondary-content-color);
    border-radius: 3px;
    box-sizing: border-box;
    padding: 2px 8px;
    color: var(--primary-text-color);
    font-size: 14px;
    font-family: Roboto;
}

textarea::placeholder.spreadsheet-header-textarea {
    color: #869aac;
    font-weight: bold;
}

input.bordered:focus {
    outline: none;
}

input.module-template {
    outline: none;
    border-radius: 4px;
}

input.module-template:focus {
    outline: none;
    border: 1px solid #1f8efa;
    border-radius: 4px;
}

textarea.module-template:focus {
    outline: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

textarea.module-template:focus {
    outline: none;
    border: 1px solid #1f8efa;
    border-radius: 4px;
}

input.zoom-canvas:focus {
    outline-style: solid;
    outline-color: #3399ff;
}

input.transparent {
    background-color: transparent;
    border: none;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 0px;
    color: var(--selectors-text-color);
    font-size: 14px;
    font-family: Roboto;
}

textarea.like-select {
    border: 1px solid #D1D1D1;
    margin: 0px;
    border-radius: 3px;
    background-color: var(--selectors-background-color);
    font-family: "Roboto";
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    color: var(--selectors-text-color);
}

textarea::placeholder.like-select {
    color: #969aa3;
}

input.like-tab {
    outline: none;
    border: 2px solid transparent;
    color: var(--sections-title-color);
    background-color: transparent;
    font-family: Roboto;
    font-size: var(--sections-title-size);
}

input.like-tab:focus {
    border: 2px solid var(--secondary-content-color);
}
input.like-tab:focus:read-only {
    border: none;
}

input.like-path-header {
    outline: none;
    border: none;
    color: var(--content-primary-text-color);
    background-color: transparent;
    font-family: Roboto;
    font-weight: 300;
    font-size: var(--path-header-size);
}

input.like-path-header:focus {
    border: 2px solid var(--secondary-content-color);
}

input.like-card-title {
    outline: none;
    border: none;
    color: var(--content-primary-text-color);
    background-color: transparent;
    font-family: Roboto;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
}

input.like-card-title:focus {
    border: 2px solid var(--secondary-content-color);
}

input.tab-page {
    letter-spacing: 2.67px;
    outline: none;
    border: 1px solid transparent;
    background-color: transparent;
    font-family: Arial;
    font-size: 12px;
}

input.dropdown-label {
    outline: none;
    border: none;
    color: var(--content-tertiary-text-color);
    background-color: transparent;
    font-family: Arial;
    font-size: 9px;
}

input.tab-page:focus {
    border: 1px solid var(--secondary-content-color);
}

input.tab-page:focus:read-only {
    border: 1px solid transparent;
}

input.no-spin::-webkit-inner-spin-button,
.no-spin::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

input.no-spin {
    -moz-appearance: textfield !important;
}
