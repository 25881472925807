.optionName {
    font-family: Roboto;
    font-size: 14px;
    color: var(--popup-primary-text-color);
    width: 180px;
}

.title {
    font-family: Roboto;
    font-size: 17px;
    color: var(--popup-primary-text-color);
}

.root {
    display: flex;
    align-items: center;
    width: 550px;
    min-height: 380px;
    position: absolute;
    box-shadow: 0px 6px 13px rgba(21, 33, 56, 0.533345);
    border-radius: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
