.root {
    padding: 20px;
}

.field {
    margin-bottom: 10px;
}

.label {
    display: block;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: rgba(51, 51, 51, 0.7);
    margin-bottom: 5px;
}
