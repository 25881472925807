.canvasViewSwitcherWrapper {
  width: 100%;
  background-color: #eee;
}

.canvasViewSwitcherAccordeon {
  display: flex;
  color: #444;
  cursor: pointer;
  padding: 11px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  justify-content: center;
}

.canvasViewSwitcherAccordeon::before {
  background-color: #3e3e3e;
  content: " ";
  display: block;
  height: 2px;
  margin-top: -1px;
  top: 10px;
  transition: all .4s cubic-bezier(.645,.045,.355,1);
  width: 15px;
}

.canvasViewSwitcherAccordeon::after {
  background-color: #3e3e3e;
  content: " ";
  display: block;
  height: 2px;
  margin-left: -1px;
  margin-top: -1px;
  top: 10px;
  transition: all .4s cubic-bezier(.645,.045,.355,1);
  width: 15px;
}

.canvasViewSwitcherAccordeon:hover::before {
  transform: rotate(20deg);
}

.canvasViewSwitcherAccordeon:hover::after {
  transform: rotate(-20deg);
}

.canvasViewSwitcherWrapper .active, .accordion:hover {
  background-color: #ccc;
}

.canvasViewSwitcherPanel {
  display: flex;
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  justify-content: center;
}

.canvasViewSwitcherPanel svg {
  width: 32px;
  height: 32px;
  margin: 5px 0;
}

.activeViewMode {
  filter: invert(53%) sepia(25%) saturate(2612%) hue-rotate(152deg) brightness(94%) contrast(101%);
}
