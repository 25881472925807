.table {
  width: 100%;
  margin-top: 15px;
}

.table th {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;  
  color: #797979;
  padding-bottom: 5px;
}

.tableRow > td {
  padding-bottom: 10px;
}

.label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}

.numberInput {
  border: 1px solid #D1D1D1;
  border-radius: 4px;
  padding: 5px;
  max-width: 50px;
}

.switchOn > div:first-child {
	opacity: .2;
}
