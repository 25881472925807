.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex: 1;
    background-color: white;
    gap: 10px;
}

.header {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 5px;
    position: relative;
}

.title {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
}

.subtitle {
    font-family: Roboto;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
}

.logoContainer {
    position: absolute;
    left: 100px;
    top: 0px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    max-width: 100px;
    max-height: 100px;
}

.dropdownArea {
    padding: 10px 10px 10px 0;
    width: 20em;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
}

.dropdownArea > * {
    flex: 1;
}

.dropdownAreaTitle {
    font-family: Roboto;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
}

.chartContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1;
    padding: 20px;
    gap: 30px;
}

.chartTitle {
    font-family: Roboto;
    font-size: 16px;
    color: white;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
}

.barChartContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 20px 30px 20px;
    flex: 1;
    gap: 10px;
    background-color: #868f9c;
    border-radius: 5px;
    overflow-x: hidden;
    overflow-y: auto;
}

.barChartInnerContainer {
    flex: 1;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.barChartWrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    gap: 20px;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.rankChartContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 80px 30px 80px;
    flex: 3;
    gap: 10px;
    background-color: #788dab;
    border-radius: 5px;
}

.rankChartInnerContainer {
    flex: 1;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.rankChartWrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
