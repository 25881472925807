.fileContainerDragArea {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    border: 2px dashed #a3a3a3;
}

.filterRoot {
	display: flex;
	align-items: center;
	margin: 10px 0 0 9px;
}

.filterButton {
    display: flex;
	align-items: center;
	cursor: pointer;
}

.filterOption {
	padding: 8px;
	cursor: pointer;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	white-space: nowrap;
	color: rgba(51, 51, 51, 0.8);
}

.filterOptionSelected {
	border: 1px solid #3b82c9;
	color: #326eaa;
	border-radius: 16px;
}
