.card {
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    margin: 3vh 4vw 0 4vw;
    padding: 3vh 2vw 3vh 2vw;
}

.contentWrapper {
    width: 90%;
    height: 90%;
}

.header {
    font-weight: 700;
    font-size: 20px;
}

.searchBarWrapper {
    width: 100%;
    display: flex;
    justify-content: end;
    padding-bottom: 20px;
}

.searchInput {
    font-family: Roboto;
    border: none;
    padding: 0;
    width: 100%;
    outline: none;
    color: #555;
}

.searchInput::placeholder {
    color: #555;
}

.searchContainer {
    display: flex;
    background: #ffffff;
    border: 1px solid #BFBFBF;
    border-radius: 4px;
    box-sizing: border-box;
    width: 100%;
    height: 35px;
    align-items: center;
    max-width: 250px;
    padding: 0 10px 0 10px;
}