.AggregateSection_dataset__3jCuC {
    width: 100%;
    max-width: 70%;
}

.variablesContainer {
    margin-top: 22px;
    display: flex;
    flex-direction: column;
    width: 610px;
    margin-left: 40px;
}

.dropMissingContainer>div {
    border-radius: 0px 0px 5px 5px;
}


.borderLine {
    height: 1px;
    background-color: #e8e8e8;
    margin-left: 22px;
    margin-right: 30px;
}

.bottomBarButton {
    border: none;
    outline: none;
    background: #3b82c9;
    border-radius: 6px;
    width: 158px;
    height: 40px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
}

.bottomBarButton:hover {
    background: #11508f;
}

.bottomBarButton:active {
    background: #639cd4;
}

.bottomBarButton:disabled {
    opacity: 0.5;
}

.bottomBar {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    margin-right: 76px;
}

.mainContainer {
    background: #fff;
    border-radius: 10px;
    width: 100%;
    margin: 15px 15px 30px 0;
}

.datasetsContainer {
    display: flex;
    width: 100%;
    margin-top: 5px;
}

.datasetSelector {
    width: 100%;
    margin-left: 0;
    /* margin-top: 15px; */
    /* max-width: 800px; */
    margin-bottom: 15px;
}

.datasetSelector .flex-simple-column {
    padding: 0;
}

.dataset {
    width: 50%;
    max-width: 50%;
}

.mergType {
    display: flex;
    width: auto;
    align-items: center;
    margin-right: 30px;
    padding-right: 30px;
    border-right: 1px solid #aaa;
}

.dropMissingContainer {
    width: auto;
    display: flex;
    margin: -1px 0 0 0;
    justify-content: space-between;
}


.dropMissingContainer .dropMissing {
    width: 39%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 0 6px 0 8px;
    position: relative;
    top: 0px;
    left: 4px;
}

.dropMissingContainer .textItem {
    margin: 0 15px;
}

.dropMissingContainer>div {
    display: flex;
    width: 61%;
    background: var(--greyColor);
    padding: 0 10px 15px 12.5%;
    /* justify-content: center; */
    border-radius: 0 0 6px 8px;
}

.mergedTableWrapper {
    background: var(--greyColor);
    width: 100%;
    max-width: 100%;
    overflow: auto;
    border-radius: 9px;
}

.dropMissingItem {
    display: flex;
    align-items: center;
}

.textItem {
    padding-left: 3px;
    color: #555;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    width: auto;
    margin-right: 15px;
    margin-bottom: 11px;
    display: flex;
    align-items: center;
}


.variableTableWrapper {
    background: var(--greyColor);
    border-radius: 6px 6px 0 6px;
    padding: 18px;
}

.variablesContainer table th p {
    margin: 0;
    font-weight: normal;
}

.variablesContainer table th {
    padding: 15px 15px 10px;
}

.variablesContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 20px;
    margin: 0;
}

.variablesContainer table {
    background: #fff;
    border-radius: 10px;
    width: 100%;
}

.variablesContainer table td {
    min-width: 430px;
    padding: 10px 15px;
}

.variablesContainer table tr {
    border-bottom: 1px solid #F9F9F9;
}

.variablesContainer table tr:hover {
    background: #e9f4ff;
    border-radius: 6px;
}

.variablesContainer table tr:first-child {
    background: transparent;
}

.variablesContainer table tr:last-child {
    border-bottom: 0;
}

.variablesContainer h2 {
    font-size: 18px;
    font-weight: normal;
}

.mergeContainer {
    background: #ffffff;
    border: 1px solid #d1d1d1;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-radius: 4px;
    height: 35px;
    padding-left: 1px;
    padding-right: 1px;
    width: fit-content;
}

.resetbtn {
    background-color: #f5f5f5;
    border: 1px solid #cccccc;
    margin-left: 5px
}

.resetbtn:hover {
    background-color: whitesmoke;
}

.mergeButton {
    border-radius: 2px;
    height: 31px;
    width: 94px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: none;
    outline: none;
    color: rgba(51, 51, 51, 0.7);
}

.mergeButton:hover {
    background-color: #d1d1d1;
}

.mergeButtonSelected {
    background-color: #75869a !important;
    color: #ffffff;
}

.mergeAndTargetContainer {
    margin-top: 0;
    display: flex;
    margin-left: 0;
    padding-top: 20px;
    background: var(--greyColor);
    justify-content: center;
    padding-bottom: 20px;
    align-items: center;
    border-top: 1px solid #d5d5d5;
}

.previewContainer {
    background: var(--greyColor);
    border: 1.46823px solid #d4d4d4;
    box-sizing: border-box;
    border-radius: 8.80937px;
    width: 100%;
    min-height: 195px;
    max-width: 100%;
    padding: 15px;
}

.borderLine {
    height: 1px;
    background-color: #e8e8e8;
    margin-left: 22px;
    margin-right: 30px;
}

.bottomBarButton {
    background: #3b83c9;
    color: #fff;
    width: 200px;
    padding: 8px 10px;
    border-radius: 5px;
    border: 0;
}

.bottomBarButton:hover {
    background: #11508f;
}

.bottomBarButton:active {
    background: #639cd4;
}

.bottomBarButton:disabled {
    opacity: 0.5;
}

.previewbtnContainer {
    margin-block: 20px;
    display: flex;
    justify-content: center;
}

.previewbtnContainer button {
    width: 143px;
    padding: 8px 10px;
}

.mergedPreview th {
    border-left: 0 !important;
}

.mergedPreview th>div>div {
    margin-right: 0 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 20px;
    border-bottom: 1px solid #eee;
    max-width: 150px;
    min-width: 76px;
}

.mergedPreview th:first-child {
    border-left: 1px solid rgba(194, 201, 209, 0.5) !important;
}

.mergedPreview th:last-child {
    border-right: 1px solid rgba(194, 201, 209, 0.5) !important;
}

.bottomBar {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
}

.hoverOverflow {
    position: absolute;
    left: 0;
    width: 100%;
    top: -25px;
}

.bottomBar button {
    width: 140px;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    background: transparent;
}

.bottomBar .bottomBarButton {
    background: #3b83c9;
}

span.css-1okebmr-indicatorSeparator {
    display: none;
}

.previewNull {
    text-align: center;
    height: 62px;
    display: block;
    width: 100%;
    background: #fff;
    border-radius: 6px;
    margin: 0;
    font-size: 14px;
    color: #555;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    display: flex;
    justify-content: center;
    align-items: center;
}

.previewTable {
    width: 100%;
    margin-inline: auto;
    margin-block: 15px;
    max-height: 300px;
    border: 1px solid #DCDCDC;
    background-color: white;
}

.dragableTable {
    width: 100%;
}

.dragableTable td {
    padding: 5px 10px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
    text-overflow: ellipsis;
    border-bottom: 1px solid #eee;
}

.dragableTableHeader {
    padding: 5px 10px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 30px;
    border-bottom: 1px solid #eee;
    max-width: 200px;
    min-width: 87px;
}

.previewColumn {
    vertical-align: top;
    padding-top: 15px;
    height: fit-content;
    min-width: 44px;
    padding: 0;
    position: relative;
}

.customHdng h2 {
    font-size: 18px;
    font-weight: normal;
}

.selectedVariabls {
    padding: 0 25px;
}

.mergeTypeSelector {
    display: flex;
    align-items: center;
    margin-right: 25px;
    padding-right: 25px;
    border-right: 1px solid #d5d5d5;
}

.mergeSecWrapper {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 0;
}

.mergeSecContent {
    display: flex;
    padding: 18px;
    min-height: 50px;
    background-color: var(--greyColor);
    border-radius: 6px;
}

.dropletItem {
    width: 100%;
    padding-right: 0px;
}

.mainMergeSec {
    background: var(--greyColor);
    padding-bottom: 0;
    border-radius: 6px;
}

.dropCustombx {
    display: flex;
    padding-right: 20px;
    width: 100%;
    justify-content: space-between;
}

.mergeSecColumn {
    width: 100%;
}

.mergeColumn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

span.mergeIcon {
    height: 37px;
    display: block;
    padding-top: 5px;
    margin-bottom: 19px;
}

.mergefild {
    width: fit-content;
    border: 1px solid #d1d1d1;
    padding: 7px 10px;
    font-size: 15px;
    color: #303030;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 50px;
    margin-right: 10px;
    background: #d4e3f3;
    margin-block: 2px;
}

.removeBtn {
    border: 0;
    font-size: 25px;
    line-height: 2px;
    width: 13px;
    height: 13px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;
    border: none;
    background: transparent;
    margin-left: 15px;
}

.dropCustombx2 .dragItems {
    width: 98%;
    margin-right: 40px;
}

.dropCustombx2 {
    width: 100%;
    display: flex;
    padding-right: 0;
}

.dragItems {
    width: 98%;
    margin-right: 40px;
}

.mergeimg {
    width: 32px;
    text-align: center;
    display: block;
    height: 37px;
}

.mergeSecColumn h4 {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
}

.mergeRow {
    margin-bottom: 20px;
}

.mergeRow:last-child {
    margin-bottom: 0;
}

.mergeTypeSec {
    border-top: 1px solid #d1d1d1;
    padding: 20px;
    text-align: center;
}

.mergeColmSec {
    display: flex;
    justify-content: space-between;
    width: 45%;
    margin: 0 auto;
}

#mergeinner {
    margin: 0 0 0 10px;
}

#mergeMissing {
    margin: 0 0 0 10px;
}


.table {
    border: 1px solid grey;
    width: 100%;
}

.table td {
    border: 1px solid grey;
    text-align: center;
}
