.container {
    background: rgba(246, 246, 246, 0.95);
    /* Drop shadow */

    box-shadow: 0px 2px 6px 2px rgba(44, 44, 44, 0.12);
    border-radius: 6px;
    padding-top: 10px;
    padding-left: 158px;
    height: 100%;
    width: 100%;
}

.topLabel {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.05em;

    color: #75869a;
}

.topContainer {
    display: flex;
}
.searchContainer {
    margin-top: 21px;
    display: flex;
    flex-wrap: wrap;
}

.searchInput {
    width: 295px;
    height: 37px;
    background: #ffffff;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    outline: none;
    display: flex;
    align-items: center;
    padding-left: 5px;
    color: rgba(51, 51, 51, 0.7);
}

.searchInput::placeholder {
    color: #666666b2;
}

.publishButton {
    border: none;
    outline: none;
    width: 86px;
    height: 37px;
    background: #ffffff;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    color: rgba(51, 51, 51, 0.7);
}

.publishButton:hover {
    background: #828282;
}

.publishButton:active {
    background: #a1a1a1;
}

.publishButton:disabled {
    opacity: 0.5;
}

.cancelButton {
    border: 1px solid #e0e0e0;
    outline: none;
    background: white;
    border-radius: 6px;
    padding: 0 20px;
    height: 40px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #3b82c9;
    position: absolute;
    bottom: 30px;
    right: 95px;
}

.cancelButton:disabled {
    opacity: 0.5;
}

.backButton {
    border: 1px solid #d1d1d1;
    border-radius: 5.51724px;
    background: #ffffff;
    border-radius: 6px;
    width: 158px;
    height: 40px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #606060;
}

.backButton:hover {
    background: #bbbbbb;
}

.backButton:active {
    background: #eeeeee;
}

.backButton:disabled {
    opacity: 0.5;
}

.loadButton {
    border: none;
    outline: none;
    background: #3b82c9;
    border-radius: 6px;
    width: 158px;
    height: 40px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
}

.loadButton:hover {
    background: #11508f;
}

.loadButton:active {
    background: #639cd4;
}

.loadButton:disabled {
    opacity: 0.5;
}

.popupBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff88;
}

.popupContainer {
    position: fixed;
    background-color: transparent;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.previewContainer {
    padding: 20px 35px 20px 35px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border: 1px solid rgba(245, 245, 245, 0.5);
    border-radius: 10px;
    filter: drop-shadow(0px 0px 9px rgba(145, 145, 145, 0.4));
    display: flex;
    flex-direction: column;
}

.previewTitle {
    margin-bottom: 10px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #333333;
}

.tableContainer {
    box-shadow: 2px 0px 7px rgba(4, 11, 20, 0.12), inset 1px 1px 0px #e9e9e9,
        inset -1px 0px 0px #e9e9e9;
    border-radius: 6px;
}

.previewDescription {
    margin-bottom: 48px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #7c7c7c;
}

.bottomPopupBar {
    display: flex;
    align-items: center;
    margin-top: 25px;
    justify-content: flex-end;
}

.textItem {
    padding-left: 3px;
    margin-bottom: 11px;
    color: #333333b2;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
}

.keywordsItem {
    color: #33333380;
}

.datasetsContainer {
    display: flex;
    width: 100%;
    margin-top: 20px;
    flex-direction: column;
}

.datasetSelector {
    width: 270px;
}

.keywordInputContainer {
    display: flex;
}

.keywordsContainer {
    margin-top: 7px;
    display: grid;
    row-gap: 8px;
    column-gap: 30px;
    grid-template-columns: repeat(3, 100px);
}

.bottomVariableInfoBar {
    display: flex;
    align-items: center;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    color: #4e80c3;
}

.bottomVariableInfoHover {
    background: #fbfbfb;
    border: 1px solid #e4e4e4;
    box-shadow: 0px 0px 9px rgba(89, 89, 89, 0.4);
    border-radius: 7px;
}

.datasetInfo {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 10px;
}
