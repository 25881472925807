.datasetsContainer {
    display: flex;
    width: 100%;
    margin-top: 5px;
}

.datasetSelector .flex-simple-column {
    padding: 0;
    margin-top: 25px;
}

.dataset {
    width: 50%;
}

.mergType {
    display: flex;
    width: auto;
    align-items: center;
    margin-right: 30px;
    padding-right: 30px;
    border-right: 1px solid #aaa;
}

.dropMissingContainer {
    margin-top: 0;
    width: auto;
    display: flex;
}

.dropMissingItem {
    display: flex;
    align-items: center;
}

.textItem {
    padding-left: 3px;
    color: #555;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    width: auto;
    margin-right: 15px;
}

.variablesContainer {
    margin-top: 22px;
    display: flex;
    flex-direction: column;
    width: 610px;
    margin-left: 40px;
}

.mergeContainer {
    background: #ffffff;
    border: 1px solid #d1d1d1;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-radius: 4px;
    height: 35px;
    padding-left: 1px;
    padding-right: 1px;
    width: fit-content;
}

.mergeButton {
    border-radius: 2px;
    height: 31px;
    width: 94px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: none;
    outline: none;
    color: rgba(51, 51, 51, 0.7);
}

.mergeAndTargetContainer {
    margin-top: 0;
    display: flex;
    margin-left: 0;
    padding-top: 20px;
    background: var(--greyColor);
    justify-content: center;
    padding-bottom: 20px;
    align-items: center;
    border-top: 1px solid #d5d5d5;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}


.previewContainer {
    background: var(--greyColor);
    border: 1.46823px solid #d4d4d4;
    border-radius: 8.80937px;
    width: 100%;
    min-height: 200px;
    max-width: 100%;
    padding: 10px 15px;
}


.previewContainer .col-sm-12 {
    padding: 0;
}

.previewContainer .row {
    margin: 0px
}

.mergedTableWrapper {
    background: var(--greyColor);
    width: 100%;
    max-width: 100%;
    overflow: auto;
}

.borderLine {
    height: 1px;
    background-color: #e8e8e8;
    margin-left: 22px;
    margin-right: 30px;
}

.previewbtnContainer {
    margin-block: 20px;
    display: flex;
    justify-content: center;
}


.previewbtnContainer button {
    width: 200px;
    padding: 8px 10px;
}

.mergedPreview th {
    border-left: 0 !important;
}

.mergedPreview th>div>div {
    margin-right: 0 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 20px;
    max-width: 150px;
    min-width: 76px;
}

.mergedPreview th:first-child {
    border-left: 1px solid rgba(194, 201, 209, 0.5) !important;
}

.mergedPreview th:last-child {
    border-right: 1px solid rgba(194, 201, 209, 0.5) !important;
}


.bottomBarButton {
    background: #3b83c9;
    color: #fff;
    width: 200px;
    padding: 8px 10px;
    border-radius: 5px;
    border: 0;
}

.bottomBarButton:hover {
    background: #11508f;
}

.bottomBarButton:active {
    background: #639cd4;
}

.bottomBarButton:disabled {
    opacity: 0.5;
}

.bottomBar {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
}

.bottomBar button {
    width: 140px;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    background: transparent;
}

.bottomBar .bottomBarButton {
    background: #3b83c9;
}

span.css-1okebmr-indicatorSeparator {
    display: none;
}

.hoverOverflow {
    position: absolute;
    left: 0;
    width: 100%;
    top: -17px;
    cursor: pointer;
    /* max-width: 300px; */
    margin: 0 0 0 0;
}

.previewTable {
    width: 99.8%;
    margin-inline: auto;
    max-height: 300px;
    min-height: 163px;
    background-color: white;
    margin-top: 24px;
    border-radius: 6px;
    outline: 1px solid #DCDCDC;
    margin: 1px;
}

.previewNullContainer {
    width: 95%;
    background-color: #fff;
    border-radius: 6px;
}

.previewNull {
    text-align: center;
    height: 62px;
    display: block;
    width: 100%;
    background: #fff;
    padding-top: 21px;
    border-radius: 6px;
    margin-right: 20px;
}

.hhhh {
    width: 100%;
    background: #fff;
    margin-top: 20px;
}

.dragableTable td {
    padding: 5px 10px;
    min-width: 75px;
    max-width: 300px;
    border-bottom: 1px solid var(--grid-border-color);
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 31px;
}


.dragableTable td .headerName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-inline: 5px;
    width: 100%;
}

.previewColumn {
    vertical-align: top;
    padding-top: 15px;
    height: fit-content;
    min-width: 44px;
    padding: 0;
    position: relative;
}

.customHdng p {
    font-size: 16px;
    font-weight: normal;
    margin-top: 10px;
}

.selectedVariabls {
    padding: 0 25px;
}


.mergeTypeSelector {
    display: flex;
    align-items: center;
    margin-right: 25px;
    padding-right: 25px;
    border-right: 1px solid #d5d5d5;
}

.mergeSecWrapper {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 0;
}

.mergeSecContent {
    display: flex;
    padding: 20px;
    min-height: 150px;
    background-color: var(--greyColor);
    border-radius: 6px;
}

.dropletItem {
    width: 98%;
    /* padding-right: 20px; */
}

.mainMergeSec {
    background-color: var(--greyColor);
    padding-bottom: 50px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.dropCustombx {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.dropCustombx .previewNull {
    margin: 0;
    width: 100%;
}

.mergeSecColumn {
    width: 50%;
    min-width: 400px;
}

.mergeColumn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

span.mergeIcon {
    height: 37px;
    display: block;
    padding-top: 5px;
    margin-bottom: 19px;
}

.mergefild {
    width: 100%;
    border: 1px solid #d1d1d1;
    background: #fff;
    padding: 7px 10px;
    border-radius: 5px;
    font-size: 14px;
    /* color: black; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.removeBtn {
    background: transparent;
    font-size: 30px;
    line-height: 13px;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    color: #2a86e3;
    text-align: center;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    min-width: 20px;
    border: 1px solid lightgray;
}

.dropCustombx2 .dragItems {
    width: 98%;
    margin-right: 40px;
}

.dropCustombx2 {
    width: 100%;
    display: flex;
    padding-right: 0;
}

.dragItems {
    width: 98%;
    margin-right: 40px;
}

.mergeimg {
    width: 32px;
    text-align: center;
    display: block;
    height: 37px;
    margin-inline: 20px;
}


.mergeSecColumn h4 {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
}

.mergeRow {
    margin-bottom: 20px;
}

.mergeRow:last-child {
    margin-bottom: 0;
}

.mergeTypeSec {
    border-top: 1px solid #d1d1d1;
    padding: 20px;
    text-align: center;
}

.mergeColmSec {
    display: flex;
    justify-content: space-between;
    width: 45%;
    margin: 0 auto;
}

#mergeinner {
    margin: 0 0 0 10px;
}

#mergeMissing {
    margin: 0 0 0 10px;
}


.hoverShow {
    padding: 5px 15px 5px 15px;
    white-space: nowrap;
    position: absolute;
    bottom: 28px;
    right: -50px;
    background: rgba(0, 0, 0, 0.4);
    color: whitesmoke;
    border-radius: 4px;
    font-size: 13px;
}

.tooltip {
    padding-inline: 10px;
    padding-block: 5px;
    border: none;
}

.EditPopup {
    position: absolute;
    top: 26px;
    right: 0px;
    z-index: 1000;
    width: 223px;
    max-height: 170px;
    overflow-y: auto;
}

.editInput {
    border: none;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    background-color: transparent;
}

.dragableHeader {
    /* border: 2px solid rgb(59, 131, 201); */
    /* max-width: 300px; */
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
}

.scrollbar::-webkit-scrollbar-track {
    margin-top: 16px;
}