.block-style-fontsize-8 { font-size: 8px; }
.block-style-fontsize-9 { font-size: 9px; }
.block-style-fontsize-10 { font-size: 10px; }
.block-style-fontsize-11 { font-size: 11px; }
.block-style-fontsize-12 { font-size: 12px; }
.block-style-fontsize-14 { font-size: 14px; }
.block-style-fontsize-16 { font-size: 16px; }
.block-style-fontsize-18 { font-size: 18px; }
.block-style-fontsize-24 { font-size: 24px; }
.block-style-fontsize-30 { font-size: 30px; }
.block-style-fontsize-36 { font-size: 36px; }
.block-style-fontsize-48 { font-size: 48px; }
.block-style-fontsize-60 { font-size: 60px; }
.block-style-fontsize-72 { font-size: 72px; }
.block-style-fontsize-96 { font-size: 96px; }
