.container {
    padding: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
}

.container > * {
    flex: 1;
}

.title {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    color: #cad3e0;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
}

.chartContainer {
    display: flex;
    flex-direction: row;
}

.chartContainer > * {
    flex: 1;
}

.barAndTitleContainer {
    display: flex;
    height: 6em;
    flex-direction: column;
    align-items: center;
    gap: 3px;
}

.barTitle {
    font-family: Roboto;
    font-size: 16px;
    color: black;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
}

.barContainer {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.barLabel {
    position: absolute;
    bottom: 10px;
    width: 100%;
    font-family: Roboto;
    font-size: 16px;
    color: #cad3e0;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
}

.leftBar {
    width: 100%;
    background-color: #0f1f3a;
    border: 1px solid #cad3e0;
}

.rightBar {
    width: 100%;
    background-color: #4b5567;
    border: 1px solid #cad3e0;
}
