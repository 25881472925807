.root {
  padding: 20px;
  padding-bottom: 80px;
}

.switchField {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.switchLabel {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  margin-left: 10px;
}
