
.imgContainer {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    position: absolute;
    width: 100%;
    object-fit: cover;
}

.thumbnailPlaceholder {
	width: 100%;
	height: 138px;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media (max-width: 784px) {
  .tutorialInfoContainer {
    display: block;
  }

  .tutorialInfoContainerText {
    width: 100% !important;
  }

  .tutorialInfoContainer span {
    margin: 0 0 0 10px;
  }

  .moduleCardIframe {
    width: 100%;
  }
}
