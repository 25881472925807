.root {
  padding: 20px;
}

.title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.inputDropdown {
  margin-bottom: 5px;
}

.inputSelector {
  margin-top: 20px;
}
