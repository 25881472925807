.barContainer {
	background: var(--canvas-page-bar-background-color);
	box-shadow: 0px 0px 4px rgba(36, 46, 66, 0.3);
	border-radius: 0px;
	display: flex;
	height: 34px;
	align-items: center;
}

.buttonsStyle {
	width: 25px;
	height: 25px;
	background-color: transparent;
	color: var(--canvas-page-bar-text-color);
	border-width: 0;
	padding: 0;
	font-size: 10px;
}

.buttonsStyle:hover,
.buttonsStyle:active,
.buttonsStyle:focus,
.buttonsStyle:active:focus {
	background-color: transparent;
	color: var(--canvas-page-bar-text-color);
	border-width: 0;
	padding: 0;
	font-size: 10px;
}

.sectionName {
	white-space: nowrap;
	outline: none;
	border: none;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 12px;
	text-transform: uppercase;
	padding-top: 2px;
	padding-bottom: 2px;
	color: var(--canvas-page-bar-text-color);
	background-color: transparent;
}

.sectionNameActive {
	border-bottom: 1px solid #44556b;
}

.sectionContainer {
	display: flex;
	position: relative;
	justify-content: space-between;
	padding-left: 20px;
	padding-right: 20px;
	border-left-width: 1px;
	border-left-style: solid;
	border-left-color: var(--canvas-page-bar-divider-color);
}

.sectionContainerFirst {
	border-left: none;
}