

.modalContainer {
    z-index: 3;
    bottom: 0;
    height: 450px;
    width:600px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;    
    border-radius: 10px;
    display: "flex";
    flex-direction: "column";
    overflow: "visible";
    flex: 1;
    background-color: white;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    padding: 20px;
}

.header {
    font-weight: 700;
    font-size: 24px;
}

.subHeader {
    font-weight: 700;
    font-size: 20px;
}

.centralize {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}

.dropArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    border: 1px dashed #ddd;
}

.dropAreaText {
    color: #666565;
    font-size: 18px;
    font-weight: 700;
}

.downloadButton {
    position: absolute;
    top: 90px;
    right: 30px;
}