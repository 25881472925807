.root {
    padding: 20px;
}

.selectLabel {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    display: block;
    margin-bottom: 10px;
}

.colorContainer {
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.collapsableLine {
    padding: 10px 0;
    border-bottom: 1px solid #e9e9e9;
}

.lineButtons {
    display: flex;
    justify-content: space-between;
}

.expressionCollapseButton {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: rgba(51, 51, 51, 0.7);
    border: none;
    background: none;
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.expressionCollapseButton:hover {
    background: none;
    border: none;
}

.expressionCollapseButton:focus {
    background: none;
    border: none;
}

.expressionCollapseButton:active {
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
}

.expressionCollapseButton:active:focus {
    background: none;
    border: none;
    outline: none;
}

.expressionCollapseButtonIcon {
    width: 12px;
    height: 6px;
    transform: rotate(-90deg);
    transition: all 0.2s ease-out;
    margin-right: 10px;
}

.expressionCollapseButtonIcon > path {
    fill: #606060;
}

.chevronOpen {
    transform: rotate(0deg);
}

.switchField {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.switchLabel {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    margin-left: 10px;
}
