.expressionRow {
  margin-top: 10px;
}

.expressionCondition {
  display: flex;
  align-items: center;
}

.expressionConditionWrapper {
  border: 1px solid #D1D1D1;
  border-radius: 4px;
  padding: 2px;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
}

.expressionLink {
  text-align: right;
  margin-right: 25px;
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.conditionText {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #5C5C5C;
  margin-right: 6px;
  min-width: 23.5px;
  text-align: right;
}

.label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #5C5C5C;
  margin-left: 10px;
}

.input {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid transparent;
  flex: 1 1 auto;
}

.input:hover {
  border-color: rgb(204, 204, 204);
}

.chevronIcon {
  width: 12px;
  height: 6px;
  margin-right: 5px;
  transform: rotate(-90deg);
  transition: all .2s ease-out;
}

.chevronIcon > path {
  fill: #242124;
}

.chevronOpen {
  transform: rotate(0deg);
}

.addRemoveButton {
  width: 19px;
  height: 19px;
  padding: 0;
  background: none;
  border: none;
  color: #2B6299;
}

.addRemoveButton:hover {
  background: #D1D1D1;
  border: none;
}

.addRemoveButton:focus {
  background: #D1D1D1;
  border: none;
}

.addRemoveButton:active {
  background: #D1D1D1;
  border: none;
  outline: none;
  box-shadow: none;
}

.addRemoveButton:active:focus {
  background: #D1D1D1;
  border: none;
  outline: none;
}
