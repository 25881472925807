.contentWrapper {
  width: 100%;
  height: 100%;
  position:relative;
}

.micWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
} 

.spinner {
  width: 40px;
  height: 40px;
  padding-bottom: 15vh;
  position: relative;
}

.doubleBounce1, .doubleBounce2 {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(0, 173, 147, 1);
  opacity: 0.6;
  top: 0;
  left: 0;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.doubleBounce2 {
  animation-delay: -1.0s;
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
  }
}

.textAreaWrapper {
  box-sizing: border-box;
  padding: 10px;
  background-color: #7575756d;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.floatingWrapper {
  /*
  position: absolute;
  right: 5vw;
  bottom: 2.5vh;
  */  
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.btnWrapper {
  background-color: #7575756d;
  padding: 10px 15px 10px 15px;
  border-radius: 15px;
  opacity: 0.9;
}

.slickPromptMobile {
  text-align: left;
  width: 100%;
  padding-top: calc((var(--textarea-height))/2);
  padding-left: 20px;
  padding-right: 20px;  
  font-size: 18px;
  resize: none;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px; 
  background-color: rgba(255, 255, 255, 0.0);
  border: none;
  outline: none;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  font-family: inherit;
  color: white;
}

.slick-prompt-mobile::placeholder {
  vertical-align: middle;
  line-height: 1.5;
}

