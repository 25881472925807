.mainContainer {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    width: 218px;
    border: 1px solid #DBDBDB;
    border-radius: 6px;
    padding: 13px;
    z-index: 8;
}

.datasetsContainer {
    width: 50%;
    margin-top: 30px;
}

.datasetSelector {
    width: 305px;
    margin-left: 40px;
}

.variablesContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.bottomBarButton {
    border: none;
    outline: none;
    background: #3b82c9;
    border-radius: 6px;
    height: 26px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
}

.bottomBarButton:hover {
    background: #11508f;
}

.bottomBarButton:active {
    background: #639cd4;
}

.bottomBarButton:disabled {
    opacity: 0.5;
}

.editInput {
    font-family: Roboto;
    font-size: 16px;
    border: 1px solid #d1d1d1;
    background-color: white;
    outline: none;
    color: #666666;
    margin-bottom: 20px;
}

.decimalContainer input::placeholder {
    font-family: "Roboto";
}