.card {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    align-items: center;
    height: 174px;
    width: 204px;
    background-color: #fafafa;
    border: 1px solid #d2d6de;
    border-radius: 5px;
    position: relative;
    transition: transform .3s;
    cursor: pointer;
}

.card:hover {
    background-color: #d4ddfc;
    transform: scale(1.05);
}

.disabled {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    align-items: center;
    height: 174px;
    width: 204px;
    background-color: #fafafa;
    border: 1px solid #d2d6de;
    border-radius: 5px;
}

.footer {
    height: 30px;
    width: 100%;
    display: flex;
    background-color: #f1f3f5;
    align-items: center;
    color: #A6AEB6;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.title {
    padding-top: 20px;
    font-weight: 500;
    font-size: 22px;
    color: #5E6F7D;
}

.subtitle {
    font-size: 12px;
    color: #5E6F7D;
    font-weight: 300;
}

.statusCircle {
    margin-left: 18px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #A6AEB6;
}

.active {
    background-color: greenyellow;
}

.text {
    margin: 0px;
    font-size: 12px;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: rgba(250, 250, 250, .45);
}

.center {
    justify-content: center;
}