.mainContainer {
  background: #fff;
  margin: 15px 15px 26px 0;
  border-radius: 10px;
  width: 100%;
  padding-inline: 30px;
}

.datasetsContainer {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  /* border:1px solid red; */
}

.datasetsContainer>div {
  width: 50%;
}

.rowLevelContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.datasetSelector {
  width: 305px;
  margin-left: 40px;
}

.textItem {
  padding-left: 3px;
  margin-bottom: 11px;
  color: #555555;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
}

.variablesContainer {
  padding: 16px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  background-color: var(--greyColor);
}

.rowLevelSwitch {
  margin-inline: 10px;
  margin-top: 5px;
}

.borderLine {
  height: 1px;
  background-color: #e8e8e8;
  margin-left: 22px;
  margin-right: 30px;
}

.bottomBarButton {
  border: none;
  outline: none;
  background: #3b82c9;
  border-radius: 6px;
  width: 158px;
  height: 40px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.bottomBarButton:hover {
  background: #11508f;
}

.bottomBarButton:active {
  background: #639cd4;
}

.bottomBarButton:disabled {
  opacity: 0.5;
}

.bottomBar {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
  margin-right: 76px;
}

.editInput {
  font-family: Roboto;
  font-size: 16px;
  border: 1px solid #d1d1d1;
  background-color: white;
  outline: none;
  color: #666666;
  margin-bottom: 20px;
}

.previewContainer {
  background: var(--greyColor);
  border: 1.46823px solid #d4d4d4;
  border-radius: 8.80937px;
  width: 100%;
  min-height: 200px;
  max-width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mergedTableWrapper {
  background: var(--greyColor);
  width: 100%;
  max-width: 100%;
  overflow: auto;
  border-radius: 9px;
}

.mergedTableWrapper .row {
  margin: 0px;
}

.tooltip1 {
  width: 250px;
}

.textArea:focus {
  outline: none;
}

.customTooltip .__react_component_tooltip.type-dark.place-top:after {
  border-top-color: #d2ac2d;
  border-top-style: solid;
  border-top-width: 6px;
}

.pTable {
  width: 100%;
  text-align: center;
}

.pRow {
  border-bottom: 1px solid lightgrey;
  height: 33px;
  min-height: 33px;
}

.pRow td {
  padding: 6px;
}

.logics {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-bottom: 0;
}

.pOperator {
  width: 10%;
  text-align: center;
  font-size: 50px;
  margin-top: 8px;
}

.pTableWrapper {
  border: 1px solid lightgrey;
  border-radius: 6px;
  padding: 15px;
  width: 30%;
  max-height: 363px;
  height: fit-content;
  /* overflow-y: scroll !important;
    max-height: 240px; */
}

.pTableWrapper thead {
  height: 38px;
}

.variablesBox {
  align-items: center;
  margin-bottom: 10px;
  padding: 20px;
  background-color: white;
  border-radius: 6px;
  outline: 1px solid #DCDCDC;
}

.card {
  display: grid;
  grid-template-columns: 1fr 2fr;
  min-width: 200px;
  max-height: 65px;
  border: 1px solid grey;
  margin: 20px;
  border-radius: 6px;
  cursor: pointer;
  background-color: white;
}

.selectedCard {
  color: var(--acceptBtn-color);
  border-color: var(--acceptBtn-color);
  background-color: white;
}

.cardLabel {
  margin-block: 10px;
}

.cardLabel p {
  margin: 0px;
  font-size: 16px;
}

.cardIcon {
  margin: 0;
  font-size: 40px;
  color: #7e7c7c;
}

.valueBox {
  /* margin-left: 20px; */
  margin-top: 48px;
  width: calc(30% + 4px)
}

.valueBox input {
  text-align: left;
  width: 200px;
  border: none;
  padding: 8px;
  box-shadow: 0px 0px 2px grey;
  border-radius: 5px;
  outline: none;
}

.valueBox input::-webkit-inner-spin-button,
.valueBox input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.commonExamples {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-right: 5px;
  margin-top: 14px; 
  margin-bottom: 14px;  
}

.newColumnName {
  border: 1px solid lightgrey;
  text-align: center;
  margin-inline: auto;
  width: 100%;
  border-radius: 6px;
  max-width: 200px;
  display: flex;
  align-items: center;
}

.connector {
  position: absolute;
  bottom: 100%;
  height: 89px;
  width: 91%;
  left: 4%;
  border: 3px dashed #d7d7d7;
  border-bottom: 0;
  border-top: 0;
}

.newColumnName input {
  border: none;
  text-align: left;
  padding: 8px 12px;
  border-radius: 6px;
  outline: none;
  width: 86%;
}

.dropVariable {
  position: absolute;
  width: 100%;
  font-size: 25px;
  -webkit-transform: translate(70px, 30px);
  transform: translate(0px, 30px);
}

.valueBox .secondValue {
  font-size: 45px;
  font-weight: bold;
  border: 1px solid var(--acceptBtn-color);
  color: var(--acceptBtn-color);
  background-color: #ebf2f9;
  padding-inline: 7px;
}

.valueBox .secondUpdatedValue {
  font-size: 45px;
  font-weight: bold;
  border: none;
  color: #555555;
  opacity: 80%;
  background-color: transparent;
  padding-inline: 7px;
}

.defaultDropdown {
  border: 1px solid var(--acceptBtn-color);
  color: var(--acceptBtn-color);
  background-color: #ebf2f9;
}

.changedDropdown {
  border: none;
  color: #555555;
  background-color: transparent;
}

.logicalOperator {
  padding: 15px;
  width: 30%;
}

.squareBracket {
  border-left: 6px solid #7e7c7c;
  border-top: 6px solid #7e7c7c;
  border-bottom: 6px solid #7e7c7c;
  margin-right: 60px;
  margin-left: 20px;
  /* padding: 144px 10px; */
  height: 288px;
  width: 40px;
  margin-top: -8px;
}

.squareBracket1 {
  border-left: 6px solid #7e7c7c;
  border-top: 6px solid #7e7c7c;
  border-bottom: 6px solid #7e7c7c;
  /* padding: 144px 10px; */
  height: 288px;
  width: 40px;
  transform: rotate(180deg);
  margin-left: 20px;
}

.repeatInputStyle {
  margin-bottom: 6px;
  outline: none;
  border: none;
  border-bottom: 1px solid #e8e8e8;
  font-family: Roboto;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  line-height: 100%;
  align-items: Center;
  vertical-align: Center;
  text-align: center;
  padding: 10px;
}

.repeatInputStyle::placeholder {
  color: #333333 !important;
  opacity: 80%;
  font-weight: 300;
}

.repeatInputStyle::-webkit-inner-spin-button,
.repeatInputStyle::-webkit-outer-spin-button {
  -webkit-appearance: none;
}


.repeatInputStyle1 {
  margin-bottom: 6px;
  outline: none;
  border: none;
  font-family: Roboto;
  font-style: italic;
  font-size: 14px;
  line-height: 14px;
  line-height: 100%;
  text-align: center;
  background: none;
  margin-top: 5px;
  color: #333333cc;
  font-weight: 300;
}

.repeatOperator {
  font-family: Roboto;
  font-style: Bold;
  font-size: 40px;
  line-height: 47px;
  line-height: 100%;
  align-items: Left;
  vertical-align: Center;
  color: #7e7c7c;
}

.hideIcon::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  color: red;
  display: none;
}

.formInputStyle {
  max-height: 150px;
  overflow: auto;
}

.randomGeneratorInputStyle {
  margin-bottom: 15px;
  outline: none;
  border: none;
  border-bottom: 1px solid #e8e8e8;
  font-family: Roboto;
  font-style: bold;
  font-size: 16px;
  line-height: 16px;
  /* line-height: 100%; */
  align-items: Center;
  vertical-align: Center;
  text-align: center;
  padding-bottom: 5px;
  margin: 0
}

.uniformDist {
  width: 80%;
  background-color: #fff;
  height: 107px;
  margin-inline: auto;
  border: 2px solid #666666;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0px);
}

.uniformDistlower {
  width: 100%;
  margin: 0;
  border-bottom: 2px solid #666666;
  position: relative;
  height: 107px;
}

.uniformDistValuesBox {
  display: flex;
  justify-content: space-between;
}

.uniformDistValuesBox p {
  text-align: center;
  font-size: 22px;
}

.uniformDistInput {
  width: 60px;
  border: none;
  outline: none;
  border-bottom: 2px solid lightgray;
}

.uniformDistInput::placeholder {
  font-size: 22px;
}

.normalDistValuebox {
  width: 49px;
  border-bottom: 2px solid lightgray;
  position: absolute;
  top: 57%;
  left: 32%;
  height: 37px;
  background: transparent;
}

.normalDistValuebox input {
  background: transparent;
  width: 49px;
  height: 20px;
  font-size: 12px;
  padding: 2px;
  border: none;
  outline: none;
  margin: 0;
}

.normalDistValuebox p {
  font-size: 11px;
  margin: 0;
  text-align: center;
}

.normalDistImg {
  width: 300px;
}

.variablesContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--greyColor);
  padding: 14px;
  border-radius: 5px;
}

.variablesWrapper {
  background-color: #fff;
  border-radius: 5px;
}

.dropdownIcon {
  margin-top: 20px;
  margin-bottom: 5px;
  padding-top: 17px;
}

.scrollArrowLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 40px;
	height: 40px;
	background-color: rgba(0, 0, 0, 0.2);
	border: none;
	transition: all 0.1s linear;
	transform: rotate(-180deg);
  color:#797979;
}

.scrollArrowRight {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
	width: 40px;
	height: 40px;
	background-color: rgba(0, 0, 0, 0.2);
	border: none;
  color:#797979;
}

.scrollArrowLeft:hover {
  background-color: #242e42;
}

.scrollArrowRight:hover {
  background-color: #242e42;
}

.scrollableTabsWrapper:hover .scrollArrow {
	display: flex;
}

.scrollArrow:hover {
	background-color: rgba(0, 0, 0, 0.5);
	transition: all 0.1s linear;
}

.customFunctionWrapper{
  display: flex;
  width: 60%;
  justify-content: center;
  align-items: center;
  width: calc(70% + 40px)
}
