.root {
	margin-top: 10px;
}

.field {
	display: flex;
	margin-top: 10px;
	align-items: center;
}

.label {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: rgba(36, 33, 36, 0.8);
	margin-right: 5px;
	min-width: 95px;
}

.fieldSelect {
	flex-grow: 1;
}
