.defaultText {
	color: #333333;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
}

.optionName {
	color: #333333;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	min-width: 130px;
}

.sectionContainer {
	min-height: 50px;
	padding-left: 5px;
}

.optionContainer {
	margin-top: 20px;
	display: flex;
	align-items: center;
}

.verticalOptionContainer {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
}

.input {
	color: #333333;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	border: 1px solid #d1d1d1;
	outline: none;
	width: 56px;
	height: 35px;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.textInput {
	color: #333333;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	border: 1px solid #d1d1d1;
	outline: none;
	width: 100%;
	height: 35px;
	border-radius: 4px;
	padding-left: 10px;
	flex-shrink: 0;
}

.optionButton {
	width: 50%;
	height: 50px;
	border-radius: 5px;
	background-color: transparent;
	border: 2px solid #acacac;
	margin: 0 10px 0 0;
}

.optionButton.optionButtonActive {
	border: 2px solid rgb(59, 130, 201);
}

.additionalLayerConfigTitle {
	color: #333333;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	margin-top: 50px;
}
