table.dataTable {
    clear: both;
    empty-cells: show;
    /* margin-top: 6px !important; */
    margin-bottom: 1px !important;
    max-width: none !important;
    border-collapse: separate !important;
    background-color: white;
    min-height: 161px;
    /* border-radius: 8px; */
}

table {
    border-radius: 8px;
}

table.dataTable tr:hover {
    background-color: #e2f6ff;
}

table.dataTable td,
table.dataTable th {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
    text-align: center;
}

table.dataTable.nowrap th,
table.dataTable.nowrap td {
    white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length label {
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length select {
    width: 75px;
    display: inline-block;
}

div.dataTables_wrapper div.dataTables_filter {
    text-align: right;
}

div.dataTables_wrapper div.dataTables_filter label {
    font-weight: normal;
    white-space: nowrap;
    text-align: left;
}

div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0.5em;
    display: inline-block;
    width: auto;
}

div.dataTables_wrapper div.dataTables_info {
    padding-top: 8px;
    white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_paginate {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin: 2px 0;
    white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    margin-top: -26px;
    text-align: center;
    padding: 1em 0;
}

table.dataTable thead>tr>th.sorting_asc,
table.dataTable thead>tr>th.sorting_desc,
table.dataTable thead>tr>th.sorting,
table.dataTable thead>tr>td.sorting_asc,
table.dataTable thead>tr>td.sorting_desc,
table.dataTable thead>tr>td.sorting {
    padding-right: 30px;
}

table.dataTable thead>tr>th:active,
table.dataTable thead>tr>td:active {
    outline: none;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
    cursor: pointer;
    position: relative;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
    position: absolute;
    bottom: 8px;
    right: 8px;
    display: block;
    font-family: "Glyphicons Halflings";
    opacity: 0.5;
}

table.dataTable thead .sorting:after {
    opacity: 0.2;
    content: "\e150";
    /* sort */
}

table.dataTable thead .sorting_asc:after {
    content: "\e155";
    /* sort-by-attributes */
}

table.dataTable thead .sorting_desc:after {
    content: "\e156";
    /* sort-by-attributes-alt */
}

table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
    color: #eee;
}

div.dataTables_scrollHead table.dataTable {
    margin-bottom: 0 !important;
}

div.dataTables_scrollBody>table {
    border-top: none;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

div.dataTables_scrollBody>table>thead .sorting:after,
div.dataTables_scrollBody>table>thead .sorting_asc:after,
div.dataTables_scrollBody>table>thead .sorting_desc:after {
    display: none;
}

div.dataTables_scrollBody>table>tbody>tr:first-child>th,
div.dataTables_scrollBody>table>tbody>tr:first-child>td {
    border-top: none;
}

div.dataTables_scrollFoot>.dataTables_scrollFootInner {
    box-sizing: content-box;
}

div.dataTables_scrollFoot>.dataTables_scrollFootInner>table {
    margin-top: 0 !important;
    border-top: none;
}

@media screen and (max-width: 767px) {

    div.dataTables_wrapper div.dataTables_length,
    div.dataTables_wrapper div.dataTables_filter,
    div.dataTables_wrapper div.dataTables_info,
    div.dataTables_wrapper div.dataTables_paginate {
        text-align: center;
    }
}

table.dataTable.table-condensed>thead>tr>th {
    padding-right: 20px;
}

table.dataTable.table-condensed .sorting:after,
table.dataTable.table-condensed .sorting_asc:after,
table.dataTable.table-condensed .sorting_desc:after {
    top: 6px;
    right: 6px;
}

table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
    border-left-width: 0;
}

table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable td:last-child {
    border-right-width: 0;
}

table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
    border-bottom-width: 0;
}

div.dataTables_scrollHead table.table-bordered {
    border-bottom-width: 0;
}

div.dataTables_wrapper>div.row {
    margin: 0;
}

div.dataTables_wrapper>div.row>.col-sm-12 {
    padding: 0;
}


div.table-responsive>div.dataTables_wrapper>div.row>div[class^="col-"]:first-child {
    padding-left: 0;
}

div.table-responsive>div.dataTables_wrapper>div.row>div[class^="col-"]:last-child {
    padding-right: 0;
}

div.dataTables_length select {
    margin-left: 1em;
}

.pagination .page-item .page-link {
    border: 0.5px solid #a3a3a3;
    background-color: transparent;
    color: #98a7b9;
    font-family: Arial;
    font-size: 9px;
    cursor: pointer;
}

.pagination .page-item.active .page-link {
    background-color: #1f8efa;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination .page-item.active .page-link:focus {
    background-color: #000;
}

.pagination .page-item.active .page-link:hover {
    background-color: #000;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination .page-item.disabled {
    pointer-events: none;
}


.customized-table th {
    color: #fff;
    font-family: Arial;
    font-size: 12px;
}

.customized-table td {
    color: #70889e;
    font-family: Arial;
    font-size: 12px;
}

.customized-table tbody+thead {
    display: none;
}

.customized-table .dataTables_info,
.customized-table .dataTables_length {
    color: #98a7b9;
    font-family: Arial;
    font-size: 9px;
    padding-left: 30px;
}

.customized-table thead>tr>th {
    border: none;
}

.customized-table tbody>tr:not(:first-child)>td {
    border-top: 0.6px solid #20293c;
}

.customized-table tbody>tr:first-child>td {
    border: none;
}

.customized-table .custom-select {
    border: none;
    background-color: #20293c;
    color: #98a7b9;
    font-family: Arial;
    font-size: 9px;
    height: 25px;
    width: 43px;
}

.customized-link-table thead>tr>th {
    border: none;
}

.customized-link-table {
    border-top: 0.6px solid #20293c;
    border-bottom: 0.6px solid #20293c;
}

.customized-link-table tbody>tr:not(:first-child)>td {
    border-top: 0.6px dashed #20293c;
}

.customized-link-table tbody>tr:first-child>td {
    border: none;
}

.customized-link-table .no-border {
    border-top: none;
    border-bottom: none;
}

.processing-page-table th {
    color: #fff;
    font-family: Arial;
    font-size: 12px;
}

.processing-page-table td {
    color: #fff;
    font-family: Arial;
    font-size: 12px;
}

.processing-page-table tbody+thead {
    display: none;
}

.processing-page-table .dataTables_info,
.processing-page-table .dataTables_length {
    color: #98a7b9;
    font-family: Arial;
    font-size: 9px;
    padding-left: 30px;
}

.processing-page-table thead>tr>th {
    border: none;
}

.processing-page-table tbody>tr:not(:first-child)>td {
    border-top: 0.6px solid #20293c;
}

.processing-page-table tbody>tr:first-child>td {
    border: none;
}

.processing-page-table .custom-select {
    border: none;
    background-color: #20293c;

    color: #98a7b9;
    font-family: Arial;
    font-size: 9px;
    height: 25px;
    width: 43px;
}

.primary-page-table th {
    color: var(--primary-text-color);
    font-family: Arial;
    font-size: 12px;
}

.primary-page-table td {
    color: var(--primary-text-color);
    font-family: Arial;
    font-size: 12px;
}

.primary-page-table tbody+thead {
    display: none;
}

.primary-page-table .dataTables_info,
.primary-page-table .dataTables_length {
    color: var(--secodary-text-color);
    font-family: Arial;
    font-size: 9px;
    padding-left: 30px;
}

.primary-page-table thead>tr>th {
    border: none;
}

.primary-page-table tbody>tr:not(:first-child)>td {
    border-top: 0.6px solid #20293c;
}

.primary-page-table tbody>tr:first-child>td {
    border: none;
}

.primary-page-table .custom-select {
    border: none;
    background-color: #20293c;

    color: var(--secodary-text-color);
    font-family: Arial;
    font-size: 9px;
    height: 25px;
    width: 43px;
}

.processing-page-link-table thead>tr>th {
    border: none;
}

.processing-page-link-table {
    border-top: 0.6px solid #20293c;
    border-bottom: 0.6px solid #20293c;
}

.processing-page-link-table tbody>tr:not(:first-child)>td {
    border-top: 0.6px dashed #20293c;
}

.processing-page-link-table tbody>tr:first-child>td {
    border: none;
}

.processing-page-link-table .no-border {
    border-top: none;
    border-bottom: none;
}

.import-table th {
    color: black;
    /* font-family: "Open Sans"; */
    font-family: "roboto";
    font-size: 12px;
    font-weight: 400;
}

.import-table td {
    color: black;
    /* font-family: "Open Sans"; */
    font-family: "roboto";
    font-size: 14px;
    font-weight: 400;

    white-space: nowrap;
    overflow: hidden;
    max-width: 300px;
    text-overflow: ellipsis;
}

.import-table tbody+thead {
    display: none;
}

.import-table .dataTables_info,
.import-table .dataTables_length {
    color: #98a7b9;
    font-family: Arial;
    font-size: 9px;
    padding-left: 30px;
}

/* .import-table thead>tr {
    box-shadow: 0px 4px 4px #eeeeee;
} */

.import-table thead>tr>th:first-child {
    border-top-left-radius: 8px;
}

.import-table thead>tr>th {
    border-top: 1px solid rgba(194, 201, 209, 0.5) !important;
    border-left: 1px solid rgba(194, 201, 209, 0.5) !important; 
    border-bottom: 1px solid rgba(194, 201, 209, 0.5);

}

.import-table thead>tr>th>div>div {
    white-space: nowrap;
    overflow: hidden;
    max-width: 300px;
    text-overflow: ellipsis;
    font-size: 14px;
}

.import-table thead>tr>th:last-child {
    border-top-right-radius: 8px;
    border-right: 1px solid rgba(194, 201, 209, 0.5);
}

.import-table tbody>tr:not(:first-child)>td {
    border-top: 1px solid rgba(194, 201, 209, 0.5);
}

.import-table tbody>tr:first-child>td {
    border: none;
}

.import-table tbody>tr:last-child>td {
    border-bottom: 1px solid rgba(194, 201, 209, 0.5);
}

.import-table tbody>tr:last-child>td:first-child {
    border-bottom-left-radius: 8px;
}

.import-table tbody>tr:last-child>td:last-child {
    border-bottom-right-radius: 8px;
}

.import-table tbody>tr>td:first-child {
    border-left: 1px solid rgba(194, 201, 209, 0.5);
}

.import-table tbody>tr>td:last-child {
    border-right: 1px solid rgba(194, 201, 209, 0.5);
}

.import-table .custom-select {
    border: none;
    background-color: #ffffff;

    color: #98a7b9;
    font-family: Arial;
    font-size: 9px;
    height: 25px;
    width: 43px;
}


.import-table-nopadding th {
    padding: 0 !important;
}

.import-table-nopadding td {
    padding: 0 !important;
}

.scrollable-body-table tbody {
    display: block;
    max-height: 170px;
    overflow-y: scroll;
}

.scrollable-body-table thead,
.scrollable-body-table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.scrollable-body-table th {
    color: #000;
}



.importSectionPreviewTable .dataTables_info,
.importSectionPreviewTable .dataTables_length {
    color: #000;
    font-size: 14px;
    font-family: Roboto;
    padding-left: 0px;
}

.importSectionPreviewTable .dataTables_info {
    padding-top: 16px !important;
}

.importSectionPreviewTable .custom-select {
    font-size: 14px;
    width: 55px !important;
    padding: 3px;
    color: #4D5966 !important;
}
/* .importSectionPreviewTable .custom-select option:hover {
background-color: green !important;
} */

.importSectionPreviewTable thead th span {
    font-size: 14px !important;
}
.importSectionPreviewTable thead th #textstyleforContextMenu {
    font-size: 12px !important;
}

.importSectionPreviewTable thead th span span {
    font-size: 12px !important;
    color: #333333;
    font-weight: 400;
}

.importSectionPreviewTable div[data-test="table"] {
    overflow-x: scroll;
}


.importSectionPreviewTable .dataTables_paginate a {
    border: 1px solid #c2c9d180 !important;
    background-color: white !important;
    color: #000 !important;
    margin-inline: 3px !important;
    font-size: 14px !important;
    border-radius: 4px !important;

}

.importSectionPreviewTable .dataTables_paginate {
    padding-top: 8px !important;
}