.contentWrapper {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 74px !important;
    padding-bottom: 20px;
    background-color: #f6f6f6;
}

.contentSection {
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    background-color: var(--content-background-color);
    padding: 30px;
    margin-right: 0 !important;
}

.stripeConnectContainer {
    margin-top: 10px;
    margin-bottom: 37px;
}

.customerTable {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    gap: 10px;
    flex: 1;
    margin-top: 50px;
}

.customerHeaderRow,
.customerRow {
    display: grid;
    width: 100%;
    grid-template-columns:
        0.5fr /* user icon */
        1fr /* user name */
        1fr /* first name */
        1fr /* last name */
        0.5fr /* type */
        1.15fr /* app/kit subscribed */
        0.63fr /* price */
        1fr /* payment period */
        1fr /* date subscribed */
        1fr /* months subscribed */
        1fr /* total revenue */
        1fr; /* unsubscribed on */
    grid-column-gap: 20px;
    align-items: center;
}

.customerHeaderRow {
    position: sticky;
    top: 0;
}

.customerRow {
    background: #75869a1a;
    border-radius: 50px;
    border-width: 0px;
    height: 45px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.headerText {
    font-family: Roboto;
    font-size: 26px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #333333b2;
}

.customerHeaderText,
.noCustomersText {
    color: #333333b2;
    font-family: Roboto;
}

.noCustomersText {
    margin-top: 50px;
}

.avatar {
    width: 45px;
    height: 45px;
    font-size: 16pt;
}
