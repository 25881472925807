input[type="radio"] {
    transform: translate(0px, -15px);
    width: 29px;
    height: 30px;
}

.paragraph {
    font-weight: lighter;
    font-size: 20px;
    color: #5e5c5c;
    text-align: center;
    margin: 0;
}

.wrapper {
    background-color: var(--greyColor);
    padding-top: 40px;
    border-radius: 6px;
    margin-bottom: 25px;
    border: 1px solid #e1e1e1;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
    height: 350px;
    margin-top: 40px;
}

.box {
    border-radius: 10px;
    background-color: #f1f1f1;
    margin: 20px;
    border: 0.4px solid #ddd6dd;
}

.pre {
    font-weight: bolder;
}

.head {
    text-align: center;
    margin-top: 0;
    font-weight: 600;
    font-size: 28px;
}

.box img {
    height: 240px;
    width: 250px;
    transform: translate(0px, -25px)
}

.box h3 {
    transform: translate(0px, -80px);
    font-size: 25px;
    color: #5e5c5c;
    font-weight: 500
}