.datasetsContainer {
    display: flex;
    width: 100%;
    margin-top: 30px;
}

.textItem {
    padding-left: 3px;
    margin-bottom: 11px;
    color: #555555;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
}

.variablesContainer {
    margin-top: 22px;
    display: flex;
    margin-right: 20px;
    flex-direction: column;
}

.transformContainer {
    background: #ffffff;
    border: 1px solid #d1d1d1;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-radius: 4px;
    height: 35px;
    padding-left: 1px;
    padding-right: 1px;
    width: fit-content;
}

.transformButton {
    border-radius: 2px;
    height: 31px;
    width: 94px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: none;
    outline: none;
    color: rgba(51, 51, 51, 0.7);
}

.editInput {
    height: 38px;
    font-family: Roboto;
    font-size: 16px;
    border: 1px solid #d1d1d1;
    background-color: white;
    outline: none;
    color: #666666;
    margin-bottom: 20px;
}



.transformButton:hover {
    background-color: #d1d1d1;
}

.transformButtonSelected {
    background-color: var(--acceptBtn-color) !important;
    color: #ffffff;
}

.transformAndTargetContainer {
    margin-top: 22px;
    display: flex;
}

.previewContainer {
    background: #ffffff;
    border: 1.46823px solid #d4d4d4;
    box-sizing: border-box;
    border-radius: 8.80937px;
    width: 70%;
    min-height: 200px;
    max-width: 70vw;
}

.borderLine {
    height: 1px;
    background-color: #e8e8e8;
    margin-left: 22px;
    margin-right: 30px;
}

.bottomBarButton {
    border: none;
    outline: none;
    background: #3b82c9;
    border-radius: 6px;
    width: 158px;
    height: 40px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
}

.bottomBarButton:hover {
    background: #11508f;
}

.bottomBarButton:active {
    background: #639cd4;
}

.bottomBarButton:disabled {
    opacity: 0.5;
}

.bottomBar {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    margin-right: 76px;
}

.variableContainer {
    padding: 15px;
    background-color: var(--greyColor);
    height: 200px;
    border-radius: 6px;
    font-size: 16px;
    /* opacity: 0.5; */
}

.scrollable {
    max-height: 200px;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 2px;
    height: 100%;
    border-radius: 6px;
    overflow: auto;
    border: 1px solid #dcdcdc;
}

.variableColumnHeader {
    border: none;
    height: 25px;
    background-color: transparent;
    width: 100%;
    outline:0
}
.variableColumnHeader:focus
{
     outline:  2px solid #2684ff !important; 
     border-radius: 4px;
 } 

.dropVariable1 {
    margin-right: 5px;
    width: 25%;
    height: 100%;
}

.dropVariable2 {
    width: 75%;
    height: 100%;
}

.dropedVariableNull {
    min-height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.dropletItem {
    display: flex;
    /* overflow: auto; */
    height: 100%;
}


.headerTableContainer {
    height: 100%;
}

.headerTable {
    width: 100%;
    height: 100%;
}

.bodyTableContaner {
    height: 100%;
}

.bodyTable {
    width: 100%;
    height: 100%;
}

.indexBody {
    white-space: nowrap;
    max-width: 110px;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 6px 15px;
    font-size: 14px;
    border: none;
}


.tableRow {
    border-bottom: 1px solid var(--greyColor);
    height: 45px;
}

.previewLeftTable {
    width: 100%;
    position: relative;
}

.previewLeftTable>thead {
    height: 45px;
}

.previewRightTable {
    width: 100%;
    height: 100%;
    position: relative;
    min-height: 166px;
}

.previewRightTable>thead {
    height: 45px;
}

.previewRightTable>tbody {
    height: 100%;
}

.dropIndicator1 {
    text-align: center;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.dropIndicatorHighlighted1 {
    /* color: #0B7FB0;
    background: rgba(59, 130, 201, 0.1); */
    font-size: 18px;
    text-align: center;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropIndicator {
    font-size: 18px;
}

.border_dashed {
    border: 2px dashed #0B7FB0 !important;
}

.dropIndicatorHighlighted {
    /* color: #0B7FB0; */
    /* border:2px dashed  #0B7FB0; */
    background: rgba(59, 130, 201, 0.1);
    font-size: 18px;
}

.borderClass {
    border: 2px dotted grey
}

.dropOvershow {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -100%);
    font-size: 18px;
    text-align: center;
}

.dropOvershow h1 {
    text-align: center;
}

.dropOvershow span {
    text-align: center;
    font-size: 30px;
}

.Manish {
    height: 191px;
    opacity: 0.5;
}

.Manish>div {
    height: 163px;
}

.Manish>div::-webkit-scrollbar-track {
    margin-top: 0;
}

.opa_font_unpivot {
    opacity: 0.5;
    font-size: 18px;
}

.unpivotbox {
    height: 272px;
}

.unpivotTable1 {
    width: 100%;
    height: 100%;
}

.unpivotTable2 {
    width: 100%;
    height: 100%;
}

.flex-simple-column.messagePopupContainer {
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: inherit;
}

.messagePopupContainer {
    flex-grow: 1;
    box-shadow: 0px 0px 2px 0px grey;
    border-radius: 15px;
    height: 100%;
    padding: 0 !important;
}

.content {
    font-family: Roboto;
    font-style: normal;
    font-size: 18px;
    font-weight: 300;
    color: #242124;
    margin-inline: 0;
    align-self: baseline;
    text-align: left;
    max-height: 370px;
    overflow: auto;
    width: 100%;
    min-height: 53px;
    margin-bottom: 5px;
    margin-left: 0;
    padding-left: 0;
    padding-bottom: 15px;
}

.datasetSelector{

}




















.previewTable {
    width: 100%;
    margin-inline: auto;
    max-height: 300px;
    min-height: 163px;
    background-color: white;
    border-radius: 10px;
}

.strawmanRow {
    width: 100%;
    position: relative;
    outline-offset: -2px;
}

.strawmanHeaderRow {
    width: 100%;
    outline-offset: -2px;
    position: sticky;
    /* top: -1px; */
    top: 0;
    z-index: 1;
    background-color: #fff;
}

.strawmanData {
    padding: 5px 20px;
    min-width: 75px;
    max-width: 300px;
    border-bottom: 1px solid var(--grid-border-color);
    /* display: flex; */
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 31px;
}

.strawmanHeaderData {
    padding: 5px 20px;
    min-width: 75px;
    max-width: 300px;
    border-bottom: 1px solid var(--grid-border-color);
    /* display: flex; */
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 31px;
}

.strawmanWrapper {
    border: 1px solid #DCDCDC;
}
.dataHubButton {	
    border-radius: 2px;	
    height: 31px;	
    width: 94px;	
    font-family: Roboto;	
    font-style: normal;	
    font-weight: 400;	
    font-size: 16px;	
    line-height: 19px;	
    display: flex;	
    align-items: center;	
    justify-content: center;	
    background-color: white;	
    border: none;	
    outline: none;	
    color: rgba(51, 51, 51, 0.7);	
}
.dataHubButton:hover {	
    background-color: #d1d1d1;	
    color: #000;	
}	
.dataHubButton:active {	
    background-color: #d1d1d1;	
    color: #000 !important;	
}	
.dataHubButtonSelected {	
    background-color: var(--acceptBtn-color) !important;	
    color: #ffffff !important;	
}
