.toggleButton {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #262E40;
	background: none;
	border: none;
	padding: 1rem 1.2rem;
	display: flex;
	align-items: center;
}

.toggleButton:hover {
	background: none;
	color: #262E40;
}

.toggleButton:active {
	background: none;
}

.toggleButton:focus {
	background: none;
	color: #262E40;
}

.toggleButton:active:focus {
	background: none;
	color: #262E40;
	outline: none;
	box-shadow: none;
}

.chevronIcon {
	margin-left: 17px;
}

.chevronIcon > path {
	fill: #262E40;
}

.item {
	display: block;
	width: 100%;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #262E40;
	background: none;
	border: none;
	margin-bottom: 5px;
}
