.root {
	border: none;
	background: #ffffff;
	/* padding: 0; */
	width: 100%;
}

.root:focus {
	background: #f6f6f6;
	border: none;
	background: none;
}

.root:active {
	border: none;
	background: #f6f6f6;
	outline: none;
	box-shadow: none;
}

.root:hover {
	border: none;
	background: #f6f6f6;
}

.root:active:focus {
	border: none;
	background: #f6f6f6;
	outline: none;
}