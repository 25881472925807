.container {
	display: flex;
	flex-direction: column;
	padding: 30px;
	bottom: 80px;
	width: 350px;
	position: absolute;
	z-index: 999;
	background: #ffffff;
	box-shadow: 0 2px 14px rgb(0 0 0 / 15%), 0 0 0 0.5px rgb(0 0 0 / 20%);
}
