.root {
	width: 300px;
	height: 200px;
	border-radius: 6px;
	filter: drop-shadow(0px 2px 8px rgba(38, 46, 64, 0.08));
	background: #fff;
	position: relative;
	cursor: pointer;
}

.badge {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	left: 12px;
	top:  12px;
	width: 99px;
	height: 18px;
	background: #f4c468;
	border-radius: 4px;
	font-family: Roboto;
	font-style: normal;
	font-weight: 300;
	font-size: 9px;
	line-height: 11px;
	color: #ffffff;
}

.image {
	object-fit: contain;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	border: 1px solid #e9ecf0;
	width: 100%;
	height: 138px;
	object-fit: cover;
}

.thumbnailForeground {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 138px;
	background: linear-gradient(0deg, rgba(255,255,255,1) 3%, rgba(0,0,0,0) 50%);
}

.thumbnailPlaceholder {
	width: 100%;
	height: 138px;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.titleThumbnail {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	margin: 0;
}

.content {
	padding: 1.4rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
}

.leftSide {
	display: flex;
	align-items: center;
}

.icon {
	margin-right: 1.5rem;
	width: 18px;
	height: 18px;
	opacity: 0.5;
	flex-shrink: 0;
}

.title {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;
	margin: 0;
	margin-bottom: 0.4rem;
	max-width: 200px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.updatedAt {
	margin: 0;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
}

.avatar {
	flex-shrink: 0;
}

.deleteButton {
	padding: 0;
	border: none;
	background: none;
	position: absolute;
	top: 10px;
	right: 10px;
}

.deleteButton:focus {
	outline: none;
}

.deleteButton:hover {
	opacity: 0.7;
}

.deleteIcon {
	width: 25px;
	height: 25px;
}
