.valueContainer {
	padding-left: 4px;
	height: 24px;
	display: flex;
	align-items: center;
	border: none;
	border-top: 1px solid #d1d1d1;
	border-right: 1px solid #d1d1d1;
}
.value {
	font-size: 14px;
	color: #333333;
	font-family: Roboto;
	outline: none;
	border: none;
	overflow: hidden;
	background: transparent;
	width: 100%;
}