.text {
	backdrop-filter: blur(4px);
	color: #98a7b9;
	font-family: "Roboto";
	font-size: 10;
}

.btnText {
	color: #3B82C9;
	font-family: "Roboto";
	font-size: 10;
}

.text:hover {
	color: #39f;
}

.backgroundButton {
		backdrop-filter: blur(4px);
		border: 1px solid #3B82C9;
		margin-right: 190px;
		width: 30vw;
		color: #3B82C9;
		text-align: center;
		padding: 10px;
		border-radius: 3px;
		color: #3B82C9;
		cursor: pointer;
}

.backgroundButton:hover {
	background: rgba(114, 137, 159, 0.1);;
}

.center {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
}

.fullScreenButton {
	margin-right: 20px;
	width: 45px;
	height: 40px;
	font-size: 30px;
	color: #fff;
	border: none;
	background: transparent;
	transition: 300ms transform ease;
	transform: scale(1);
	outline: none;
}

.fullScreenButton svg path {
	stroke: #8c8c8c;
	fill: #8c8c8c;
}

.fullScreenNotActive {

}
.fullScreenNotActive:hover {
	transform: scale(1.25) !important;
}
.fullScreenNotActive:active {
	transform: scale(1.1) !important;
}
.fullScreenActive {
	
}
.fullScreenActive:hover {
	transform: scale(0.75) !important;
}

.fullScreenActive:active {
	transform: scale(0.9) !important;
}

.watermark {
	display: flex;
	align-items: center;
}

.liveModeTitle {
	font-family: Roboto;
	font-style: normal;
	font-weight: 300;
	line-height: 18px;
	color: #999999;
	margin: 0;
	opacity: 0.3;
	margin-left: 10px;
}

.liveModeLogo {
	opacity: .3;
}

