.container {
	height: 100%;
	width: 100%;
	overflow: hidden;
}

.editModeCameraInnerContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}

.cameraIcon {
	width: 30%;
	height: auto;
}

.cameraContainer {
	position: relative;
	border-bottom: 2px solid #E9ECF0;
}

.liveDataContainer {
	margin: 10px 0;
	padding: 0 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.liveDataInput {
	font-family: Roboto;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	width: 80%;
}

.buttonsContainer {
	margin: 10px 0;
	display: flex;
	justify-content: center;
}

.buttonsContainer > button {
	margin-right: 10px;
}

.buttonsContainer > button:last-child {
	margin: 0;
}

.button {
	color: white;
	background-color: var(--secondary-content-color);
	border: none;
	text-transform: capitalize;
	border-radius: 3px;
	padding: 5px 20px;
	cursor: pointer;
}

.button:disabled {
	opacity: .6;
	pointer-events: none;
}

.button:hover {
	background: #00b3db;
}

.screenshot {
	opacity: .5;
	width: 100%;
	height: 100%;
}

.screenshotSuccessIcon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 20%;
	height: auto;
	opacity: .8;
	fill: green;
}
