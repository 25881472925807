.container {
	display: flex;
	flex-direction: row;
	padding: 3px;
	justify-content: center;
	align-items: center;
	position: absolute;
	left: 50%;
	bottom: 80px;
	background: linear-gradient(
		90.2deg,
		#323232 49.83%,
		rgba(50, 50, 50, 0.9) 100%
	);
	border-radius: 3px;
	z-index: 999;
}

.itemContainer {
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 2px;
	padding-bottom: 2px;
	width: 100%;
	display: flex;
	align-items: center;
	border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.btnCanvasAlignment {
	cursor: pointer;
	min-width: 20px;
	min-height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.btnCanvasAlignment:hover {
	background-color: #cccccc;
}

.btnCanvasAlignment:active {
	background-color: #aaaaaa;
}

.labelCanvasAlignment {
	cursor: default;
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 9px;
	line-height: 11px;
	color: #d8d8d8;
}

.inputCanvasAlignment {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 9px;
	line-height: 11px;
	text-align: center;
	color: #ffffff;
	background: rgba(255, 255, 255, 0.1);
	border-radius: 2px;
	outline: none;
	border: none;
}

.formulaEditor {
	text-align: left;
	font-size: 12px;
	line-height: 14px;
}