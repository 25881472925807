.container {
	overflow: auto;
	width: 300px;
	background: linear-gradient(
		132.57deg,
		#323232 82.05%,
		rgba(38, 46, 64, 0) 229.42%
	);
	/* box-shadow: 0 2px 14px rgb(0 0 0 / 15%), 0 0 0 0.5px rgb(0 0 0 / 20%);*/
	display: flex;
	flex-direction: column;
	height: 100%;
}

.mainTextStyle {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 14px;
	line-height: 16px;
	color: #fafcff;
	letter-spacing: 0.08em;
	/*padding: 10px;*/
	padding: 5px 15px 10px;
	font-family: Roboto;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
