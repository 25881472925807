.innerContainer {
  display: flex;
  align-items: center;
  padding: 3px 0;
  margin: 2px;
  border-radius: 6px;
  padding: 5px 20px;
  justify-content: flex-start;
  font-size: 12px;
  color: black;
  text-transform: capitalize;
}

.innerContainer:hover {
  background-color: lightgray;
  font-weight: 500;
}

.iconWrapper {
  margin-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconWrapper > svg > path {
  fill: currentColor;
}
