.radioButtonsGroup {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 5px 20px 5px 20px;
    overflow: auto
}

.radioButton {
    display: flex;
    width: 100%;
    border: 1px solid var(--secondary-content-color);
    margin: 0 0 10px 0;
    position: relative;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
}

.radioButton:focus-visible {
    outline: none;
}

.radioButton.active {
    border: 2px solid #629BD4;
}

.radioButton:last-child {
    margin: 0;
}

.radioButton p {
    margin: 0;
}

.radioButton:hover {
    background-color: rgba(4, 69, 175, 0.3);
}

.radioButtonKeyLabel {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--secondary-content-color);
    margin: 0 10px 0 0;
    background-color: #fff;
    border-radius: 2px;
}

.radioButtonKeyLabel.active {
    background-color: var(--secondary-content-color);
    color: #fff;
}

.checkIcon {
    display: flex;
    align-items: center;
}

.checkIcon.iconChoiseTheme::before {
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 24px;
    border-image: none 100% / 1 / 0 stretch;
    border-color: var(--secondary-content-color) var(--secondary-content-color) transparent transparent;
    position: absolute;
    right: 0px;
    top: 0px;
}

.checkIcon.iconChoiseTheme svg {
    z-index: 9999;
    width: 20px;
    height: 20px;
}

.radioButtonIcon,
.radioButtonIcon img {
    width: 100%;
    height: auto;
}
