.root {
  margin: 15px 0;
}

.tooltipArea {
  height: 280px;
  background: #F6F6F6;
  border: 1.95477px dashed #D1D1D1;
}

.tooltipEdit {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
