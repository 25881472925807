/*.container {
    display: flex;
    align-items: center;
    overflow: auto;
    z-index: 100000000;
    position: fixed;
    top: 0;
    left: 0;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(0, 0);
    -webkit-filter: drop-shadow(0px 8px 20px rgba(38, 46, 64, 0.12));
    filter: drop-shadow(0px 8px 20px rgba(38, 46, 64, 0.12));
    border-radius: 10px;
    background: rgba(0, 0, 0, 15%);
     border: 1px solid grey;
    width: 100% !important;
    height: 100% !important;
} */
.container {
    display: flex;
    align-items: center;
    overflow: auto;
    z-index: 999;
    position: fixed;
    top: 54%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    /* -webkit-filter: drop-shadow(0px 8px 20px rgba(38, 46, 64, 0.12)); */
    /* filter: drop-shadow(0px 8px 20px rgba(38, 46, 64, 0.22)); */
    border-radius: 8px;
    background: #fff;
    max-width: 600px !important;
    /* outline: 1px solid #d7d7d7; */
}

.flex-simple-column.messagePopupContainer {
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: inherit;
}

.messagePopupContainer {
    flex-grow: 1;
    box-shadow: 0px 0px 2px 0px grey;
    border-radius: 7px;
    border: 1px solid #d1d1d1;
    height: 100%;
    padding: 0 !important;
    background-color: var(--greyColor);
}

.crossIconContainer {
    cursor: pointer;
    margin-right: 15px;
    margin-left: auto;
    position: absolute;
    right: 0px;
    top: 20px;
}

.crossIcon {
    stroke: grey;
    margin-bottom: 0;
}

.crossIconSmall {
    width: 15px;
    height: 15px;
    stroke: grey;

}

.crossIcon:hover {
    stroke: #bababa;
}

.header {
    display: flex;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    text-align: left;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px 10px !important;
    width: 100%;
    border-bottom: 1px solid #d7d7d7;
    background-color: #fff;
    margin-bottom: 16px;
    text-transform: uppercase;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.header p {
    font-size: 21px;
    margin: 0 0 0 0 !important;
    /* font-weight: 300; */
    text-align: left !important;
}

.header p span {
    font-size: 20px;
}

.content {
    font-family: Roboto;
    font-style: normal;
    font-size: 18px;
    font-weight: 300;
    color: #242124;
    margin-inline: 0;
    align-self: baseline;
    text-align: left;
    max-height: 370px;
    overflow: auto;
    width: 100%;
    min-height: 65px;
    /* min-height: 53px; */
    margin-bottom: 5px;
    margin-left: 0;
    padding-left: 0;
    /* border-bottom: 1px solid #e6e6e6; */
    /* margin-bottom: 15px; */
    padding-bottom: 15px;

}

.messageButton {
    height: 35px;
    min-width: 98px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    border: 1px solid rgba(4, 30, 55, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(49, 54, 58, 0.04);
    border-radius: 4px;
}

.rejectButton {
    background: #ffffff;
    color: #13529b;
}

.rejectButton:hover,
.rejectButton:focus,
.rejectButton:active {
    background: #eaeaea;
    color: #13529b;
}

.acceptButton {
    background: linear-gradient(94.78deg, #1a60b3 0%, #13529b 100%), #ffffff;
}

.acceptButton:hover,
.acceptButton:focus,
.acceptButton:active {
    background: linear-gradient(94.78deg, #1a60b3 0%, #13529b 30%), #ffffff;
}

/* .acceptButtonDanger {
    background: linear-gradient(94.78deg, #b3351a 0%, #ac3116 100%), #ffffff;
}

.acceptButtonDanger:hover,
.acceptButtonDanger:focus,
.acceptButtonDanger:active {
    background: linear-gradient(94.78deg, #b3351a 0%, #ac3116 30%), #ffffff;
} */

.acceptButtonDanger:hover {
    background-color: #c75454 !important;
  }
  .acceptButtonDanger:active {
    background-color: #c75454 !important;
    box-shadow: 0px 0px 5px 0px #e99393 !important;
  }

@media (max-width: 784px) {
    .container {
        width: 80% !important;
    }


    .container {
        top: 70px;
        transform: translate(-50%, 0);
        width: 96% !important;
        position: absolute;
    }

}